<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>GBDG Projects Map</h2>
    </div>
  </div>

  <div class="page-shape">
    <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
    <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
    <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
    <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
    <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
  </div>
</div>

<section class="services-details-area pt-100">
  <div class="container">
    <div class="row">

      <div class="col-12">
        <div class="services-details-text">
          <div class="section-title">
            <h2>Unveiling our presence across the nation</h2>
          </div>
          <p class="paragraph">Welcome to the Atlas of GBDG Projects.</p>
          <p class="paragraph">Our commitment to excellence and groundbreaking solutions comes to life through a diverse array of projects delivered across the geography of the country, as showcased on our interactive project maps.</p>
          <p class="paragraph">The GBDG Projects Map is a visual representation of our local footprint and highlights the reach of our initiatives. This interactive tool allows you to explore our diverse projects, spanning all directions and zones, and witness firsthand the value we are able to offer through our wide presence. Each pin on the map signifies a story of our competence, collaboration, and success.</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="home-case mt-70 pb-100">
  <div class="container">
    <div class="case">
      <ul class="nav-tabset">
        <li class="nav-tab" [ngClass]="{'active': currentTab === tab1}">
          <span (click)="switchTab(tab1)">
            Roads & Transport Infrastructure
          </span>
        </li>
        <li class="nav-tab" [ngClass]="{'active': currentTab === tab2}">
          <span (click)="switchTab(tab2)">
            Buildings Infrastructure
          </span>
        </li>
        <li class="nav-tab" [ngClass]="{'active': currentTab === tab3}">
            <span (click)="switchTab(tab3)">
              Stadiums
            </span>
        </li>
        <li class="nav-tab" [ngClass]="{'active': currentTab === tab4}">
            <span (click)="switchTab(tab4)">
              Data Centers
            </span>
        </li>
        <li class="nav-tab" [ngClass]="{'active': currentTab === tab5}">
            <span (click)="switchTab(tab5)">
              Operations & Maintenance
            </span>
        </li>
      </ul>
      <div class="tabs-container">
        <div class="pane" id="tab">
          <div class="row project-list">
            <div class="col-lg-12">
              <iframe [src]="('https://www.google.com/maps/d/u/4/embed?' + currentTab) | safe : 'resourceUrl'" class="map"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="case-btn text-center">
      <p><a routerLink="/key-projects">Explore the details of our Key Projects.</a></p>
    </div>
  </div>
</section>
