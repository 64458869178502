<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>Partners and Suppliers</h2>
    </div>
  </div>

  <div class="page-shape">
    <div class="shape1"><img src="assets/images/partners/shape/1.png" title="shape" /></div>
    <div class="shape3"><img src="assets/images/partners/shape/3.png" title="shape" /></div>
    <div class="shape4"><img src="assets/images/partners/shape/4.png" title="shape" /></div>
    <div class="shape5"><img src="assets/images/partners/shape/5.png" title="shape" /></div>
    <div class="shape6"><img src="assets/images/partners/shape/6.png" title="shape" /></div>
  </div>
</div>

<section class="services-details-area pt-70">
  <div class="container">
    <div class="row">

      <div class="col-12">
        <div class="services-details-text">
          <div class="scrives-item-2">
            <div class="row align-items-center">
              <div class="col-lg-3">
                <div class="services-img mb-qc">
                  <img src="assets/images/info/i2.jpg" title="Image">
                </div>
              </div>

              <div class="col-lg-8">
                <p class="paragraph">GBDG duly recognizes and appreciates the important contribution of its OEM partners, associates, and suppliers, who have supported us all along and enabled us to deliver customized solutions to our valued customers.</p>
                <p class="paragraph">We strongly believe in the power of collaboration and continually benefit from the synergy that arises from combining our expertise with that of our partners. By forging these partnerships, we gain access to a wide range of cutting-edge technologies, products, and skill sets, enabling us to provide comprehensive and tailored offerings to our customers. </p>
                <p class="paragraph">Below are some of our trusted partners and suppliers who contribute to our commitment to excellence and innovation:</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="partner-area pb-100">
  <div class="container">
    <div class="row" style="justify-content: center;">
      <div class="col-md-3 partner">
        <a href="https://www.aesys.com/" target="_blank"><img src="assets/images/partners/aesys-240x240.webp" title="Aesys"></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://www.aitek.it/en/" target="_blank"><img src="assets/images/partners/aitek-logo-1.webp" title="Aitek SpA"></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://www.allegion.com/corp/en/index.html" target="_blank"><img src="assets/images/partners/allegion-240x240.webp" title="Allegion"></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://www.assaabloy.com/group/en" target="_blank"><img src="assets/images/partners/assa-abloy-240x240.webp" title="Assa Abloy"></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://www.belden.com/" target="_blank"><img src="assets/images/partners/belden-240x240.webp" title="Belden"></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://www.bosch.com/" target="_blank"><img src="assets/images/partners/bosch-240x240.webp" title="Bosch"></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://ozak-t.com/?LNG=EN" target="_blank"><img src="assets/images/partners/came-ozak-240x240.webp" title="CAME Ozak"></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://www.cisco.com/c/en_ae/index.html" target="_blank"><img src="assets/images/partners/cisco-240x240.webp" title="Cisco"></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://www.citilog.com/" target="_blank"><img src="assets/images/partners/citilog.webp" title="Citilog"></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://www.comnet.net/" target="_blank"><img src="assets/images/partners/comnet-240x240.webp" title="ComNet"></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://dcl.co.nz/" target="_blank"><img src="assets/images/partners/data_collection_limited_logo.jpeg" title="Data Collection Ltd."></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://www.flir.eu/" target="_blank"><img src="assets/images/partners/flir-240x240.webp" title="Teledyn FLIR"></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://www.genetec.com" target="_blank"><img src="assets/images/partners/genetec-240x240.webp" title="Genetec"></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://www.hidglobal.com/" target="_blank"><img src="assets/images/partners/hid-240x240.webp" title="HID"></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://www.hikvision.com/en/" target="_blank"><img src="assets/images/partners/hikvision-240x240.webp" title="Hikvision"></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://www.hp.com/emea_middle_east-en/home.html" target="_blank"><img src="assets/images/partners/hp-240x240.webp" title="Hewlett-Packard"></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://www.huawei.com/en/" target="_blank"><img src="assets/images/partners/huawei-240x240.webp" title="Huawei"></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://www.idemia.com/" target="_blank"><img src="assets/images/partners/idemia-240x240.webp" title="IDEMIA"></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://www.ipsotek.com/" target="_blank"><img src="assets/images/partners/ipsotek-240x240.webp" title="Ipsotek"></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://www.leviton.com/en" target="_blank"><img src="assets/images/partners/leviton-240x240.webp" title="Leviton"></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://www.panduit.com/" target="_blank"><img src="assets/images/partners/panduit-240x240.webp" title="PANDUIT"></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://www.papercast.com/" target="_blank"><img src="assets/images/partners/papercast-240x240.webp" title="Papercast"></a>
      </div>
      <div class="col-md-3 partner">
        <img src="assets/images/partners/pelco-1-240x240.webp" title="Pelco">
      </div>
      <div class="col-md-3 partner">
        <a href="https://rasilient.com/" target="_blank"><img src="assets/images/partners/resilient-240x240.webp" title="Rasilient Systems"></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://www.rittal.com/com-en/" target="_blank"><img src="assets/images/partners/rittal-240x240.webp" title="Rittal"></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://rokid.ai/" target="_blank"><img src="assets/images/partners/rokid-logo-black-square-240x240.webp" title="Rokid"></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://www.se.com/ww/en/" target="_blank"><img src="assets/images/partners/scheider-electric-240x240.webp" title="Schneider Electric"></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://www.sensysnetworks.com/" target="_blank"><img src="assets/images/partners/sensys-networks-240x240.webp" title="Sensys Networks"></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://www.tattile.com/" target="_blank"><img src="assets/images/partners/tattile-240x240.webp" title="Tattile"></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://www.vicon.com/" target="_blank"><img src="assets/images/partners/vicon-logo-240x240.webp" title="Vicon"></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://www.zenitel.com/" target="_blank"><img src="assets/images/partners/zenitel-240x240.webp" title="Zenitel"></a>
      </div>
      <div class="col-md-3 partner">
        <a href="https://www.zucchetti.com/worldwide/cms/home.html" target="_blank"><img src="assets/images/partners/zucchetti-2-240x240.webp" title="Zucchetti"></a>
      </div>
    </div>
  </div>

</section>


