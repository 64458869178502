<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>Operations and Maintenance</h2>
    </div>
  </div>

  <div class="page-shape">
    <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
    <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
    <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
    <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
    <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
  </div>
</div>

<section class="services-details-area pt-70 pb-100">
  <div class="container">
    <div class="row">

      <div class="col-12">
        <div class="services-details-text">
          <div class="scrives-item-2">
            <div class="row align-items-center">

              <div class="col-lg-4 d-flex justify-content-start">
                <div class="services-img mb-qc image">
                  <img src="assets/images/service/o-and-m.jpg" title="Image">
                </div>
              </div>
              <div class="col-lg-8">
                <p class="paragraph">At GBDG, we take pride in spearheading leading-edge solutions for a wide range of assets, offering a comprehensive suite of services that transcend traditional operations and maintenance (O&M).</p>
                <p class="paragraph">We play a pivotal role in ensuring the seamless functionality and perpetual optimization of critical infrastructure assets. It encapsulates the daily operations, routine repairs, and systematic replacements necessary to ensure that an asset continues to deliver optimal services throughout its expected life.</p>
                <p class="paragraph">GBDG's proficiency in O&M services seamlessly extends into tunnel-related projects, reflecting the company's origins as a dedicated O&M service provider. The journey has naturally evolved from its inception, and GBDG takes pride in its role beyond the supply, installation, and commissioning of critical infrastructure components.</p>
                <p class="paragraph">With a strong foundation in O&M services, GBDG has been recognized for its excellence by the Public Works Authority "Ashghal," having been awarded with significant O&M Framework packages. These include multiple pedestrian bridges and a comprehensive network of numerous tunnels and underpasses, each incorporating substations and control rooms. Operating 24/7, GBDG's operators manage operations both in local control rooms and the main national control room, ensuring the continuous functionality, safety, and resilience of the infrastructure under their dedicated care.</p>
                <p class="paragraph">GBDG's expertise in O&M services extends beyond tunnel-related projects to encompass a diverse portfolio, marked by the company's recognition as a leading service provider. <b>In addition to its notable achievements in the realm of tunnels, GBDG has secured long-term maintenance contracts across various other domains, including ITS/SCADA projects, ICT/ELV projects, and electrical and lighting projects.</b></p>

              </div>


              <div class="col-lg-4 pt-70 d-flex justify-content-start">
                <div class="services-img mb-qc image pt-70">
                  <img src="assets/images/service/o-and-m2.jpg" title="Image">
                </div>
              </div>
              <div class="col-lg-8 pt-70">
                <h2>Our Value Proposition:</h2>
                <div class="subsection-title pt-20">
                  <span><strong>Commitment to Effective O&M</strong></span>
                </div>
                <p class="paragraph">We view O&M as more than just a routine task; it's a strategic initiative that encompasses complex operations and comprehensive asset management. At the core of our mission is our unwavering commitment to effective O&M practices. We understand that the key to unlocking the full potential of assets lies in meticulous maintenance and operation. Our team of dedicated professionals employs a proactive approach, leveraging state-of-the-art technology, industry innovations, and best practices to ensure the longevity and peak performance of your assets.</p>
                <div class="subsection-title pt-20">
                  <span><strong>Value for Money through Asset Optimization</strong></span>
                </div>
                <p class="paragraph">Every investment in infrastructure demands a tangible return. That's why our focus extends beyond routine maintenance, encompassing strategic asset optimization actions aimed at delivering unparalleled value for money. By applying innovative field-level solutions, extended levels of equipment/device maintenance and harnessing the power of data, we go beyond mere upkeep, making informed choices to optimize asset performance and thereby maximizing the lifespan of your assets.</p>
                <div class="subsection-title pt-20">
                  <span><strong>Expertise That Defines Excellence</strong></span>
                </div>
                <p class="paragraph">Our O&M and Asset Management division comprises seasoned professionals who bring a wealth of experience to the table. We have successfully navigated the complexities of diverse groups of assets, honing our skills in precision maintenance, operational risk mitigation, and performance enhancement. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-70 d-flex justify-content-center">
      <a class="box-btn" routerLink="/contact" target="_blank">Contact Us</a>
    </div>
  </div>
</section>
