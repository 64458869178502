<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>Road Network Survey Solutions</h2>
    </div>
  </div>

  <div class="page-shape">
    <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
    <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
    <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
    <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
    <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
  </div>
</div>

<section class="product-area pt-100 pb-20" id="romdas" #romdas>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="container">
          <div class="row product-img">
            <img src="assets/images/products/romdas1.webp" alt="Product" />
          </div>
          <div class="row product-img">
            <img src="assets/images/products/romdas2.webp" alt="Product" />
          </div>
          <div class="row product-img">
            <img src="assets/images/products/romdas3.webp" alt="Product" />
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="text-wrapper">
          <div class="logo-wrapper"><img class="logo" src="assets/images/products/romdas-logo.png" alt="GBDG"></div>
          <h1><strong>Road Network Survey Solutions</strong></h1>
          <span><strong>Use ROMDAS&#8482; Innovative Technology for Measuring and Managing Pavements and Collection of Road Asset Data</strong></span>
          <p>
            Unlock the power of data-driven road management with ROMDAS&#8482; innovative technologies. ROMDAS&#8482; advanced solutions empower transportation agencies to efficiently manage pavements and collect essential road asset data. With state-of-the-art systems, you can precisely assess pavement conditions, identify maintenance needs, and optimize asset management strategies.<br/><br/>

            ROMDAS&#8482; offers a comprehensive suite of cutting-edge technologies, including laser-based road profilers, high-definition imaging systems, and mobile mapping solutions. ROMDAS&#8482; integrated platform seamlessly captures and analyzes data, providing valuable insights for decision-making and resource allocation.<br/><br/>

            Experience the benefits of ROMDAS&#8482; innovative technologies, such as accurate pavement roughness measurements, distress detection, and asset inventory data collection. This enables you to prioritize maintenance interventions, prolong infrastructure lifespans, and enhance road safety for all users.<br/><br/>

            ROMDAS&#8482; user-friendly software and intuitive interfaces make data interpretation and reporting a breeze, allowing you to effectively communicate findings and track performance indicators. Stay ahead of the curve with ROMDAS&#8482; and unlock the potential of data to revolutionize your road management strategies.<br/><br/>

            Contact us today and join transportation agencies worldwide that rely on ROMDAS&#8482; innovative technologies to optimize pavement management, streamline operations, and ensure cost-effective infrastructure investments.<br/><br/>
          </p>
          <div class="product-links d-flex justify-content-center">
            <a class="box-btn" href="https://romdas.com/" target="_blank">Visit ROMDAS&#8482; Website</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="product-area pb-100">
  <div class="container">
    <div class="row align-items-center flex-lg-row-reverse">
      <div class="col-lg-6">
        <div class="technology-area mt-70">
          <div class="choose-img">
            <img src="assets/images/products/romdas.jpg" alt="GBDG" />

            <div class="technology-video">
              <button type="button" class="video-btn p-0 border-0" (click)="openPopup1()">
                <i class='bx bx-play' ></i>
              </button>
            </div>
            <div class="video-popup" *ngIf="isOpen1">
              <div class="popup-inner">
                <iframe src="https://www.youtube.com/embed/lSHtIu17OxY" title="Romdas" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <button (click)="closePopup1()" type="button" class="close-btn">
                  <i class='bx bx-x'></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="text-wrapper">
          <h2><strong>ROMDAS&#8482; System</strong></h2>
          <p>
            ROMDAS&#8482; (ROad Measurement Data Acquisition System) is developed by Data Collection Ltd. (DCL). Whether a private consultant, government department, or research institution, ROMDAS&#8482; offers great flexibility and ease of use to accurately measure a variety of pavement data quickly.<br/><br/>

            The Central ROMDAS&#8482; system includes the core hardware components, odometer, interfaces, and software licenses for collecting survey data. You can then add modules to this central system for recording additional datasets. This innovative design can be installed on almost any vehicle.<br/><br/>

            The system is designed to enable users to tailor it with modules specific to the needs of their projects without having to spend their budget on components they do not need.<br/><br/>
          </p>
        </div>
        <div class="product-links d-flex justify-content-center mt-20">
          <a class="box-btn" href="assets/docs/ROMDASOverview.pdf" target="_blank"><i class="bx bx-download"></i> Download Brochure</a>
          <a class="box-btn" href="https://romdas.com/romdas-system.html" target="_blank">Read more on ROMDAS&#8482; Website</a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="choose-area pt-20 pb-100">
  <div class="container">
    <div class="section-title">
      <h2>ROMDAS&#8482; Add-on Modules</h2>
      <p>ROMDAS&#8482; offers low-cost devices for use in tough and unpaved conditions as well as the latest in laser scanning technology for accurate analysis of highways or rural roads.</p>
    </div>

    <div class="row flex-lg-row-reverse">
      <div class="col-lg-8">
        <div class="choose-img">
          <img src="assets/images/products/romdas4.webp" alt="choose" />
        </div>
        <div class="product-links d-flex justify-content-center mt-70">
          <a class="box-btn" href="assets/docs/ROMDASOverview.pdf" target="_blank"><i class="bx bx-download"></i> Download Brochure</a>
          <a class="box-btn" href="https://romdas.com/romdas-system.html" target="_blank">Read more on ROMDAS&#8482; Website</a>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="choose-content">
          <div class="faq-accordion">
            <div class="faq-item" *ngFor="let item of accordionItems; let i = index;">
              <div class="faq-header" (click)="toggleAccordionItem(item)">
                {{item.title}}
                <i class='bx' [ngClass]="{'bx-down-arrow': !item.open, 'bx-up-arrow': item.open}"></i>
              </div>
              <div class="faq-content" [hidden]="!item.open">
                <p><strong>{{item.subtitle}}: </strong>{{item.content}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="product-area pb-100">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="text-wrapper">
          <h2><strong>ROMDAS&#8482; Data Acquisition Software</strong></h2>
          <p>
            ROMDAS&#8482; Data Acquisition Software interfaces between the survey computer and ROMDAS&#8482; hardware to collect and analyze pavement data. It is supplied with the central ROMDAS&#8482; System and used in the vehicle for recording and processing the data into Microsoft Access databases.<br/><br/>

            The software will display real-time status for all modules during surveying. ROMDAS&#8482; Data Acquisition software offers useful features such as location referencing, event/condition rating and travel time surveying to enhance the value of the data being collected.<br/><br/>
          </p>
        </div>
      </div>
      <div class="col-lg-6 d-flex m-auto">
        <div class="big-product-img">
          <img src="assets/images/products/romdas5.webp" alt="Product" />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="text-wrapper">
          <strong>Key Benefits & Features</strong>
          <ul>
            <li><i class="bi bi-dot"></i> Simultaneously monitor and control all ROMDAS&#8482; modules in real-time </li>
            <li><i class="bi bi-dot"></i> Operator friendly, ease of use  </li>
            <li><i class="bi bi-dot"></i> Inbuilt Location Referencing system (LRPs)  </li>
            <li><i class="bi bi-dot"></i> Customizable keycode asset and condition rating system  </li>
            <li><i class="bi bi-dot"></i> Inbuilt calibration and self-testing features  </li>
            <li><i class="bi bi-dot"></i> Real-time status and display of all modules during surveying </li>
            <li><i class="bi bi-dot"></i> Automatically reference data to distance/chainage and GPS (if connected)   </li>
            <li><i class="bi bi-dot"></i> Process data onsite for immediate results   </li>
            <li><i class="bi bi-dot"></i> Low processor requirements means it can run on any laptop or computer   </li>
            <li><i class="bi bi-dot"></i> Free office version for processing/reprocessing survey files in the office </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>



<section class="product-area pb-100">
  <div class="container">
    <div class="row align-items-center flex-lg-row-reverse">
      <div class="col-lg-12">
        <div class="text-wrapper">
          <h2><strong>ROMDAS&#8482; DataView Office Post-Processing Software</strong></h2>
          <p>
            An advanced data integration and post-processing software, DataView is a versatile tool for ROMDAS&#8482; equipment users. Engineers and survey managers responsible for managing and processing road data can especially take advantage of presenting the numerical information in visual reports.<br/><br/>

            DataView integrates individual survey files into a single central database. It helps present data in user-defined graphs, charts, and maps. All data is linked using the chainage (distance) information and/or the GPS positions. This linking helps to track between graphed data, GIS maps, and digital video images.

          </p>

        </div>
      </div>
      <div class="col-lg-6">
        <div class="big-product-img">
          <img src="assets/images/products/romdas6.webp" alt="Product" />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="text-wrapper">
          <p>
            DataView software is an essential Quality Assurance (QA) tool that allows users to simultaneously view linked videos, GIS maps, and strip maps and check data for quality and accuracy. The postprocessing software can convert raw GPS-referenced data into GIS map layers that can be easily exported to GIS mapping software like ArcGIS, Google Earth, or other 3rd party software.<br/><br/>

            With enhanced look and functionality for all features, DataView software has been improved to now include a step-by-step logic module for easy data processing. This results in a professional, user-friendly, and informative way of presenting your survey data.<br/><br/>
          </p>

        </div>
      </div>
    </div>
  </div>
</section>

<section class="product-area pb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="big-product-img">
          <img src="assets/images/products/romdas7.webp" alt="Product" />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="text-wrapper">
          <h2><strong>ROMDAS&#8482; Mobile Mapping Software</strong></h2>
          <p>
            ROMDAS&#8482; Mobile Mapping allows mapping capabilities from the 360-view videos to enable the recording of accurate measurements and locations within the road corridor. Partnering with industry-acclaimed image processing software developers, mobile mapping is a fast way to collect up-to-date data on a large scale and in less time.<br/><br/>

            The 360-degree camera images can be stitched together to provide a panoramic view of the road environment. Using the mobile mapping features, users can easily reference and measure any visible asset and add it directly to GIS map layers. Mobile mapping allows to extract the coordinates of assets, take measurements of the road surface as well as the assets, and annotate assets and events.<br/><br/>
          </p>

        </div>
      </div>
    </div>
  </div>
</section>

<section class="product-area">
  <div class="overlay">
    <div class="container overlay-content">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-8">
          <div class="story-wrapper">
            <h2>GBDG Partnership with ROMDAS&#8482; for Advanced Road Network Surveys</h2>
            <p>Gulf Business Development Group's partnership with ROMDAS&#8482; for advanced road network surveys and road asset inventory data collection evolved through a shared commitment to innovation and excellence in the field of road infrastructure asset management. Recognizing the value of ROMDAS&#8482;' advanced technologies for data collection and analysis, GBDG embarked on the opportunity to expand their operations and maintenance service offerings and provide comprehensive road survey solutions.<br/><br/>

              By partnering with ROMDAS&#8482;, GBDG gained access to state-of-the-art systems for right-of-way asset inventory data and pavement condition data collection, including laser-based road profilers, high-definition imaging systems, and mobile mapping solutions. These technologies enable GBDG to capture accurate and detailed data on road conditions and asset inventory, empowering transportation agencies with valuable insights for decision-making and planning.<br/><br/>

              To solidify their commitment to operations and maintenance services, GBDG established a dedicated division focused on road surveys. Leveraging the ROMDAS&#8482; solution and collaborating with other innovative partners specializing in pavement maintenance and inspection, GBDG has expanded its capabilities and expertise in delivering comprehensive pavement management solutions.<br/><br/>

              GBDG delivers end-to-end services for road surveys, from data collection to analysis and reporting. By integrating ROMDAS&#8482;' advanced technologies with their existing expertise in road infrastructure, GBDG provides clients with a holistic approach to pavement management and road asset data collection, optimizing maintenance interventions, and prolonging the lifespan of road assets.<br/><br/>

              GBDG's above initiative demonstrates its commitment to staying at the forefront of the industry and providing clients with advanced value-added solutions and services.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

