import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-road-survey',
    templateUrl: './road-survey.component.html',
    styleUrls: ['./road-survey.component.scss']
})
export class RoadSurveyComponent implements OnInit {

    constructor(
        private titleService: Title,
        private meta: Meta,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private doc: Document
    )
    {
        this.titleService.setTitle('GBDG - Road Network Survey Solutions');
        this.meta.addTag({ name: 'description', content: 'Unlock the power of data-driven road management with ROMDAS&#8482 distributed by GBDG; innovative technologies. ROMDAS&#8482; advanced solutions empower transportation agencies to efficiently manage pavements and collect essential road asset data. With state-of-the-art systems, you can precisely assess pavement conditions, identify maintenance needs, and optimize asset management strategies.' });
        this.meta.addTag({ name: 'keywords', content: ' Asset Inventory Survey Qatar,GPR Survey Qatar,LCMS Qatar,Pavement Condition Surveys,Pavement Surveys,Road Condition Surveys,Road Surveys,ROMDAS,Best Road Network Surveys in Doha,Road Network Surveys,Road Network Surveys in Qatar,Road Network Surveys near me,Top Road Network Surveys in Qatar,Road Network Surveys Qatar,Fast Survey Qatar,Automated Survey Qatar,Laser Profilometer Qatar,Laser Foreign Object Detection Qatar,Data Acquisition Qatar,' });
        this.meta.addTag({ name: 'robots', content: 'all' });
        this.meta.addTag({ name: 'resource-type', content: 'document' });
        this.meta.addTag({ name: 'page-topic', content: 'Gulf Business Development Group' });
        this.meta.addTag({ name: 'clientbase', content: 'Global' });
        this.meta.addTag({ name: 'distribution', content: 'World Wide Web' });
        this.meta.addTag({ name: 'audience', content: 'all' });
        this.meta.addTag({ name: 'rating', content: 'general' });
        this.meta.addTag({ id: 'googlebot', name: 'googlebot', content: 'index, follow' });
        this.meta.addTag({ name: 'expires', content: 'never' });
        this.meta.addTag({ name: 'bingbot', content: ' index, follow ' });
        this.meta.addTag({ name: 'revisit-after', content: 'Daily' });
        this.meta.addTag({ name: 'author', content: 'Gulf Business Development Group' });
        this.meta.addTag({ name: 'HandheldFriendly', content: 'True' });
        this.meta.addTag({ name: 'YahooSeeker', content: 'Index,Follow' });
        this.meta.addTag({ name: 'geo.region', content: 'UAE' });
        this.meta.addTag({ name: 'State', content: 'Qatar' });
        this.meta.addTag({ name: 'City', content: 'Doha' });
        this.meta.addTag({ name: 'subject', content: 'Rated #1 Gulf Business Development Group - Best Road Network Survey Solutions' });
    }

    ngOnInit(): void {
        this.setCanonicalURL('https://gulfbdgroup.com/romdas/');
    }

    setCanonicalURL(url: string) {
        let link: HTMLLinkElement | null = this.doc.querySelector('link[rel="canonical"]') || null;
        if (link) {
            this.renderer.setAttribute(link, 'href', url);
        } else {
            link = this.renderer.createElement('link');
            this.renderer.setAttribute(link, 'rel', 'canonical');
            this.renderer.setAttribute(link, 'href', url);
            this.renderer.appendChild(this.doc.head, link);
        }
    }

    isOpen1 = false;
    openPopup1(): void {
        this.isOpen1 = true;
    }

    closePopup1(): void {
        this.isOpen1 = false;
    }


    accordionItems = [
        {
            title: 'Laser Profilometer',
            subtitle: 'Profile, Roughness and Macrotexture',
            content: `The ROMDAS Laser Profilometer is an exceptionally portable system used when high accuracy roughness data on paved roads is required.`,
            open: false
        },
        {
            title: 'LCMS Module',
            subtitle: 'Multifunctional Pavement Profiling',
            content: `ROMDAS LCMS utilizes the latest advance laser scanning technology from INO/Pavemetrics to collect high accuracy 3D profiles of the road surface.`,
            open: false
        },
        {
            title: 'Laser Foreign Object Detection',
            subtitle: 'Automated Foreign Object Detection',
            content: `The ROMDAS LFOD system uses Pavemetrics LCMS hardware and latest algorithms for rapid FOD and pavement condition surveys at Airports.`,
            open: false
        },
        {
            title: 'Right of Way View Video',
            subtitle: 'Right of Way, Mobile Mapping',
            content: `The Right of Way Video Logging module is used to create a visual record of the road right-of-way (ROW) to collect information about road asset and inventory.`,
            open: false
        },
        {
            title: 'Pavement View Video',
            subtitle: 'Pavement Surface',
            content: `The Pavement View Video Logging module creates a record of the pavement surface for visual condition assessment. ROMDAS is an accredited iRAP inspection system.`,
            open: false
        },
        {
            title: 'Transverse Profile Logger',
            subtitle: 'Transverse Profile and Rutting',
            content: `The ROMDAS TPL is used to measure the transverse profile of the road, utilising 15 high quality single point laser sensors to output industry standard transverse profile.`,
            open: false
        },
        {
            title: 'Bump Integrator',
            subtitle: 'Roughness',
            content: `This Class 3 response type meter has been widely implemented over the last few decades and is a great low cost option for measuring roughness and calculating IRI.`,
            open: false
        },
        {
            title: 'GPS and Geometry',
            subtitle: 'Georeferencing and Geometry Data',
            content: `GPS data can be used to establish the road center line or simultaneously referenced to other datasets collected by a ROMDAS system. Easy integration with GIS mapping software.`,
            open: false
        },
        {
            title: 'High Resolution Distance Measurement',
            subtitle: 'High Accuracy Distance/Speed',
            content: `The High Resolution Distance Measurement Instrument (HR DMI) is used in situations where extremely accurate (< 0.1 m) distance measurements are required.`,
            open: false
        }
    ];

    selectedItem : any = null;

    toggleAccordionItem(item:any) {
        item.open = !item.open;
        if (this.selectedItem && this.selectedItem !== item) {
            this.selectedItem.open = false;
        }
        this.selectedItem = item;
    }
}
