<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>GBDG Trademarks and IPs</h2>
    </div>
  </div>

  <div class="page-shape">
    <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
    <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
    <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
    <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
    <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
  </div>
</div>

<section class="services-details-area pt-70 pb-20">
  <div class="container">
    <div class="row">

      <div class="col-12">
        <div class="services-details-text">
          <div class="scrives-item-2">
            <div class="row align-items-center">
              <div class="col-lg-3">
                <div class="services-img mb-qc">
                  <img src="assets/images/certifications/patents.jpg" alt="Image">
                </div>
              </div>

              <div class="col-lg-9">
                <p class="paragraph">We are passionate about innovation and nurturing ideas aimed at serving the complex business requirements of our committed customers and providing long-term engagement to the organization. We invest our resources in giving shape to those ideas and maintaining them on a roadmap to excellence and maturity.</p>
                <p class="paragraph">Our designs and products are continually validated to ensure they stand out in the market while being protected by registered trademarks and intellectual property rights</p>
                <p class="paragraph">Every design and product we develop bears the hallmark of originality and uniqueness. Our dedicated compliance team collaborates closely with our development units and ensures that all significant aesthetic, technological, and functional advancements are duly recognized and safeguarded. This not only fortifies our position in the industry but also assures our clients that they are investing in products of unparalleled ingenuity and quality.</p>
              </div>
              <div class="col-lg-12 pt-20">
                <p class="paragraph">Below are some of our registered and trademarked products, each embodying innovation, quality, and uniqueness at its core:</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>
<section class="home-blog-area pb-100">
  <div class="container">
    <div class="row" style="justify-content: center;">

      <div class="col-lg-3 col-md-6">
        <div class="single-blog">
          <div class="blog-img">
            <img src="assets/images/products/grams.jpg" alt="Products and Designs">
          </div>

          <div class="content">
            <h3>GRAMS&#8482;</h3>
            <p>Road Asset Management Platform<br><a routerLink="/software" fragment="grams">Read More</a></p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6">
        <div class="single-blog">
          <div class="blog-img">
            <img src="assets/images/products/busistop.jpg" alt="ICV">
          </div>

          <div class="content">
            <h3>Bus iStop&#8482;</h3>
            <p>Bus Stop Management Platform<br><a routerLink="/software" fragment="busistop">Read More</a></p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6">
        <div class="single-blog">
          <div class="blog-img">
            <img src="assets/images/products/footibridge.jpg" alt="Products and Designs">
          </div>

          <div class="content">
            <h3>Footibridge&#8482;</h3>
            <p>Footbridge Management Platform<br><a routerLink="/software" fragment="footibridge">Read More</a></p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6">
        <div class="single-blog">
          <div class="blog-img">
            <img src="assets/images/products/gfms.jpg" alt="Products and Designs">
          </div>

          <div class="content">
            <h3>GFMS&#8482;</h3>
            <p>Fleet Management System<br><a routerLink="/software" fragment="gfms">Read More</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
