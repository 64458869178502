import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-passenger-info',
    templateUrl: './passenger-info.component.html',
    styleUrls: ['./passenger-info.component.scss']
})
export class PassengerInfoComponent implements OnInit {

    constructor(
        private titleService: Title,
        private meta: Meta,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private doc: Document
    )
    {
        this.titleService.setTitle('GBDG - Passenger Information Systems');
        this.meta.addTag({ name: 'description', content: 'Experience the power of the Papercast solutions offered by GBDG. Our strategic partnership with Papercast brings you cutting-edge digital passenger information systems that revolutionize public transportation communication. With Papercast\'s e-paper displays, real-time updates, and dynamic content, you can enhance passenger experiences, increase operational efficiency, and reduce costs.' });
        this.meta.addTag({ name: 'keywords', content: ' Papercast Qatar,Passenger Information System Qatar,Passenger Information Displays Qatar,Public Transportation Information Displays Qatar,E-Ink Displays Qatar,' });
        this.meta.addTag({ name: 'robots', content: 'all' });
        this.meta.addTag({ name: 'resource-type', content: 'document' });
        this.meta.addTag({ name: 'page-topic', content: 'Gulf Business Development Group' });
        this.meta.addTag({ name: 'clientbase', content: 'Global' });
        this.meta.addTag({ name: 'distribution', content: 'World Wide Web' });
        this.meta.addTag({ name: 'audience', content: 'all' });
        this.meta.addTag({ name: 'rating', content: 'general' });
        this.meta.addTag({ id: 'googlebot', name: 'googlebot', content: 'index, follow' });
        this.meta.addTag({ name: 'expires', content: 'never' });
        this.meta.addTag({ name: 'bingbot', content: ' index, follow ' });
        this.meta.addTag({ name: 'revisit-after', content: 'Daily' });
        this.meta.addTag({ name: 'author', content: 'Gulf Business Development Group' });
        this.meta.addTag({ name: 'HandheldFriendly', content: 'True' });
        this.meta.addTag({ name: 'YahooSeeker', content: 'Index,Follow' });
        this.meta.addTag({ name: 'geo.region', content: 'UAE' });
        this.meta.addTag({ name: 'State', content: 'Qatar' });
        this.meta.addTag({ name: 'City', content: 'Doha' });
        this.meta.addTag({ name: 'subject', content: 'Rated #1 Gulf Business Development Group - Best Passenger Information Systems' });
    }

    ngOnInit(): void {
        this.setCanonicalURL('https://gulfbdgroup.com/passenger-info/');
    }

    setCanonicalURL(url: string) {
        let link: HTMLLinkElement | null = this.doc.querySelector('link[rel="canonical"]') || null;
        if (link) {
            this.renderer.setAttribute(link, 'href', url);
        } else {
            link = this.renderer.createElement('link');
            this.renderer.setAttribute(link, 'rel', 'canonical');
            this.renderer.setAttribute(link, 'href', url);
            this.renderer.appendChild(this.doc.head, link);
        }
    }

    	// Video Popup
	  isOpen1 = false;
	  isOpen2 = false;
    openPopup1(): void {
        this.isOpen1 = true;
    }
    openPopup2(): void {
        this.isOpen2 = true;
    }
    closePopup1(): void {
        this.isOpen1 = false;
    }
    closePopup2(): void {
        this.isOpen2 = false;
    }
}
