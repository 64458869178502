import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-transportation',
    templateUrl: './transportation.component.html',
    styleUrls: ['./transportation.component.scss']
})
export class TransportationComponent implements OnInit {

    constructor(
        private titleService: Title,
        private meta: Meta,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private doc: Document
    )
    {
        this.titleService.setTitle('GBDG  - Integrated Transportation Solutions');
        this.meta.addTag({ name: 'description', content: 'Over the years, Gulf Business Development Group (GBDG) has emerged as a pioneer in the dynamic realm of public transportation, reshaping the landscape with a comprehensive suite of solutions and services that redefine the overall passenger experience with enhanced efficiency, comfort, and safety.' });
        this.meta.addTag({ name: 'keywords', content: ' Best Integrated Transportation Solutions in Doha,Integrated Transportation Solutions,Integrated Transportation Solutions near me,Intelligent Transportation Systems in Doha,Intelligent Transportation Systems in Qatar,Intelligent Transportation Systems near me,Integrated Transportation Solutions Qatar,Bus iStop Qatar,Bus Shelters Qatar,Bus Stops Qatar,Bus Stations Qatar,Mowasalat Qatar,SILA Qatar,FIFA 2022 Qatar,Expo 2023 Qatar,Asian Cup 2023 Qatar,' });
        this.meta.addTag({ name: 'robots', content: 'all' });
        this.meta.addTag({ name: 'resource-type', content: 'document' });
        this.meta.addTag({ name: 'page-topic', content: 'Integrated Transportation Solutions in Qatar' });
        this.meta.addTag({ name: 'clientbase', content: 'Global' });
        this.meta.addTag({ name: 'distribution', content: 'World Wide Web' });
        this.meta.addTag({ name: 'audience', content: 'all' });
        this.meta.addTag({ name: 'rating', content: 'general' });
        this.meta.addTag({ id: 'googlebot', name: 'googlebot', content: 'index, follow' });
        this.meta.addTag({ name: 'expires', content: 'never' });
        this.meta.addTag({ name: 'bingbot', content: ' index, follow ' });
        this.meta.addTag({ name: 'revisit-after', content: 'Daily' });
        this.meta.addTag({ name: 'author', content: 'Gulf Business Development Group' });
        this.meta.addTag({ name: 'HandheldFriendly', content: 'True' });
        this.meta.addTag({ name: 'YahooSeeker', content: 'Index,Follow' });
        this.meta.addTag({ name: 'geo.region', content: 'UAE' });
        this.meta.addTag({ name: 'State', content: 'Qatar' });
        this.meta.addTag({ name: 'City', content: 'Doha' });
        this.meta.addTag({ name: 'subject', content: 'Rated #1 Gulf Business Development Group  - Best Integrated Transportation Solutions in Qatar' });
    }

    ngOnInit(): void {
        this.setCanonicalURL('https://gulfbdgroup.com//');
    }

    setCanonicalURL(url: string) {
        let link: HTMLLinkElement | null = this.doc.querySelector('link[rel="canonical"]') || null;
        if (link) {
            this.renderer.setAttribute(link, 'href', url);
        } else {
            link = this.renderer.createElement('link');
            this.renderer.setAttribute(link, 'rel', 'canonical');
            this.renderer.setAttribute(link, 'href', url);
            this.renderer.appendChild(this.doc.head, link);
        }
    }

}
