<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>Quality and Safety</h2>
    </div>
  </div>

  <div class="page-shape">
    <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
    <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
    <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
    <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
    <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
  </div>
</div>

<section class="home-company-area">
  <div class="feature-area container">
    <div class="row align-items-center">
      <div class="col-lg-7">
        <div class="contnet">
          <div class="mycontent">
            <p>Recognizing our role as a responsible business entity making an important contribution in the areas of our presence, GBDG strives to conduct its daily activities in a secure, safe, injury-free, and environmentally responsible manner.</p>
            <p>Our workforce is adequately trained on quality, environment, and safety aspects to the extent that they apply to their respective roles and the surrounding work environment. By deploying the latest tools, techniques, and work instructions for worksite safety, we strive to make sure that every single employee goes home safe and sound every evening.</p>
            <p>We are committed to:</p>
            <ul>
              <li><p><i class="flaticon-correct"></i> Ensuring that our operations and business activities comply with all applicable laws and regulations.</p></li>
              <li><p><i class="flaticon-correct"></i> Delivering projects within the stipulated timelines while maintaining a consistent focus on the progress and quality of our deliverables across all stages of our project delivery lifecycle.</p></li>
              <li><p><i class="flaticon-correct"></i> Optimizing the consumption of resources while minimizing emissions and waste.</p></li>
              <li><p><i class="flaticon-correct"></i> Reducing risks in our operations to safeguard our employees, contractors, and the communities where we operate and engage in business activities.</p></li>
            </ul>
          </div>

        </div>

      </div>

      <div class="col-lg-5">
        <div class="feature-img">
          <div class="col-lg-6">
            <div class="row align-items-center image-500">
              <img src="assets/images/company/iso.jpg" alt="ISO" />
              <span class="certification">Certification Nos. : Q-97405072921, OM-97405072918, and E-97416013667 </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <p>We regularly review and maintain the outcomes of our periodic quality and HSE audits and actively engage with our customers, employees, subcontractors, regulatory agencies, and other stakeholders, actively seeking their input to drive our ongoing efforts for continuous improvement. Open communication is not just a commitment but a cornerstone of our approach, fostering collaboration and ensuring that everyone involved plays a crucial role in advancing our initiatives towards excellence, sustainability, and safety.</p>
        </div>
      </div>
    </div>
  </div>
</section>

