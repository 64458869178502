import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ContactService {
  resourceUrl = 'https://gulfbdgroup.com:8084' // 'http://192.168.100.222:8084'

  constructor(
    protected http: HttpClient
  ) {}

  sendEmailToContact(contact: any): Observable<HttpResponse<{}>> {
    return this.http.post(`${this.resourceUrl}/send-email-contact`, contact, { observe: 'response' });
  }

  sendEmailToGBDG(contact: any): Observable<HttpResponse<{}>> {
    return this.http.post(`${this.resourceUrl}/send-email-gbdg`, contact, { observe: 'response' });
  }


}
