<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>Augmented Reality Smart Glasses</h2>
    </div>
  </div>

  <div class="page-shape">
    <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
    <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
    <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
    <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
    <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
  </div>
</div>

<section class="product-area ptb-100" id="rokid" #rokid>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="container pb-20">
          <div class="row product-img">
            <img src="assets/images/products/rokid1.webp" alt="Product" />
          </div>
          <div class="row product-img">
            <img src="assets/images/products/rokid2.webp" alt="Product" />
          </div>
          <div class="row product-img">
            <img src="assets/images/products/rokid3.webp" alt="Product" />
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="text-wrapper">
          <div class="logo-wrapper"><img class="logo" src="assets/images/products/rokid.webp" alt="GBDG"></div>
          <h1><strong>AR GLASSES</strong></h1>
          <span><strong>Use Augmented Reality to enhance your productivity</strong></span>
          <p>
            Augmented Reality glasses play a crucial role in Operations and Maintenance (O&M) for many industries, such as Roads & Building Infrastructure, Transportation, Oil & Gas, manufacturing, and many more, by providing essential real-time information and augmented visualization. They enable technicians to access digital data, instructions, and remote expert assistance hands-free, improving efficiency, accuracy, and safety.<br/><br/>

            Rokid AR glasses enhance troubleshooting, maintenance, and repair processes by overlaying relevant information onto the physical environment, reducing errors and downtime. They enable remote collaboration, minimizing the need for on-site visits and increasing productivity. With Rokid AR glasses, professionals can visualize equipment status, access maintenance history, and perform complex tasks with guided step-by-step instructions.<br/><br/>

            GBDG firmly believes that AR glasses are revolutionizing industries by enhancing productivity, knowledge transfer, and operational effectiveness, leading to cost savings, improved efficiency, and enhanced customer satisfaction.<br/><br/>

            Rokid AR glasses can also be used for entertainment and tour guides, providing immersive experiences that enhance engagement and interactivity. They can overlay digital information onto physical locations, adding historical, cultural, or artistic context to the environment. Rokid AR glasses can create unique, interactive, and memorable experiences for tourists, gamifying visits and enhancing educational value.<br/><br/>
          </p>
          <div class="product-links d-flex justify-content-center">
            <a class="box-btn" href="https://www.rokid.ai/" target="_blank">Visit Rokid Website</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="product-area pb-100">
  <div class="container">
    <div class="row align-items-center flex-lg-row-reverse">
      <div class="col-lg-6">
        <div class="technology-area mt-70">
          <div class="choose-img">
            <img src="assets/images/products/rokid-xcraft.png" alt="GBDG" />

            <div class="technology-video">
              <button type="button" class="video-btn p-0 border-0" (click)="openPopup1()">
                <i class='bx bx-play' ></i>
              </button>
            </div>
            <div class="video-popup" *ngIf="isOpen1">
              <div class="popup-inner">
                <iframe src="https://p.rokidcdn.com/web/newsite/videos/Rokid%20X-Craft/%E8%8B%B1%E6%96%87%E7%89%88/%E5%A4%B4%E5%9B%BE%E8%A7%86%E9%A2%91%EF%BC%88%E8%8B%B1%EF%BC%89/X-Craft%20ROKID%20LOGO%20%E8%8B%B1%E6%96%87%E7%89%88%E2%80%94%E2%80%94%E5%8E%8B%E7%BC%A9%E7%89%88.mp4?autoplay=1" title="Rokid" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <button (click)="closePopup1()" type="button" class="close-btn">
                  <i class='bx bx-x'></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="text-wrapper">
          <h2><strong>Rokid X-Craft</strong></h2>
          <p>
            X-Craft MR glasses are the world’s leading ATEX and IECEx Zone 1/2 and Zone 21/22-certified explosion-proof MR glasses equipped with a 5G module. It is compatible with safety helmets and hard hats, specially designed for complex and high-risk environments such as oil and gas, manufacturing, electric power, aviation, rail transport, and many other industries. It is equipped with an AI algorithm and MR technology, which enable frontline workers to update information automatically and increase work efficiencies.
          </p>
          <p>
            <img src="assets/images/products/rokid-xcraft-details.png" alt="GBDG"/>
          </p>
        </div>
        <div class="product-links d-flex justify-content-center mt-20">
          <a class="box-btn" href="assets/docs/Rokid-X-Craft.pdf" target="_blank"><i class="bx bx-download"></i> Download Brochure</a>
          <a class="box-btn" href="https://rokid.ai/products/rokid-x-craft/" target="_blank">Read more on Rokid Website</a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="product-area pb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="technology-area mt-70">
          <div class="choose-img">
            <img src="assets/images/products/rokid-glass2.webp" alt="GBDG" />

            <div class="technology-video">
              <button type="button" class="video-btn p-0 border-0" (click)="openPopup2()">
                <i class='bx bx-play' ></i>
              </button>
            </div>
            <div class="video-popup" *ngIf="isOpen2">
              <div class="popup-inner">
                <iframe src="https://www.youtube.com/embed/Rs-bVG7Qr54?rel=0&amp;amp;&amp;showinfo=0&amp;autoplay=0&amp;loop=0" title="Rokid" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <button (click)="closePopup2()" type="button" class="close-btn">
                  <i class='bx bx-x'></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="text-wrapper">
          <h2><strong>Rokid Glass 2</strong></h2>
          <p>
            Foldable, monocular Augmented Reality (AR) glasses, Rokid Glass 2 offers a lightweight design, high-resolution display, and superior performance on a variety of tasks. A hands-free voice control interface and robust extensions for use with existing mobile devices make it extremely versatile. Rokid Glass 2 continues to be adopted within the manufacturing, logistics, exhibition, healthcare, and education markets.
          </p>
          <p>
            <img src="assets/images/products/rokid-glass2-details.png" alt="GBDG"/>
          </p>
        </div>
        <div class="product-links d-flex justify-content-center mt-20">
          <a class="box-btn" href="assets/docs/Rokid-GlassII.pdf" target="_blank"><i class="bx bx-download"></i> Download Brochure</a>
          <a class="box-btn" href="https://rokid.ai/products/rokid-glass-2/" target="_blank">Read more on Rokid Website</a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="product-area pb-100">
  <div class="container">
    <div class="row align-items-center flex-lg-row-reverse">
      <div class="col-lg-6">
        <div class="technology-area mt-70">
          <div class="choose-img">
            <img src="assets/images/products/rokid-airpro.png" alt="GBDG" />

            <div class="technology-video">
              <button type="button" class="video-btn p-0 border-0" (click)="openPopup3()">
                <i class='bx bx-play' ></i>
              </button>
            </div>
            <div class="video-popup" *ngIf="isOpen3">
              <div class="popup-inner">
                <iframe src="https://www.youtube.com/embed/vrcx5mPZphU" title="Rokid" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <button (click)="closePopup3()" type="button" class="close-btn">
                  <i class='bx bx-x'></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="text-wrapper">
          <h2><strong>Rokid Air Pro</strong></h2>
          <p>
            AR Glasses specialized for remote collaboration, workflow optimization or for home entertainment.
            <br/><br/>
            Rokid Air Pro is powerful and intuitive. Simply put them on, and you will experience a 120" wide virtual screen, giving you an unprecedented, immersive multimedia experience for exhibition, education, and training.
          </p>
          <p>
            <img src="assets/images/products/rokid-airpro-details.png" alt="GBDG"/>
          </p>
        </div>
        <div class="product-links d-flex justify-content-center mt-20">
          <a class="box-btn" href="assets/docs/Rokid-AirPro.pdf" target="_blank"><i class="bx bx-download"></i> Download Brochure</a>
          <a class="box-btn" href="https://rokid.ai/products/rokid-air-pro/" target="_blank">Read more on Rokid Website</a>
        </div>
      </div>
    </div>
  </div>
</section>

