<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>Integrated Transportation Solutions</h2>
    </div>
  </div>

  <div class="page-shape">
    <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
    <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
    <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
    <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
    <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
  </div>
</div>

<section class="services-details-area pt-70 pb-100">
  <div class="container">
    <div class="row">

      <div class="col-12">
        <div class="services-details-text">
          <div class="scrives-item-2">
            <div class="row align-items-center">

              <div class="col-lg-4">
                <div class="services-img mb-qc">
                  <img class="image" src="assets/images/service/transportation.jpg" title="Image">
                </div>
              </div>
              <div class="col-lg-8">
                <p class="paragraph">Over the years, Gulf Business Development Group (GBDG) has emerged as a pioneer in the dynamic realm of public transportation, reshaping the landscape with a comprehensive suite of solutions and services that redefine the overall passenger experience with enhanced efficiency, comfort, and safety. </p>
                <p class="paragraph">This pursuit delves into GBDG's expertise in enhancing urban mobility solutions by:</p>
                <ul class="list">
                  <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;providing innovative, remotely monitored, and controlled bus shelters;</li>
                  <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;implementing a wide range of onboard systems in luxury buses and VIP fleets;</li>
                  <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;delivering a variety of advanced technology systems in bus stations and depots;</li>
                  <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;providing a suite of integrated real-time passenger information systems across various transit and end points.</li>
                </ul>
                <p class="paragraph pt-20">What sets us apart is our holistic approach to public transportation solutions. We excel at integrating various technology elements, creating a seamless ecosystem where remote monitoring and control of fixed and mobile transportation assets operate cohesively. This integration optimizes the efficiency of the public transportation network and contributes to the overall enhancement of the travel experience offered by the transport operator.</p>
                <p class="paragraph">GBDG’s services for public transportation epitomize a vision for a future-ready transit system. By pioneering innovative solutions across all segments of the transportation network, GBDG propels public transportation into the digital age. As cities and communities embrace the need for smart, efficient, and passenger-centric transit solutions, GBDG stands at the forefront, shaping the future of public transportation.</p>
                <p class="paragraph">Our integrated transportation solutions and 24/7 technical support services have played a pivotal role in the success of major international events hosted by the State of Qatar, such as the FIFA World Cup 2022, the AFC Asian Cup 2023, and the Qatar Expo 2023. GBDG’s successful contributions to these mega-events in Qatar not only established its capacity to deliver large-scale transport management systems but also enabled the country’s national transport operator to effectively manage the crowd movement, reduce traffic congestion, and ensure safety and security for all attendees.</p>
              </div>
              <div class="col-lg-4">
                <div class="services-img mb-qc">
                  <img class="image2" src="assets/images/events/fifa-2022.webp" title="Image">
                </div>
              </div>
              <div class="col-lg-4">
                <div class="services-img mb-qc">
                  <img class="image2" src="assets/images/events/asian-cup-2023.webp" title="Image">
                </div>
              </div>
              <div class="col-lg-4">
                <div class="services-img mb-qc">
                  <img class="image2" src="assets/images/events/expo-2023.jpg" title="Image">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-70 d-flex justify-content-center">
      <a class="box-btn" routerLink="/contact" target="_blank">Contact Us</a>
    </div>
  </div>
</section>
