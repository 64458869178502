import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

    constructor(
        private titleService: Title,
        private meta: Meta,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private doc: Document
    )
    {
        this.titleService.setTitle('GBDG - About Us');
        this.meta.addTag({ name: 'description', content: 'Gulf Business Development Group (GBDG) was founded in 2013 by an ingenious entrepreneur with a strategic vision to transform Qatar\'s land transportation sector through advanced technological solutions and services, aiming to establish a new benchmark for innovation and progress.' });
        this.meta.addTag({ name: 'keywords', content: ' Gulf Business Development Group,Best Technology Company Qatar,Commissioning Services Qatar,GBDG Qatar,Gulf Business Qatar,Qatar Technology Solutions,Supply and Installation Qatar,GBDG Qatar near me,Qatari Company,Business Development Qatar,New Technologies in Qatar,PWA Contractor,Public Works Authority,Ministry of Transportation,Ministry of Interior,PWA Qatar,MoT Qatar,MoI Qatar,Software Qatar,ISO 9001 Certified Company Qatar,ISO 14001 Certified Company Qatar,ISO 45001 Certified Company Qatar,ISO Certified Company Qatar,Trademarks Qatar,Intellectual Properties Qatar,Licenced Contractor Qatar,' });
        this.meta.addTag({ name: 'robots', content: 'all' });
        this.meta.addTag({ name: 'resource-type', content: 'document' });
        this.meta.addTag({ name: 'page-topic', content: 'Gulf Business Development Group' });
        this.meta.addTag({ name: 'clientbase', content: 'Global' });
        this.meta.addTag({ name: 'distribution', content: 'World Wide Web' });
        this.meta.addTag({ name: 'audience', content: 'all' });
        this.meta.addTag({ name: 'rating', content: 'general' });
        this.meta.addTag({ id: 'googlebot', name: 'googlebot', content: 'index, follow' });
        this.meta.addTag({ name: 'expires', content: 'never' });
        this.meta.addTag({ name: 'bingbot', content: ' index, follow ' });
        this.meta.addTag({ name: 'revisit-after', content: 'Daily' });
        this.meta.addTag({ name: 'author', content: 'Gulf Business Development Group' });
        this.meta.addTag({ name: 'HandheldFriendly', content: 'True' });
        this.meta.addTag({ name: 'YahooSeeker', content: 'Index,Follow' });
        this.meta.addTag({ name: 'geo.region', content: 'UAE' });
        this.meta.addTag({ name: 'State', content: 'Qatar' });
        this.meta.addTag({ name: 'City', content: 'Doha' });
        this.meta.addTag({ name: 'subject', content: 'Rated #1 Gulf Business Development Group - About Us' });
    }

    ngOnInit(): void {
        this.setCanonicalURL('https://gulfbdgroup.com/about/');
    }

    setCanonicalURL(url: string) {
        let link: HTMLLinkElement | null = this.doc.querySelector('link[rel="canonical"]') || null;
        if (link) {
            this.renderer.setAttribute(link, 'href', url);
        } else {
            link = this.renderer.createElement('link');
            this.renderer.setAttribute(link, 'rel', 'canonical');
            this.renderer.setAttribute(link, 'href', url);
            this.renderer.appendChild(this.doc.head, link);
        }
    }

    clientWrap: OwlOptions = {
		loop:true,
		margin:30,
		nav:false,
		mouseDrag: true,
		items:1,
		dots: false,
		autoHeight: true,
		autoplay: true,
		smartSpeed: 800,
		autoplayHoverPause: true,
		center: false,
		responsive:{
			0:{
				items:1,
				margin: 10,
			},
			576:{
				items:1,
			},
			768:{
				items:2,
				margin: 20,
			},
			992:{
				items:2,
			},
			1200:{
				items:2,
			}
		}
    }

	// Video Popup
	isOpen = false;
    openPopup(): void {
        this.isOpen = true;
    }
    closePopup(): void {
        this.isOpen = false;
    }

}
