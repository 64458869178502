import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AboutComponent } from './components/pages/about/about.component';
import { ARGlassesComponent } from './components/pages/ar-glasses/ar-glasses.component';
import { AssetDataCollectionComponent } from './components/pages/asset-data-collection/asset-data-collection.component';
import { AssetManagementComponent } from './components/pages/asset-management/asset-management.component';
import { ClientsComponent } from './components/pages/clients/clients.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ElectricalLightingComponent } from './components/pages/electrical-lighting/electrical-lighting.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { HomeComponent } from './components/pages/home/home.component';
import { ICTELVComponent } from './components/pages/ict-elv/ict-elv.component';
import { ITSComponent } from './components/pages/its/its.component';
import { KeyProjectsComponent } from './components/pages/key-projects/key-projects.component';
import { LicensesComponent } from './components/pages/licenses/licenses.component';
import { OAndMComponent } from './components/pages/o-and-m/o-and-m.component';
import { PartnersComponent } from './components/pages/partners/partners.component';
import { PassengerInfoComponent } from './components/pages/passenger-info/passenger-info.component';
import { PatentsComponent } from './components/pages/patents/patents.component';
import { PLCSCADAComponent } from './components/pages/plc-scada/plc-scada.component';
import { ProjectMapComponent } from './components/pages/project-map/project-map.component';
import { QualityComponent } from './components/pages/quality/quality.component';
import { RecognitionsComponent } from './components/pages/recognitions/recognitions.component';
import { RoadSurveyComponent } from './components/pages/road-survey/road-survey.component';
import { SecurityELVComponent } from './components/pages/security-elv/security-elv.component';
import { SoftwareComponent } from './components/pages/software/software.component';
import { TransportationComponent } from './components/pages/transportation/transportation.component';

import { HomeOneComponent } from './components/pages/examples/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/examples/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/examples/home-three/home-three.component';
import { SolutionsComponent } from './components/pages/examples/solutions/solutions.component';
import { SolutionsDetailsComponent } from './components/pages/examples/solutions-details/solutions-details.component';
import { CaseStudiesComponent } from './components/pages/examples/case-studies/case-studies.component';
import { CaseStudiesDetailsComponent } from './components/pages/examples/case-studies-details/case-studies-details.component';
import { BlogComponent } from './components/pages/examples/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/examples/blog-details/blog-details.component';
import { TeamComponent } from './components/pages/examples/team/team.component';
import { PricingComponent } from './components/pages/examples/pricing/pricing.component';
import { GalleryComponent } from './components/pages/examples/gallery/gallery.component';
import { TestimonialsComponent } from './components/pages/examples/testimonials/testimonials.component';
import { SignUpComponent } from './components/pages/examples/sign-up/sign-up.component';
import { SignInComponent } from './components/pages/examples/sign-in/sign-in.component';
import { FaqComponent } from './components/pages/examples/faq/faq.component';
import { TermsConditionsComponent } from './components/pages/examples/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/examples/privacy-policy/privacy-policy.component';

const routes: Routes = [
    {path: 'about', component: AboutComponent},
    {path: 'clients', component: ClientsComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'electrical-lighting', component: ElectricalLightingComponent},
    {path: 'error', component: ErrorComponent},
    {path: '', component: HomeComponent},
    {path: 'ict-elv', component: ICTELVComponent},
    {path: 'its', component: ITSComponent},
    {path: 'key-projects', component: KeyProjectsComponent},
    {path: 'licenses-and-certifications', component: LicensesComponent},
    {path: 'o-and-m', component: OAndMComponent},
    {path: 'papercast', component: PassengerInfoComponent},
    {path: 'partners-and-suppliers', component: PartnersComponent},
    {path: 'plc-scada', component: PLCSCADAComponent},
    {path: 'project-map', component: ProjectMapComponent},
    {path: 'quality-and-safety', component: QualityComponent},
    {path: 'recognitions', component: RecognitionsComponent},
    {path: 'rokid', component: ARGlassesComponent},
    {path: 'romdas', component: RoadSurveyComponent},
    {path: 'road-network-surveys', component: AssetDataCollectionComponent},
/*     {path: 'security-elv', component: SecurityELVComponent}, */
    {path: 'asset-management', component: AssetManagementComponent},
    {path: 'software', component: SoftwareComponent},
    {path: 'trademarks-and-ips', component: PatentsComponent},
    {path: 'transportation', component: TransportationComponent},

    {path: 'home-one', component: HomeOneComponent},
    {path: 'home-two', component: HomeTwoComponent},
    {path: 'home-three', component: HomeThreeComponent},
    {path: 'solutions', component: SolutionsComponent},
    {path: 'solutions-details', component: SolutionsDetailsComponent},
    {path: 'case-studies', component: CaseStudiesComponent},
    {path: 'case-studies-details', component: CaseStudiesDetailsComponent},
    {path: 'blog', component: BlogComponent},
    {path: 'blog-details', component: BlogDetailsComponent},
    {path: 'team', component: TeamComponent},
    {path: 'pricing', component: PricingComponent},
    {path: 'gallery', component: GalleryComponent},
    {path: 'testimonials', component: TestimonialsComponent},
    {path: 'sign-up', component: SignUpComponent},
    {path: 'sign-in', component: SignInComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'terms-condition', component: TermsConditionsComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    // Here add new pages component

    {path: '**', component: ErrorComponent} // This line will remain down from the whole pages component list

];

const routerOptions: any = {
  useHash: false,
  anchorScrolling: 'enabled',
  // ...any other options you'd like to use
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
