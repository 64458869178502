<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>Clients</h2>
    </div>
  </div>

  <div class="page-shape">
    <div class="shape1"><img src="assets/images/shape/1.png" title="shape" /></div>
    <div class="shape3"><img src="assets/images/shape/3.png" title="shape" /></div>
    <div class="shape4"><img src="assets/images/shape/4.png" title="shape" /></div>
    <div class="shape5"><img src="assets/images/shape/5.png" title="shape" /></div>
    <div class="shape6"><img src="assets/images/shape/6.png" title="shape" /></div>
  </div>
</div>

<section class="services-details-area mt-70 ptb-20">
  <div class="container">
    <div class="row">

      <div class="col-12">
        <div class="services-details-text">
          <div class="scrives-item-2">
            <div class="row align-items-center">
              <div class="col-lg-4">
                <div class="services-img mb-qc">
                  <img src="assets/images/info/i7.jpg" title="Image">
                </div>
              </div>

              <div class="col-lg-8">
                <p class="paragraph">GBDG takes pride in the lasting relationships we've built with our esteemed clients. These long-standing client associations have been at the core of our success and have empowered our journey with consistent growth and sustainability.<br/><br/>
                  We owe sincere appreciation to our following clients for placing their trust in GBDG and for their continued support and cooperation:
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="client-area ptb-100">
  <div class="container">
    <div class="row mt-20" style="justify-content: center;">
      <!--<div class="col-md-3 client"><img src="assets/images/client/al-aali-1.png" title=""></div>-->
      <div class="col-md-3 client"><img src="assets/images/client/ashghal-1.png" title="Public Works Authority"></div>
      <div class="col-md-3 client"><img src="assets/images/client/moi.png" title="Ministry of Interior"></div>
      <div class="col-md-3 client"><img src="assets/images/client/mot.png" title="Ministry of Transportation"></div>
      <div class="col-md-3 client"><img src="assets/images/client/supreme-committee.png" title="Supreme Committee for Delivery & Legacy"></div>
      <div class="col-md-3 client"><img src="assets/images/client/lusail-city-logo-1.png" title=""></div>
      <div class="col-md-3 client"><img src="assets/images/client/aspire-1.png" title="Aspire Zone"></div>
      <div class="col-md-3 client"><img src="assets/images/client/hamad.png" title="Hamad Medical Corporation"></div>
      <div class="col-md-3 client"><img src="assets/images/client/sidra.png" title="Sidra Medicine"></div>
      <div class="col-md-3 client"><img src="assets/images/client/mowasalat-logo-solo-transparent-1.png" title="Mowasalat"></div>
      <div class="col-md-3 client"><img src="assets/images/client/ooredoo-logo-1.png" title="Ooredoo"></div>
      <div class="col-md-3 client"><img src="assets/images/client/qatar-media.png" title="Qatar Media Corporation"></div>
      <div class="col-md-3 client"><img src="assets/images/client/meeza-1.png" title="Meeza"></div>
      <div class="col-md-3 client"><img src="assets/images/client/crcc.png" title="CRCC"></div>
      <div class="col-md-3 client"><img src="assets/images/client/hbk-1.png" title="HBK"></div>
      <div class="col-md-3 client"><img src="assets/images/client/nec-1.png" title="NEC"></div>
      <div class="col-md-3 client"><img src="assets/images/client/ucc.png" title="UCC"></div>
      <div class="col-md-3 client"><img src="assets/images/client/zucchetti-1.png" title="Zucchetti"></div>


      <div class="col-md-3 client"><img src="assets/images/client/al-sraiya.png" title="Al Sraiya"></div>
      <div class="col-md-3 client"><img src="assets/images/client/debbas-1.png" title="Debbas"></div>
      <div class="col-md-3 client"><img src="assets/images/client/emovis-1.png" title="Emovis"></div>
      <div class="col-md-3 client"><img src="assets/images/client/get.png" title="GET"></div>
      <div class="col-md-3 client"><img src="assets/images/client/dogus-1.png" title="Dogus"></div>
      <div class="col-md-3 client"><img src="assets/images/client/galfar-1.png" title="Galfar"></div>
      <div class="col-md-3 client"><img src="assets/images/client/madar.png" title="Madar"></div>
      <div class="col-md-3 client"><img src="assets/images/client/gsic-jv.png" title="GSIC JV"></div>
      <div class="col-md-3 client"><img src="assets/images/client/isg.png" title="ISG"></div>
      <div class="col-md-3 client"><img src="assets/images/client/midmac-2.png" title="Midmac"></div>
      <div class="col-md-3 client"><img src="assets/images/client/ccc-1.png" title="CCC"></div>
      <div class="col-md-3 client"><img src="assets/images/client/bojamhoor-1.png" title="Bojamhoor"></div>
      <div class="col-md-3 client"><img src="assets/images/client/qdsbg-1.png" title="QD-SGB"></div>
      <!--<div class="col-md-3 client"><img src="assets/images/client/qsp-2.png" title=""></div>-->
      <!--<div class="col-md-3 client"><img src="assets/images/client/qtec.png" title=""></div>-->
      <div class="col-md-3 client"><img src="assets/images/client/six-contruct.png" title="Six Construct"></div>

      <!--<div class="col-md-3 client"><img src="assets/images/client/vibgyor.png" title=""></div>-->
      <div class="col-md-3 client"><img src="assets/images/client/carriage.png" title="Carriage"></div>
    </div>
  </div>
</section>


