<div class="page-title-area">
  <div class="container">
    <div class="page-title-content pb-20">
      <h2>Key Projects</h2>
      <span>A glimpse of our successful history</span>
    </div>
  </div>

  <div class="page-shape">
    <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
    <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
    <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
    <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
    <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
  </div>
</div>

<section class="home-case mt-70 pt-20 pb-20">
  <div class="container">
    <div class="case">
      <ul class="nav-tabset">
        <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
          <span (click)="switchTab($event, 'tab1')">
            Roads & Transport Infrastructure
          </span>
        </li>
        <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab2'}">
          <span (click)="switchTab($event, 'tab2')">
            Buildings Infrastructure
          </span>
        </li>
        <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab3'}">
            <span (click)="switchTab($event, 'tab3')">
              Stadiums
            </span>
        </li>
        <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab4'}">
            <span (click)="switchTab($event, 'tab4')">
              Data Centers
            </span>
        </li>
        <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab5'}">
            <span (click)="switchTab($event, 'tab5')">
              Operations & Maintenance
            </span>
        </li>
      </ul>
      <div class="tabs-container">
        <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">

          <section class="project-area pb-20" id="mqt-its">
            <div class="container">
              <div class="row flex-lg-row-reverse">
                <div class="col-lg-6 vertical-center">
                  <div class="project-img">
                    <img src="assets/images/projects/mqt1.jpg" alt="Project" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="title">
                    <span class="project-type">ITS, ELV and PLC/SCADA Tunnel Management System Implementation </span><br/>
                    <h3>Mall of Qatar Tunnel</h3>
                  </div>
                  <div class="content">
                    Gulf Business Development Group successfully commissioned the state-of-the-art integrated ITS, ELV, and Tunnel Management System (SCADA) in Qatar as part of the P004-Dukhan Central Highway/Mall of Qatar Tunnel Contract under the direct supervision of Ashghal, wherein GBDG successfully implemented a fully integrated and unified Intelligent Transportation System (ITS) and SCADA Platform covering multiple subsystems in compliance with Qatar ITS Standards and specifications:
                    <ul>
                      <li><i class="bi bi-dot"></i>Unified ITS/ELV &amp; MEP SCADA Tunnel Management System Platform</li>
                      <li><i class="bi bi-dot"></i>Master PLC System</li>
                      <li><i class="bi bi-dot"></i>CCTV System</li>
                      <li><i class="bi bi-dot"></i>Automatic Incident Detection System</li>
                      <li><i class="bi bi-dot"></i>Automatic Number Plate Recognition System</li>
                      <li><i class="bi bi-dot"></i>Magnetometer Vehicle Detection System</li>
                      <li><i class="bi bi-dot"></i>Dynamic Message Signs</li>
                      <li><i class="bi bi-dot"></i>Lane Control Signs</li>
                      <li><i class="bi bi-dot"></i>Public Address System</li>
                      <li><i class="bi bi-dot"></i>Access Control System</li>
                      <li><i class="bi bi-dot"></i>Emergency Telephone System</li>
                      <li><i class="bi bi-dot"></i>Tunnel Control Room IP Telephony</li>
                      <li><i class="bi bi-dot"></i>Video wall and CCTV Decoders</li>
                      <li><i class="bi bi-dot"></i>Active Components</li>
                      <li><i class="bi bi-dot"></i>Servers &amp; Storage</li>
                      <li><i class="bi bi-dot"></i>Operator Workstations, including the Control Room Furniture.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="project-area pb-20" id="saadiyat">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 vertical-center">
                  <div class="project-img">
                    <img src="assets/images/projects/saadiyat.jpg" alt="Project" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="title">
                    <span class="project-type">ITS, ELV and PLC/SCADA Tunnel Management System Implementation </span><br/>
                    <h3>Saadiyat Tunnel</h3>
                  </div>
                  <div class="content">
                    GBDG successfully commissioned the integrated ITS and Tunnel Management System in the Saadiyat Tunnel, a private access service tunnel constructed at the Cultural District, Saadiyat Island in Abu Dhabi, to provide underground access for service vehicles to the Guggenheim Abu Dhabi, Louvre Abu Dhabi, Zayed National Museum, and the District Mall.<br/><br/>The scope of the project included the provision of the following subsystems:
                    <ul>
                      <li><i class="bi bi-dot"></i>Unified ITS/ELV & MEP SCADA Tunnel Management System Platform</li>
                      <li><i class="bi bi-dot"></i>CCTV System </li>
                      <li><i class="bi bi-dot"></i>Automatic Incident Detection System</li>
                      <li><i class="bi bi-dot"></i>Automatic Number Plate Recognition System</li>
                      <li><i class="bi bi-dot"></i>Magnetometer Vehicle Detection System</li>
                      <li><i class="bi bi-dot"></i>Dynamic Message Signs</li>
                      <li><i class="bi bi-dot"></i>Lane Control Signs</li>
                      <li><i class="bi bi-dot"></i>Video wall and CCTV Decoders</li>
                      <li><i class="bi bi-dot"></i>Active Components</li>
                      <li><i class="bi bi-dot"></i>Servers &amp; Storage</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="project-area pb-20" id="khalifa-its">
            <div class="container">
              <div class="row flex-lg-row-reverse">
                <div class="col-lg-6 vertical-center">
                  <div class="project-img">
                    <img src="assets/images/projects/khalifa.jpg" alt="Project" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="title">
                    <span class="project-type">ITS, ELV and PLC/SCADA Tunnel Management System Implementation </span><br/>
                    <h3>Dukhan Road (East)/Khalifa Avenue </h3>
                  </div>
                  <div class="content">
                    Gulf Business Development Group successfully implemented the turnkey contract for the implementation of the following systems and scope of work items for the Dukhan Road (East)/Khalifa Avenue Project (including 2 tunnels and 4 underpasses) in compliance with local ITS standards and specifications:
                    <ul>
                      <li><i class="bi bi-dot"></i>Unified ITS/ELV &amp; MEP SCADA Tunnel Management System Platform</li>
                      <li><i class="bi bi-dot"></i>Master PLC System</li>
                      <li><i class="bi bi-dot"></i>CCTV System</li>
                      <li><i class="bi bi-dot"></i>Automatic Incident Detection System</li>
                      <li><i class="bi bi-dot"></i>Automatic Number Plate Recognition System</li>
                      <li><i class="bi bi-dot"></i>Magnetometer Vehicle Detection System</li>
                      <li><i class="bi bi-dot"></i>Dynamic Message Signs</li>
                      <li><i class="bi bi-dot"></i>Lane Control Signs</li>
                      <li><i class="bi bi-dot"></i>Public Address System</li>
                      <li><i class="bi bi-dot"></i>Access Control System</li>
                      <li><i class="bi bi-dot"></i>Emergency Telephone System</li>
                      <li><i class="bi bi-dot"></i>Tunnel Control Room IP Telephony</li>
                      <li><i class="bi bi-dot"></i>Road Weather Information Systems</li>
                      <li><i class="bi bi-dot"></i>Radio Rebroadcast System</li>
                      <li><i class="bi bi-dot"></i>Video wall and CCTV Decoders</li>
                      <li><i class="bi bi-dot"></i>Active Components</li>
                      <li><i class="bi bi-dot"></i>Servers &amp; Storage</li>
                      <li><i class="bi bi-dot"></i>Operator Workstations, including the Control Room Furniture.</li>
                    </ul><br/>
                    In addition to the above scope, GBDG has also carried out the design and installation of fifteen (15) ITS gantries and commissioned the main PLC, primary fiber backbone network, including several field equipment and distribution cabinets, structured cabling, and network management system as part of this project.
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="project-area pb-20" id="albustan-its">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 vertical-center">
                  <div class="project-img">
                    <img src="assets/images/projects/bustan.jpeg" alt="Project" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="title">
                    <span class="project-type">ITS, ELV and PLC/SCADA Tunnel Management System Implementation </span><br/>
                    <h3>Al Bustan Street North</h3>
                  </div>
                  <div class="content">
                    GBDG successfully delivered the contract for the implementation of the following systems and scope of work items for the Al Bustan North Road Project (including the two-way "K1" tunnel) in compliance with local ITS standards and specifications:
                    <ul>
                      <li><i class="bi bi-dot"></i>Unified ITS/ELV &amp; MEP SCADA Tunnel Management System Platform</li>
                      <li><i class="bi bi-dot"></i>Master PLC System</li>
                      <li><i class="bi bi-dot"></i>CCTV System</li>
                      <li><i class="bi bi-dot"></i>Automatic Incident Detection System</li>
                      <li><i class="bi bi-dot"></i>Automatic Number Plate Recognition System</li>
                      <li><i class="bi bi-dot"></i>Magnetometer Vehicle Detection System</li>
                      <li><i class="bi bi-dot"></i>Dynamic Message Signs</li>
                      <li><i class="bi bi-dot"></i>Lane Control Signs</li>
                      <li><i class="bi bi-dot"></i>Public Address System</li>
                      <li><i class="bi bi-dot"></i>Access Control System</li>
                      <li><i class="bi bi-dot"></i>Emergency Telephone System</li>
                      <li><i class="bi bi-dot"></i>Tunnel Control Room IP Telephony</li>
                      <li><i class="bi bi-dot"></i>Radio Rebroadcast System</li>
                      <li><i class="bi bi-dot"></i>Video wall and CCTV Decoders</li>
                      <li><i class="bi bi-dot"></i>Active Components</li>
                      <li><i class="bi bi-dot"></i>Servers &amp; Storage</li>
                      <li><i class="bi bi-dot"></i>Operator Workstations, including the Control Room Furniture.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="project-area pb-20" id="congestion">
            <div class="container">
              <div class="row flex-lg-row-reverse">
                <div class="col-lg-6 vertical-center">
                  <div class="project-img">
                    <img src="assets/images/projects/congesting-charging.jpg" alt="Project" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="title">
                    <span class="project-type">ITS Implementation</span><br/>
                    <h3>Congestion Charging</h3>
                  </div>
                  <div class="content">
                    <p>The Qatar Ministry of Transportation (MoT), in collaboration with the Public Works Authority (Ashghal), introduced the project for the implementation of a congestion charging system as part of their measures to ease the country’s high traffic during peak hours, boost the efficiency of the local transportation system, and promote safer, cleaner, and more environmentally friendly alternatives.</p>
                    <p>GBDG was awarded the contract for the supply, installation, testing, and commissioning of all the field-side congestion charging system equipment and other related works for 15 traffic signal junctions corresponding to 114 controlled lanes in different parts of Doha.</p>
                    The scope of works included:
                    <ul class="justify-align-center">
                      <li><i class="bi bi-dot"></i>Supply, installation and commissioning of Automated Number Plate Reader (ANPR) Cameras</li>
                      <li><i class="bi bi-dot"></i>Supply, installation and commissioning of ground and pole-mounted enclosures</li>
                      <li><i class="bi bi-dot"></i>Supply, installation and commissioning of power and fiber optic cabling works</li>
                      <li><i class="bi bi-dot"></i>System configuration, testing and commissioning</li>
                      <li><i class="bi bi-dot"></i>Traffic Management</li>
                      <li><i class="bi bi-dot"></i>Associated minor civil works, as necessary</li>
                      <li><i class="bi bi-dot"></i>Preparation of As-Built Drawings</li>
                    </ul><br/>
                    Following the overall success of the implementation phase, GBDG was also awarded a comprehensive operations and maintenance contract for the duration of three (3) years, covering all 15 junctions.
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="project-area pb-20" id="tolling">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 vertical-center">
                  <div class="project-img">
                    <img src="assets/images/projects/tolling.jpg" alt="Project" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="title">
                    <span class="project-type">ITS Implementation</span><br/>
                    <h3>Tolling Gates</h3>
                  </div>
                  <div class="content">
                    <p>Ashghal commenced Qatar’s implementation of the road tolling program to fulfill the transportation strategies defined under the Transportation Masterplan for Qatar (TMPQ). The primary objective of tolling is to reduce congestion, manage the high demand for transportation, and aim to achieve a balance between public and private transportation. </p>
                    <p>The project also aims to introduce in the country the first free flow tolling system as part of the infrastructure development plan in line with the Qatar National Vision 2030. </p>
                    GBDG successfully carried out the supply, installation, testing, and commissioning of a state-of-the-art video-based free-flow electronic toll collection system (ETCS) across four (4) tolling gantries, covering the following scope:
                    <ul class="justify-align-center">
                      <li><i class="bi bi-dot"></i>Supply, installation and commissioning of Automated Vehicle Detection and Classification (AVDC) cameras</li>
                      <li><i class="bi bi-dot"></i>Supply, installation and commissioning of Automated Number Plate Reader (ANPR) cameras</li>
                      <li><i class="bi bi-dot"></i>Supply, installation and commissioning of Tolling Gantry video monitoring cameras</li>
                      <li><i class="bi bi-dot"></i>Supply, installation and commissioning of intermediate cabinets and roadside enclosures equipped with servers, switches and other communication equipment</li>
                      <li><i class="bi bi-dot"></i>Power and Fiber Optic Cabling works</li>
                      <li><i class="bi bi-dot"></i>System configuration, testing and commissioning</li>
                      <li><i class="bi bi-dot"></i>Traffic Management</li>
                      <li><i class="bi bi-dot"></i>Associated minor civil works, as necessary</li>
                      <li><i class="bi bi-dot"></i>Preparation of As-Built Drawings</li>
                    </ul><br/>
                    Following the overall success of the implementation phase, GBDG was also awarded the comprehensive operations and maintenance contract for the duration of three (3) years for the complete tolling solution.
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="project-area pb-20" id="nec-transportation">
            <div class="container">
              <div class="row flex-lg-row-reverse">
                <div class="col-lg-6 vertical-center">
                  <div class="project-img">
                    <img src="assets/images/projects/buses.jpg" alt="Project" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="title">
                    <span class="project-type">Integrated Public Transportation Solutions</span><br/>
                    <h3>ITMS Implementation for FIFA World Cup Qatar 2022™</h3>
                  </div>
                  <div class="content">
                    Over recent years, Qatar’s transport infrastructure has been significantly transformed to provide fast and efficient land transport options for citizens, residents, and visitors arriving for various key events, e.g., the FIFA World Cup 2022, Expo 2023, the AFC Asian Cup 2023, etc.<br/><br/>

                    Guided by Qatar's National Vision 2030, which aims to reduce the country’s reliance on hydrocarbons, the Supreme Committee for Delivery and Legacy (SC) worked with a range of stakeholders to accelerate a host of transport projects ahead of the World Cup, including a new eco-friendly bus fleet.<br/><br/>

                    GBDG was awarded the sub-contract for supply, installation, testing, and commissioning of a state-of-the-art intelligent transportation management system comprising the following key sub-systems in 3000 Mowasalat buses:
                    <ul class="justify-align-center">
                      <li><i class="bi bi-dot"></i>In-vehicle Central Monitoring System (IVMS) </li>
                      <li><i class="bi bi-dot"></i>On-board Driver Control Panel (ODCP) </li>
                      <li><i class="bi bi-dot"></i>RFID-based travel ticket reader units </li>
                      <li><i class="bi bi-dot"></i>Onboard communication system  </li>
                      <li><i class="bi bi-dot"></i>External Digital Display (EDD) control units </li>
                      <li><i class="bi bi-dot"></i>On-board Passenger information displays </li>
                      <li><i class="bi bi-dot"></i>Passenger counting system  </li>
                      <li><i class="bi bi-dot"></i>Media controllers and other related ancillary HW and SW.</li>
                    </ul><br/>
                    GBDG delivered the project on time and in compliance with the local MoT regulations, with the utmost satisfaction from the client. Starting in 2022, GBDG has also been awarded to provide maintenance services for the installed equipment and systems until 2025.
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="project-area pb-20" id="busstop-transportation">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 vertical-center">
                  <div class="project-img">
                    <img src="assets/images/projects/busstop.webp" alt="Project" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="title">
                    <span class="project-type">Integrated Public Transportation Solutions</span><br/>
                    <h3>Intelligent Bus Stops</h3>
                  </div>
                  <div class="content">
                    As part of the new Public Bus Infrastructure Upgrade Program, the Ministry of Transportation (MoT) introduced an ambitious project to install 2000+ intelligent bus stops in the state of Qatar, in cooperation with the Public Works Authority (Ashghal).<br/><br/>

                    Relying on its in-depth expertise and experience in the intelligent transportation systems domain, GBDG embarked on this prestigious project in collaboration with Bojamhoor Trading and Contracting Company (BTCC) and delivered the following key scope elements:
                    <ul class="justify-align-center">
                      <li><i class="bi bi-dot"></i>Concept Design Services for Air-conditioned, Normal Bus Shelters, and Bus Stop Poles </li>
                      <li><i class="bi bi-dot"></i>Supply, installation, and commissioning of real-time passenger information displays</li>
                      <li><i class="bi bi-dot"></i>Supply, installation, and commissioning of CCTV systems.</li>
                      <li><i class="bi bi-dot"></i>Supply, installation, and commissioning of environmental sensors and access control systems</li>
                      <li><i class="bi bi-dot"></i>Supply, installation, and commissioning of solar power systems</li>
                      <li><i class="bi bi-dot"></i>Design, Development, Installation and Commissioning of <strong>Bus iStop™</strong>, a web-based remote monitoring and control software platform deployed to facilitate the remote operation and maintenance of the air-conditioned bus shelters.</li>
                    </ul><br/>
                    Although the project has yet to be completed, the majority of the priority bus stops were successfully commissioned and widely utilized during the FIFA Qatar 2022 World Cup event.
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="project-area pb-20" id="busstation-transportation">
            <div class="container">
              <div class="row flex-lg-row-reverse">
                <div class="col-lg-6 vertical-center">
                  <div class="project-img">
                    <img src="assets/images/projects/busstation.jpg" alt="Project" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="title">
                    <span class="project-type">Integrated Public Transportation Solutions</span><br/>
                    <h3>Access Control Systems for Bus Stations</h3>
                  </div>
                  <div class="content">
                    As part of Qatar's effort to provide efficient transportation services, the National Public Transportation Operator Mowasalat introduced modernized bus stations at various locations in Al Sudan, Al Gharrafa, Lusail, Al Rayyan Al Jadeed, Al Wakrah, Msheireb, West Bay, and the Industrial Area.<br/><br/>

                    GBDG was awarded contracts from different main contractors for the supply, installation, testing, and commissioning of the customized heavy-duty sliding gates for access control in each of the new bus stations.<br/><br/>

                    Our engineers worked closely with the design consultants and the concerned OEM to develop a customized solution, considering the various functional requirements and constraints, and successfully deployed and commissioned the system across all the locations, allowing for the smooth management of the inbound and outbound passengers.
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div><!-- Road & Transport -->
        <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
          <section class="project-area pb-20" id="qmc-ictelv">
            <div class="container">
              <div class="row flex-lg-row-reverse">
                <div class="col-lg-6 vertical-center">
                  <div class="project-img">
                    <img src="assets/images/projects/qmc.png" alt="Project" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="title">
                    <span class="project-type">ICT and ELV Systems </span><br/>
                    <h3>Qatar Media Corporation</h3>
                  </div>
                  <div class="content">
                    GBDG is pleased to have been awarded the contract for the supply, installation, testing, and commissioning of ELV systems for the Qatar Media Corporation Studios and its Administration Office at 37 TV. This project underscores our commitment to delivering comprehensive solutions tailored to the specific needs of our clients.<br/><br/>

                    The scope of work included the implementation of Access control system, CCTV, Structured cabling and Networking solution, Gate barrier & Speed gates GBDG's approach involved a meticulous process, ensuring the seamless integration of these critical systems to enhance the operational efficiency of the Qatar Media Corporation Studios & Admin Office.<br/><br/>

                    We are proud to announce the successful delivery of this project, with a focus on compliance with the applicable MOI-SSD regulations and client requirements. This accomplishment reflects our dedication to professionalism and adherence to industry standards throughout the supply, installation, testing, and commissioning phases.<br/><br/>

                    GBDG remains committed to providing reliable and tailored solutions. This successful project serves as a testament to our capabilities in delivering complex projects while meeting and exceeding the expectations of our clients. We look forward to continuing our commitment to excellence in future endeavors.
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="project-area pb-20" id="military-medical-city">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 vertical-center">
                  <div class="project-img">
                    <img src="assets/images/projects/military-medical-city.jpg" alt="Project" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="title">
                    <span class="project-type">ICT and ELV Systems </span><br/>
                    <h3>Military Medical City Hospital</h3>
                  </div>
                  <div class="content">
                    GBDG is pleased to have been awarded the contract for the Engineering, Procurement, and Construction (EPC/TURNKEY) contract for the Military Medical City Hospital at Tarek Bin Zeyad Camp in Sailiyah. This project signifies our commitment to delivering the Vehicle Access control solution for the healthcare infrastructure solution tailored to the unique needs of the military community.<br/><br/>

                    The systems that shall be implementation are Vehicle Access control solution such as Under vehicle scanning system, Road Blockers, Gate barriers along the perimeter of the premise. Our approach involves a meticulous coordination of the Engineering, Procurement, and Construction phases to ensure the successful development of this crucial healthcare facility. From initial engineering design to the final stages of construction, GBDG is dedicated to meeting and surpassing the highest standards.<br/><br/>

                    Throughout the project, we prioritize compliance with regulations and client requirements, showcasing our commitment to professionalism. The Military Medical City Hospital project reflects our dedication to contributing meaningfully to national healthcare infrastructure, providing a state-of-the-art facility that addresses the specific medical requirements of military personnel.<br/><br/>

                    The successful completion of the Military Medical City Hospital project is anticipated to reinforce GBDG's reputation for delivering on complex initiatives. We remain steadfast in our commitment to not only meet but exceed expectations, ensuring the Military Medical City Hospital becomes a cornerstone in providing exceptional healthcare services to the Tarek Bin Zeyad Camp military community in Sailiyah.
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="project-area pb-20" id="hbk-office">
            <div class="container">
              <div class="row flex-lg-row-reverse">
                <div class="col-lg-6 vertical-center">
                  <div class="project-img">
                    <img src="assets/images/projects/hbk.jpeg" alt="Project" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="title">
                    <span class="project-type">ICT and ELV Systems </span><br/>
                    <h3>HBK Holding Headquarters </h3>
                  </div>
                  <div class="content">
                    GBDG has been awarded the contract for the Supply, Installation, Testing & Commissioning of Security Speed Gates for the HBK Holding Headquarters in Lusail City. Our commitment is to deliver a reliable and tailored security solution that aligns with the specific requirements of the HBK Headquarters environment. This project involves a meticulous approach to procurement, installation, and commissioning processes to ensure the seamless integration of security turnstiles, enhancing the overall security infrastructure of the building.<br/><br/>

                    The successful completion of the Supply, Installation, Testing & Commissioning of Security Turnstiles project for the HBK Headquarters building is anticipated to further solidify GBDG's reputation for providing reliable security solutions. We remain dedicated to ensuring that the security speed gates not only meet but exceed expectations, ultimately providing a robust and effective security measure for the occupants of the HBK office building in Lusail City.
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div><!-- Building -->
        <div class="pane" id="tab3" *ngIf="currentTab === 'tab3'">

          <section class="project-area pb-20" id="lusail-stadium-ictelv">
            <div class="container">
              <div class="row flex-lg-row-reverse">
                <div class="col-lg-6 vertical-center">
                  <div class="project-img">
                    <img src="assets/images/projects/lusail-stadium.jpg" alt="Project" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="title">
                    <span class="project-type">ICT and ELV Systems </span><br/>
                    <h3>Lusail Stadium</h3>
                  </div>
                  <div class="content">
                    Lusail Stadium hosted the FIFA World Cup Qatar 2022™ Final, an event that secured its place in football and architectural history. Immense yet exquisite, the scale and splendor of this arena are a wonder, built to lie in harmony with its surroundings. Its design reflects the hand-crafted bowls found all across the Arab and Islamic worlds during the rise of civilization, while interplays of light mirror the fanar lanterns of the region.<br/><br/>

                    GBDG is proud to have been awarded the contract for the supply, installation, testing, and commissioning of turnstiles and ticketing access control systems, as well as the hostile vehicle mitigation system, including road blockers, bolts, and gate barriers for this iconic stadium.<br/><br/>

                    GBDG delivered the project on time and in compliance with the applicable Ministry of Interior (MOI), Supreme Committee for Delivery & Legacy, and Qatar Civil Defense Department (QCDD) regulations and requirements, with the utmost satisfaction and appreciation from the client.
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="project-area pb-20" id="974-stadium-ictelv">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 vertical-center">
                  <div class="project-img">
                    <img src="assets/images/projects/974-stadium.png" alt="Project" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="title">
                    <span class="project-type">ICT and ELV Systems </span><br/>
                    <h3>Stadium 974</h3>
                  </div>
                  <div class="content">
                    The FIFA World Cup 2022™ in Qatar was the first of its kind to be hosted in the Middle East and the most geographically compact since the inaugural edition of the tournament in 1930. The FIFA World Cup 2022™ was unique in many respects, including in the types of opportunities and challenges it presented in relation to sustainability.<br/><br/>

                    Stadium 974 was constructed entirely from shipping containers and modular steel; it was the first fully demountable covered football stadium. This unique venue paid tribute to Qatar’s long-standing tradition of worldwide trade and seafaring. Not only is 974 the international dialing code for Qatar, but it is also the exact number of shipping containers used in its construction.<br/><br/>

                    GBDG is proud to have been awarded the contract for supply, installation, testing, and commissioning of turnstiles and ticketing access control systems for the stadium.<br/><br/>

                    GBDG delivered the project on time and in compliance with the applicable Ministry of Interior (MOI), Supreme Committee for Delivery and Legacy, and Qatar Civil Defense Department (QCDD) regulations and requirements, with the utmost satisfaction and appreciation from the client.
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="project-area pb-20" id="khalifa-stadium-ictelv">
            <div class="container">
              <div class="row flex-lg-row-reverse">
                <div class="col-lg-6 vertical-center">
                  <div class="project-img">
                    <img src="assets/images/projects/khalifa-stadium.jpg" alt="Project" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="title">
                    <span class="project-type">ICT and ELV Systems </span><br/>
                    <h3>Khalifa International Stadium</h3>
                  </div>
                  <div class="content">
                    Khalifa International Stadium has hosted a long list of momentous sporting events since its inauguration in 1976. The iconic stadium received a complete refit for the FIFA World Cup 2022™ as a reward for its long-standing service.<br/><br/>

                    A key element of the structural and technical measures taken into consideration during the upgrade of the stadium for the safety of the crowd was the access control system.<br/><br/>

                    GBDG was chosen to undertake the supply, installation, testing, and commissioning of turnstiles and ticketing access control systems for the stadium for the renovation project.<br/><br/>

                    GBDG is proud to have successfully completed its scope of work within the project’s stipulated time frame and to the utmost satisfaction of its client.
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="project-area pb-20" id="bayt-stadium-ictelv">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 vertical-center">
                  <div class="project-img">
                    <img src="assets/images/projects/bayt-stadium.jpg" alt="Project" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="title">
                    <span class="project-type">ICT and ELV Systems </span><br/>
                    <h3>Al Bayt Stadium</h3>
                  </div>
                  <div class="content">
                    Inspired by the bayt al sha’ar of Qatar’s nomadic people, the Al Bayt stadium’s magnificent tent structure envelopes an ultra-modern football stadium. During the tournament, the stadium hosted five group matches, including the opening match, a round of 16 matches, a quarterfinal, and a semifinal.<br/><br/>

                    A key element of the structural and technical measures taken into consideration during the design of the stadium for the safety of the crowd during events was the access control system.<br/><br/>

                    GBDG was selected for the supply, installation, testing, and commissioning of the turnstile system and high-performance EM door locks for both the perimeter and internal security of the Al Bayt Stadium.<br/><br/>

                    GBDG carried out the installation and commissioning of the required numbers of stainless-steel tripod turntables and EM locks and integrated the with the main access control platform chosen for the Al Bayt Stadium.
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div><!-- Stadium -->
        <div class="pane" id="tab4" *ngIf="currentTab === 'tab4'">
          <section class="project-area pb-20" id="ooredoo-qcs5-ictelv">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 vertical-center">
                  <div class="project-img">
                    <img src="assets/images/projects/ooredoo-qdc5.jpg" alt="Project" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="title">
                    <span class="project-type">ICT and ELV Systems </span><br/>
                    <h3>Ooredoo Data Center (QDC 5)</h3>
                  </div>
                  <div class="content">
                    GBDG was awarded the turnkey contract for supply, installation, testing, and commissioning of ELV and fire suppression systems for Ooredoo Mesaimeer Data Center (QDC5), Zones 4 and 5. The overall project scope covers the following systems and items:
                    <ul class="justify-align-center">
                      <li><i class="bi bi-dot"></i>CCTV System (including Video Analytics)</li>
                      <li><i class="bi bi-dot"></i>Access Control System, Building Management System</li>
                      <li><i class="bi bi-dot"></i>Fire Alarm and Public Address System and High-Sensitivity Smoke Detection System for the Data Center areas</li>
                      <li><i class="bi bi-dot"></i>NOVEC 1230-based Automatic Gas Suppression System and its integration with main Fire Alarm Control Panel.</li>
                    </ul><br/>
                    GBDG successfully delivered this project in compliance with the applicable Ministry of Interior (MoI) and Qatar Civil Defense Department (QCDD) regulations and requirements.
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="project-area pb-20" id="ooredoo-microsoft-ictelv">
            <div class="container">
              <div class="row flex-lg-row-reverse">
                <div class="col-lg-6 vertical-center">
                  <div class="project-img">
                    <img src="assets/images/projects/microsoft.jpg" alt="Project" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="title">
                    <span class="project-type">ICT and ELV Systems </span><br/>
                    <h3>Ooredoo Microsoft Datacenter</h3>
                  </div>
                  <div class="content">
                    GBDG secured the contract for the supply, installation, testing, and commissioning of the following systems for the Ooredoo Microsoft Data Center:
                    <ul class="justify-align-center">
                      <li><i class="bi bi-dot"></i>Intelligent Structured Cabling </li>
                      <li><i class="bi bi-dot"></i>Network Switches </li>
                      <li><i class="bi bi-dot"></i>Gate Barrier </li>
                      <li><i class="bi bi-dot"></i>Key Management System </li>
                      <li><i class="bi bi-dot"></i>Cable Basket for Data Center Racks </li>
                      <li><i class="bi bi-dot"></i>Fiber Runner System for Data Center Racks </li>
                      <li><i class="bi bi-dot"></i>Secure Containment.</li>
                    </ul><br/>
                    GBDG successfully delivered this project within a tight schedule in compliance with the applicable industry standards for data centers and Microsoft requirements.
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="project-area pb-20" id="meeza-ictelv">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 vertical-center">
                  <div class="project-img">
                    <img src="assets/images/projects/meeza.webp" alt="Project" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="title">
                    <span class="project-type">ICT and ELV Systems </span><br/>
                    <h3>Meeza Datacenter Service Provider</h3>
                  </div>
                  <div class="content">
                    GBDG successfully carried out the supply, installation, testing, and commissioning of the following systems for the MEEZA Microsoft Data Center construction:
                    <ul class="justify-align-center">
                      <li><i class="bi bi-dot"></i>Intelligent Structured Cabling </li>
                      <li><i class="bi bi-dot"></i>Network Switches </li>
                      <li><i class="bi bi-dot"></i>CCTV System </li>
                      <li><i class="bi bi-dot"></i>Access Control System. </li>
                    </ul><br/>
                    GBDG delivered this project in compliance with the applicable MOI-SSD regulations and Microsoft requirements.
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div><!-- Datacenter -->
        <div class="pane" id="tab5" *ngIf="currentTab === 'tab5'">
          <section class="project-area pb-20" id="mqt-om">
            <div class="container">
              <div class="row flex-lg-row-reverse">
                <div class="col-lg-6 vertical-center">
                  <div class="project-img">
                    <img src="assets/images/projects/mqt2.jpg" alt="Project" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="title">
                    <span class="project-type">Operations and Maintenance</span><br/>
                    <h3>Mall of Qatar Tunnel</h3>
                  </div>
                  <div class="content">
                    <p>Following the successful completion of the Mall of Qatar Tunnel ITS implementation project, GBDG was retained by Ashghal to provide operations and maintenance services for the tunnel until the final handover.</p>
                    Subsequently, as part of the Tunnels O&M Framework Package, GBDG was awarded the long-term operations and maintenance contract for the same tunnel, providing the following services:
                    <ul class="justify-align-center">
                      <li><i class="bi bi-dot"></i>Managing 24/7 Monitoring and Operations of the Tunnel Main  Control Room</li>
                      <li><i class="bi bi-dot"></i>Comprehensive maintenance of all the electrical systems</li>
                      <li><i class="bi bi-dot"></i>Comprehensive maintenance of all mechanical systems</li>
                      <li><i class="bi bi-dot"></i>Comprehensive maintenance of all ITS, ELV, and other technology systems and infrastructure</li>
                      <li><i class="bi bi-dot"></i>Routine inspection of all tunnel structural components</li>
                      <li><i class="bi bi-dot"></i>Handling all emergencies and special works for tunnels, including corrective maintenance</li>
                      <li><i class="bi bi-dot"></i>Providing temporary traffic diversion and maintaining the traffic flow as per local requirements and regulations</li>
                      <li><i class="bi bi-dot"></i>Provision of all necessary auxiliary elements and coordination with utility authorities, police, civil defense, ambulances, and public services, as required</li>
                      <li><i class="bi bi-dot"></i>All necessary documentation, project management, and KPI-based reporting</li>
                    </ul><br/>
                    <p class="justify-align-center">
                      As a specialist tunnel O&M services provider, GBDG has been successfully delivering its obligations and services to the utmost satisfaction of its clients.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="project-area pb-20" id="r6-om">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 vertical-center">
                  <div class="project-img">
                    <img src="assets/images/projects/r6.jpeg" alt="Project" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="title">
                    <span class="project-type">Operations and Maintenance</span><br/>
                    <h3>Al Rayyan Tunnel</h3>
                  </div>
                  <div class="content">
                    GBDG was awarded the contract for the provision of operations and maintenance services for the Al Rayyan Tunnel as part of Ashghal's R6 Project, covering the following scope areas:
                    <ul class="justify-align-center">
                      <li><i class="bi bi-dot"></i>Managing 24/7 Monitoring and Operations of the Tunnel Main  Control Room</li>
                      <li><i class="bi bi-dot"></i>Comprehensive maintenance of all the electrical systems</li>
                      <li><i class="bi bi-dot"></i>Comprehensive maintenance of all mechanical systems</li>
                      <li><i class="bi bi-dot"></i>Comprehensive maintenance of all ITS, ELV, and other technology systems and infrastructure</li>
                      <li><i class="bi bi-dot"></i>Routine inspection of all tunnel structural components</li>
                      <li><i class="bi bi-dot"></i>Handling all emergencies and special works for tunnels, including corrective maintenance</li>
                      <li><i class="bi bi-dot"></i>Providing temporary traffic diversion and maintaining the traffic flow as per local requirements and regulations</li>
                      <li><i class="bi bi-dot"></i>Provision of all necessary auxiliary elements and coordination with utility authorities, police, civil defense, ambulances, and public services, as required</li>
                      <li><i class="bi bi-dot"></i>All necessary documentation, project management, and KPI-based reporting</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="project-area pb-20" id="raa-om">
            <div class="container">
              <div class="row flex-lg-row-reverse">
                <div class="col-lg-6 vertical-center">
                  <div class="project-img">
                    <img src="assets/images/projects/ras.jpg" alt="Project" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="title">
                    <span class="project-type">Operations and Maintenance</span><br/>
                    <h3>Ras Abu Abboud Tunnel</h3>
                  </div>
                  <div class="content">
                    GBDG was awarded the contract for the provision of operations and maintenance services for the Ras Abu Abboud Tunnel in Sharq Crossing in Doha, covering the following scope areas:
                    <ul class="justify-align-center">
                      <li><i class="bi bi-dot"></i>Managing 24/7 Monitoring and Operations of the Tunnel Main  Control Room</li>
                      <li><i class="bi bi-dot"></i>Comprehensive maintenance of all the electrical systems</li>
                      <li><i class="bi bi-dot"></i>Comprehensive maintenance of all mechanical systems</li>
                      <li><i class="bi bi-dot"></i>Comprehensive maintenance of all ITS, ELV, and other technology systems and infrastructure</li>
                      <li><i class="bi bi-dot"></i>Routine inspection of all tunnel structural components</li>
                      <li><i class="bi bi-dot"></i>Handling all emergencies and special works for tunnels, including corrective maintenance</li>
                      <li><i class="bi bi-dot"></i>Providing temporary traffic diversion and maintaining the traffic flow as per local requirements and regulations</li>
                      <li><i class="bi bi-dot"></i>Provision of all necessary auxiliary elements and coordination with utility authorities, police, civil defense, ambulances, and public services, as required</li>
                      <li><i class="bi bi-dot"></i>All necessary documentation, project management, and KPI-based reporting</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="project-area pb-20" id="ebridge-om">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 vertical-center">
                  <div class="project-img">
                    <img src="assets/images/projects/ering-bridge.jpeg" alt="Project" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="title">
                    <span class="project-type">Operations and Maintenance</span><br/>
                    <h3>E Ring Pedestrian Bridge</h3>
                  </div>
                  <div class="content">
                    GBDG was selected for the provision of comprehensive maintenance services for the pedestrian bridge located on E Ring Road, covering the following scope elements:
                    <ul class="justify-align-center">
                      <li><i class="bi bi-dot"></i>Comprehensive maintenance of all the electrical systems</li>
                      <li><i class="bi bi-dot"></i>Comprehensive maintenance of all mechanical systems</li>
                      <li><i class="bi bi-dot"></i>Comprehensive maintenance of all ITS, ELV, and other technology systems and infrastructure</li>
                      <li><i class="bi bi-dot"></i>Routine inspection of all bridge structural components</li>
                      <li><i class="bi bi-dot"></i>Providing temporary traffic diversion and maintaining the traffic flow as per local requirements and regulations</li>
                      <li><i class="bi bi-dot"></i>Provision of all necessary auxiliary elements and coordination with utility authorities, police, civil defense, ambulances, and public services, as required</li>
                      <li><i class="bi bi-dot"></i>All necessary documentation, project management, and KPI-based reporting</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="project-area pb-20" id="ibridge-om">
            <div class="container">
              <div class="row flex-lg-row-reverse">
                <div class="col-lg-6 vertical-center">
                  <div class="project-img">
                    <img src="assets/images/projects/ind-bridge.jpeg" alt="Project" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="title">
                    <span class="project-type">Operations and Maintenance</span><br/>
                    <h3>Industrial Area Pedestrian Bridge</h3>
                  </div>
                  <div class="content">
                    GBDG was also selected for the provision of maintenance services for the pedestrian bridge located in the industrial area, covering the following scope areas:
                    <ul class="justify-align-center">
                      <li><i class="bi bi-dot"></i>Comprehensive maintenance of all the electrical systems</li>
                      <li><i class="bi bi-dot"></i>Comprehensive maintenance of all mechanical systems</li>
                      <li><i class="bi bi-dot"></i>Comprehensive maintenance of all ITS, ELV, and other technology systems and infrastructure</li>
                      <li><i class="bi bi-dot"></i>Routine inspection of all bridge structural components</li>
                      <li><i class="bi bi-dot"></i>Providing temporary traffic diversion and maintaining the traffic flow as per local requirements and regulations</li>
                      <li><i class="bi bi-dot"></i>Provision of all necessary auxiliary elements and coordination with utility authorities, police, civil defense, ambulances, and public services, as required</li>
                      <li><i class="bi bi-dot"></i>All necessary documentation, project management, and KPI-based reporting</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="project-area pb-20" id="lusail-light">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 vertical-center">
                  <div class="project-img">
                    <img src="assets/images/projects/lusail-light.jpg" alt="Project" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="title">
                    <span class="project-type">Electrical and Lighting</span><br/>
                    <h3>Lusail Street Lighting</h3>
                  </div>
                  <div class="content">
                    GBDG was awarded the contract for the provision of inspection and maintenance services for the street lighting network in Lusail City. The project scope involved the following activities:
                    <ul class="justify-align-center">
                      <li><i class="bi bi-dot"></i>New street lighting installations</li>
                      <li><i class="bi bi-dot"></i>Existing street lighting inspection and maintenance </li>
                      <li><i class="bi bi-dot"></i>Cable fault/feeder pillar rectification </li>
                      <li><i class="bi bi-dot"></i>Remote location/special event lighting solutions</li>
                    </ul>
                    GBDG successfully delivered the project within the stipulated time frame as per the specific requirements and standards of the Lusail City Authority. The overall delivery approach and workmanship of the GBDG Street Lighting project team were highly appreciated by the client and the end user.
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="project-area pb-20" id="fifa-stadium-ictelv">
            <div class="container">
              <div class="row flex-lg-row-reverse">
                <div class="col-lg-6 vertical-center">
                  <div class="project-img">
                    <img src="assets/images/projects/fifa.jpg" alt="Project" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="title">
                    <span class="project-type">Operations and Maintenance</span><br/>
                    <h3>Ticketing and Turnstile Support for FIFA World Cup Qatar 2022™</h3>
                  </div>
                  <div class="content">
                    GBDG was selected for providing the specialist resources and expertise for the event support and maintenance of the ticketing and turnstile systems for four major stadiums, i.e., Lusail, Al Bayt, Khalifa, and 974 stadiums during the FIFA World Cup 2022.<br/><br/>

                    GBDG delivered the project successfully in collaboration with the other key partners, to the complete satisfaction of all stakeholders, such as FIFA and the Supreme Committee. GBDG had established full-equipped support centers with dedicated teams to manage the ticketing and turnstile systems during the complete duration of the World Cup 2022 event to ensure a smooth inflow and outflow of several thousand spectators.
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="project-area pb-20" id="fifa-stadium-o&m">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 vertical-center">
                  <div class="project-img">
                    <img src="assets/images/projects/fifa.jpg" alt="Project" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="title">
                    <span class="project-type">Operations and Maintenance</span><br/>
                    <h3>24/7 Live Operations and Maintenance Support for FIFA World Cup Qatar 2022™</h3>
                  </div>
                  <div class="content">
                    GBDG was awarded the prestigious project for providing 24/7 live operations and maintenance support to the national bus transport operator Mowasalat for the onboard devices and systems for the fleet of 3000 eco-friendly luxury buses deployed for the smooth transportation of the participating teams, media officials, VIPs, and a large variety of fans and visitors during the FIFA World Cup 2022 event.<br/><br/>

                    GBDG mobilized a large pool of specialized engineers, technicians, and other support resources across all the transport hub locations and delivered the challenging project successfully in collaboration with the other key partners, to the complete satisfaction of all stakeholders, such as Mowasalat, the Ministry of Transport, FIFA, and the Supreme Committee of Delivery and Legacy.
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div><!-- O&M -->
      </div>
    </div>
  </div>
</section>




