import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-plc-scada',
    templateUrl: './plc-scada.component.html',
    styleUrls: ['./plc-scada.component.scss']
})
export class PLCSCADAComponent implements OnInit {

    constructor(
        private titleService: Title,
        private meta: Meta,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private doc: Document
    )
    {
        this.titleService.setTitle('GBDG  - PLC & SCADA Automation');
        this.meta.addTag({ name: 'description', content: 'PLCs facilitate the exchange of critical information with a wide variety of MEP, ITS, and ELV systems, enabling efficient remote monitoring and control over PLC & SCADA Automation in Qatar' });
        this.meta.addTag({ name: 'keywords', content: ' Automation Contractor Qatar,Best System Integrator Qatar,Tunnel SCADA,Tunnel Management System,Top Tunnel Management System in Qatar,Tunnel Management System in Qatar,Tunnel Management System near me,Best PLC & SCADA Automation in Doha,PLC & SCADA Automation,PLC & SCADA Automation in Doha,PLC & SCADA Automation in Qatar,PLC & SCADA Automation near me,Top PLC & SCADA Automation in Doha,SCADA Qatar,SCADA Contractor Qatar,PLC Qatar,RTU Qatar,IoT Qatar,PLC SCADA Qatar,PLC Testing and Commissioning Qatar,' });
        this.meta.addTag({ name: 'robots', content: 'all' });
        this.meta.addTag({ name: 'resource-type', content: 'document' });
        this.meta.addTag({ name: 'page-topic', content: 'PLC & SCADA Automation' });
        this.meta.addTag({ name: 'clientbase', content: 'Global' });
        this.meta.addTag({ name: 'distribution', content: 'World Wide Web' });
        this.meta.addTag({ name: 'audience', content: 'all' });
        this.meta.addTag({ name: 'rating', content: 'general' });
        this.meta.addTag({ id: 'googlebot', name: 'googlebot', content: 'index, follow' });
        this.meta.addTag({ name: 'expires', content: 'never' });
        this.meta.addTag({ name: 'bingbot', content: ' index, follow ' });
        this.meta.addTag({ name: 'revisit-after', content: 'Daily' });
        this.meta.addTag({ name: 'author', content: 'Gulf Business Development Group' });
        this.meta.addTag({ name: 'HandheldFriendly', content: 'True' });
        this.meta.addTag({ name: 'YahooSeeker', content: 'Index,Follow' });
        this.meta.addTag({ name: 'geo.region', content: 'UAE' });
        this.meta.addTag({ name: 'State', content: 'Qatar' });
        this.meta.addTag({ name: 'City', content: 'Doha' });
        this.meta.addTag({ name: 'subject', content: 'Rated #1 Gulf Business Development Group  - PLC & SCADA Automation in Qatar' });
    }

    ngOnInit(): void {
        this.setCanonicalURL('https://gulfbdgroup.com/plc-scada/');
    }

    setCanonicalURL(url: string) {
        let link: HTMLLinkElement | null = this.doc.querySelector('link[rel="canonical"]') || null;
        if (link) {
            this.renderer.setAttribute(link, 'href', url);
        } else {
            link = this.renderer.createElement('link');
            this.renderer.setAttribute(link, 'rel', 'canonical');
            this.renderer.setAttribute(link, 'href', url);
            this.renderer.appendChild(this.doc.head, link);
        }
    }

}
