<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>Road Network Surveys for <br/>Asset Inventory and Pavement Condition Data Collection</h2>
    </div>
  </div>

  <div class="page-shape">
    <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
    <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
    <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
    <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
    <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
  </div>
</div>

<section class="services-details-area pt-70 pb-100">
  <div class="container">
    <div class="row">

      <div class="col-12">
        <div class="services-details-text">
          <div class="scrives-item-2">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <!--<div class="services-img mb-qc">
                  <img src="assets/images/service/autosurvey.jpg" title="Image">
                </div>-->
                <div class="row mt-20">
                  <div class="col-lg-8">
                    <div class="services-img mb-qc">
                      <img class="vehicle" src="assets/images/service/gbdg-vehicle-1.jpg" title="Image">
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="services-img mb-qc">
                      <img class="vehicle" src="assets/images/service/gbdg-vehicle-2.jpg" title="Image">
                    </div>
                  </div>
                </div>
                <div class="row mt-20">
                  <div class="col-lg-8">
                    <div class="services-img mb-qc">
                      <img class="vehicle" src="assets/images/service/gbdg-vehicle-3.jpg" title="Image">
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="services-img mb-qc">
                      <img class="vehicle" src="assets/images/service/gbdg-vehicle-4.jpg" title="Image">
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <p class="paragraph">The assessment of the structural and functional pavement condition and the collection of an accurate inventory of “right-of-way” road assets are fundamental to keeping the road assets safe and in good condition. For this reason, road O&M engineers have to know what the current road condition is, which requires the collection of road asset and road pavement condition data in order to enable them to make better decisions and provide a more complete picture of the performance of the road network. However, this is typically an activity that is traditionally time-consuming and requires a vast pool of trained people, besides the purchase and maintenance of expensive equipment.</p>
                <p class="paragraph">As an established O&M service provider in the road and tunnels domain, Gulf Business Development Group’s Road Network Survey division undertakes comprehensive mobile road asset data collection and pavement condition surveys using a fully machine-based, non-destructive approach with a variety of advanced LiDAR-based equipment installed in our survey vehicles.</p>
              </div>
              <!--<div class="row mt-20">
                <div class="col-lg-4">
                  <div class="services-img mb-qc">
                    <img class="vehicle" src="assets/images/service/gbdg-vehicle-1.jpg" title="Image">
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="services-img mb-qc">
                    <img class="vehicle" src="assets/images/service/gbdg-vehicle-2.jpg" title="Image">
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="services-img mb-qc">
                    <img class="vehicle" src="assets/images/service/gbdg-vehicle-3.jpg" title="Image">
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="services-img mb-qc">
                    <img class="vehicle" src="assets/images/service/gbdg-vehicle-4.jpg" title="Image">
                  </div>
                </div>
              </div>-->
              <div class="col-lg-12 mt-20">
                <div class="row align-content-center">
                  <div class="col-lg-6">
                    <div class="services-img mb-qc">
                      <img src="assets/images/service/vehicle.jpg" title="Image">
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <p class="paragraph">The GBDG's Network Survey Vehicles "Gulf ROMDAS" are equipped with various state-of-the-art technologies for the automatic collection of road inventory and pavement condition-related data required for road asset management, pavement maintenance management systems, and road safety audit-related studies at highway speeds, such as:</p>
                    <ul class="list">
                      <li><i class="bx bx-right-arrow"></i>&nbsp;Longitudinal profiling (International Roughness Index) </li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;Transverse profiling (Rut Depth) </li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;Pavement Texture in terms of Mean Profile Depth </li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;Road Geometry Data (cross slope, gradient, curvature)  </li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;GPS coordinates (X, Y, Z) viz. longitude, latitude & altitude  </li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;Video imaging and mapping for Roadside furniture / Road Assets  </li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;Video imaging for Pavement Surface Distresses  </li>
                    </ul>
                    as well as of providing data such as Skid Resistance Results and underground mapping with metallic and non-metallic utilities such as pipes, ducts, cables, etc. at lower pace speeds.
                  </div>
                </div>
              </div>
              <div class="col-lg-12 mt-20">
                <div class="row align-content-center">
                  <div class="col-lg-6">
                    <div class="services-img mb-qc">
                      <img src="assets/images/service/modules.jpg" title="Image">
                    </div>
                  </div>
                  <div class="col-lg-6" style="padding-top: 30px;">
                    <strong>Some of the technologies equipped in GBDG Survey Vehicles are:</strong>
                    <ul class="list pt-20">
                      <li><i class="bx bx-right-arrow"></i>&nbsp;High Resolution 360 Digital Cameras with Mobile Mapping Software  </li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;Inertial Laser Surface Profilometers (ILSP)  </li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;Longitudinal Laser Profilometer – Roughness (IRI)  </li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;Transverse Profile Logger for Rutting  </li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;INO Laser Crack Measurement System v2 (LCMS2)  </li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;Distance Measurement Instrument (DMI)   </li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;Inertial Navigation System (INS) and Global Positioning System (GPS) </li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;Ground Penetrating Radar (GPR) with HDR Technology    </li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;Grip Tester for Pavement Skid Resistance   </li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;Fast Falling Weight Deflectometer (FWD)   </li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;Real time Data Acquisition Software   </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center pt-20">
              <div class="col-lg-3">
                <div class="services-img mb-qc">
                  <img src="assets/images/service/uav1.jpg" title="Image">
                </div>
                <!--<div class="services-img mb-qc">
                  <img src="assets/images/service/uav2.jpg" title="Image">
                </div>
                <div class="services-img mb-qc">
                  <img src="assets/images/service/uav4.jpg" title="Image">
                </div>-->
                <div class="services-img mb-qc">
                  <img src="assets/images/service/uav5.jpg" title="Image">
                </div>
              </div>
              <div class="col-lg-9 pt-20">
                <p class="paragraph">GBDG also recognizes that certain asset attributes necessitate a blend of manual expertise and advanced technologies for a holistic and accurate assessment. Acknowledging this, our approach seamlessly integrates the experience of highly skilled manual survey engineers with the latest survey technologies, ensuring comprehensive and unparalleled coverage of road assets.</p>
                <p class="paragraph">While manual surveying remains indispensable for capturing nuanced details, GBDG empowers its surveyors with the latest advancements in technology. Our engineers leverage cutting-edge tools, including unmanned vehicles (UAVs or drones), handheld cameras, lasers, and back-office AI tools, to the maximum extent feasible. </p>
                <p class="paragraph">The integration of these advanced technologies enhances the speed and accuracy of data collection, allowing our teams to cover expansive areas and capture details that might be challenging for manual surveys alone.</p>
                <p class="paragraph">Further, in this pursuit of excellence in road asset management, GBDG's commitment extends beyond the initial data capture phase. Recognizing the significance of timely and insightful reporting, we have established a dedicated team of post-processing experts. These specialists seamlessly integrate data captured manually or automatically, working meticulously to produce comprehensive reports and final data deliverables in multiple industry-recognized formats. Leveraging their expertise, GBDG ensures that our clients receive meaningful end deliverables and actionable insights in the shortest time frame, enabling prompt decision-making and efficient future planning.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pt-70 d-flex justify-content-center">
        <a class="box-btn" routerLink="/contact" target="_blank">Contact Us</a>
      </div>
    </div>
  </div>
</section>
