import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-electrical-lighting',
    templateUrl: './electrical-lighting.component.html',
    styleUrls: ['./electrical-lighting.component.scss']
})
export class ElectricalLightingComponent implements OnInit {

    constructor(
        private titleService: Title,
        private meta: Meta,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private doc: Document
    )
    {
        this.titleService.setTitle('GBDG - Electrical and Lighting ');
        this.meta.addTag({ name: 'description', content: 'Gulf Business Development Group (GBDG) is a licensed electrical contractor by Qatar General Electricity and Water Corporation (Kahramaa) and also approved by the Public Works Authority for standard and smart street Electrical Lighting Supply in Qatar' });
        this.meta.addTag({ name: 'keywords', content: '  Electrical Contractor Qatar,Electrical Maintenance Qatar,Kahramaa Licensed Contractor,Street Lighting Qatar,Electrical Lighting Supply,Electrical Lighting Supply in Doha,Electrical Lighting Supply in Qatar,Electrical Lighting Supply near me,PWA Electrical Contractor Qatar,Lighting Solutions Qatar,Smart Lighting Qatar,PWA Lighting Contractor Qatar,Landscape Lighting Qatar,Building Lighting Qatar,' });
        this.meta.addTag({ name: 'robots', content: 'all' });
        this.meta.addTag({ name: 'resource-type', content: 'document' });
        this.meta.addTag({ name: 'page-topic', content: 'Electrical Lighting Services' });
        this.meta.addTag({ name: 'clientbase', content: 'Global' });
        this.meta.addTag({ name: 'distribution', content: 'World Wide Web' });
        this.meta.addTag({ name: 'audience', content: 'all' });
        this.meta.addTag({ name: 'rating', content: 'general' });
        this.meta.addTag({ id: 'googlebot', name: 'googlebot', content: 'index, follow' });
        this.meta.addTag({ name: 'expires', content: 'never' });
        this.meta.addTag({ name: 'bingbot', content: ' index, follow ' });
        this.meta.addTag({ name: 'revisit-after', content: 'Daily' });
        this.meta.addTag({ name: 'author', content: 'Gulf Business Development Group' });
        this.meta.addTag({ name: 'HandheldFriendly', content: 'True' });
        this.meta.addTag({ name: 'YahooSeeker', content: 'Index,Follow' });
        this.meta.addTag({ name: 'geo.region', content: 'UAE' });
        this.meta.addTag({ name: 'State', content: 'Qatar' });
        this.meta.addTag({ name: 'City', content: 'Doha' });
        this.meta.addTag({ name: 'subject', content: 'Rated #1 Gulf Business Development Group  - Electrical Lighting Services in Qatar' });

    }

    ngOnInit(): void {
        this.setCanonicalURL('https://gulfbdgroup.com/electrical-lighting/');
    }

    setCanonicalURL(url: string) {
        let link: HTMLLinkElement | null = this.doc.querySelector('link[rel="canonical"]') || null;
        if (link) {
            this.renderer.setAttribute(link, 'href', url);
        } else {
            link = this.renderer.createElement('link');
            this.renderer.setAttribute(link, 'rel', 'canonical');
            this.renderer.setAttribute(link, 'href', url);
            this.renderer.appendChild(this.doc.head, link);
        }
    }

}
