<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Contact Us</h2>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="contact-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>

                    <div class="content">
                        <h3>Email</h3>
                        <p><a class="contact-content" href="mailto:info@gulfbdgroup.com>">info@gulfbdgroup.com</a></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-location-plus'></i>
                    </div>

                    <div class="content">
                        <h3>Address</h3>
                        <p class="contact-content"><span>Building No.247, 1st Floor,<br>D Ring Road, Fereej Al Ali,<br>P.O. Box 15341, Doha, Qatar
</span></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>

                    <div class="content">
                        <h3>Phone</h3>
                        <p><a class="contact-content" href="tel:+97444663465>">+974 4466 3465</a></p>
                    </div>
                </div>
            </div>

          <div class="col-lg-3 col-sm-6">
            <div class="single-contact">
              <div class="icon">
                <i class='bx bx-printer'></i>
              </div>

              <div class="content">
                <h3>Fax</h3>
                <p><a class="contact-content" href="tel:+97444663462>">+974 4466 3462</a></p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="google-maps">
                    <iframe frameborder="0" style="width:100%;height:100%;border:0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.4927011196464!2d51.518733673969926!3d25.2540061294001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45cffe0242e50f%3A0x49351da532d4eabc!2sGulf%20Business%20Development%20Group!5e0!3m2!1sen!2sqa!4v1681293274182!5m2!1sen!2sqa" allowfullscreen=""></iframe>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-contact-area home-2-contact pb-100">
    <div class="container">
        <div class="section-title">
            <h2>Get in Touch with Us!</h2>
            <p>Have a question or feedback? We're here to help! Use the contact form below, connect with us on social media, or drop by our office. We value your input and look forward to hearing from you soon.</p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="content">
                    <div class="row" *ngIf="isLoading">
                      <gbdg-alert *ngIf="isLoading" [loading]="isLoading" [expires]="!isLoading" message="Sending Message " class="loading"></gbdg-alert>
                    </div>
                    <div class="row" *ngIf="submitted">
                      <gbdg-alert *ngIf="submitted" [loading]="!submitted" [expires]="submitted" message="Thank you for contacting us, we will get back to you as soon as possible." class="ok-response"></gbdg-alert>
                    </div>
                    <div class="row" *ngIf="error">
                      <gbdg-alert *ngIf="error" [loading]="!error" [expires]="error"  message="Apologies, an unexpected error occurred during the sending your message. Please try again or contact us directly via email or phone." class="error-response"></gbdg-alert>
                    </div>
                    <div class="row" *ngIf="errorContactEmail">
                      <gbdg-alert *ngIf="errorContactEmail" [loading]="!errorContactEmail" [expires]="errorContactEmail" message="Apologies, we are unable to reach you via the provided email. Please double-check your email details or your email server and try again. If the problem persists, please contact us directly via email or phone." class="error-response"></gbdg-alert>
                    </div>
                  <ngb-toast-container></ngb-toast-container>
                  <div class="row">
                        <form name="contactForm" role="form" [formGroup]="contactForm" (ngSubmit)="submit()">
                            <div class="row">
                                <div class="col-lg-12 col-sm-12 mb-20">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" formControlName="name" class="form-control" required placeholder="Your Name" />
                                         <div *ngIf="contactForm.get('name')!.invalid && (contactForm.get('name')!.dirty || contactForm.get('name')!.touched)">
                                            <small class="form-text text-danger" *ngIf="contactForm.hasError('required','name')">This field is required.</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-sm-12 mb-20">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" formControlName="email" data-cy="email" class="form-control" required placeholder="Your Email" />
                                         <div *ngIf="contactForm.get('email')!.invalid && (contactForm.get('email')!.dirty || contactForm.get('email')!.touched)">
                                              <small class="form-text text-danger" *ngIf="contactForm.hasError('required','email')">This field is required.</small>
                                              <small class="form-text text-danger" *ngIf="contactForm.hasError('maxlength','email')">This field cannot be longer than 100 characters.</small>
                                              <small class="form-text text-danger" *ngIf="contactForm.hasError('minlength','email')">This field is required to be at least 5 characters.</small>
                                              <small class="form-text text-danger" *ngIf="contactForm.hasError('email','email')">Your email is invalid.</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-sm-12 mb-20">
                                    <div class="form-group">
                                        <input type="text" name="phone" id="phone" formControlName="phone" data-cy="phone" required class="form-control" placeholder="Your Phone" />
                                        <div *ngIf="contactForm.get('phone')!.invalid && (contactForm.get('phone')!.dirty || contactForm.get('phone')!.touched)">
                                          <small class="form-text text-danger" *ngIf="contactForm.hasError('required','phone')">This field is required.</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-sm-12 mb-20">
                                    <div class="form-group">
                                        <input type="text" name="subject" id="subject" formControlName="subject" data-cy="subject" class="form-control" required placeholder="Subject" />
                                        <div *ngIf="contactForm.get('subject')!.invalid && (contactForm.get('subject')!.dirty || contactForm.get('subject')!.touched)">
                                          <small class="form-text text-danger" *ngIf="contactForm.hasError('required','subject')">This field is required.</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 mb-20">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" formControlName="message" data-cy="message" cols="30" rows="5" required placeholder="Your Message"></textarea>
                                        <div *ngIf="contactForm.get('message')!.invalid && (contactForm.get('message')!.dirty || contactForm.get('message')!.touched)">
                                          <small class="form-text text-danger" *ngIf="contactForm.hasError('required','message')">This field is required.</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 mb-2 d-flex justify-content-center">
                                  <re-captcha (resolved)="checkCaptcha($event)"  name="recaptchaReactive" formControlName="token" siteKey="6LckbYkpAAAAAB9pXmUNHrOJLWB3_K7GLd-qm50p"></re-captcha>
                                </div>
                                <div class="col-lg-12 col-md-12 d-flex justify-content-center pt-20">
                                    <button type="button" class="btn-secondary" (click)="resetForm()">Reset</button>
                                    <button type="submit" class="default-btn page-btn box-btn" [disabled]="contactForm.invalid || isLoading" style="{{ contactForm.invalid ? 'cursor:not-allowed;' : 'cursor:pointer' }}">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="contact-img contact-img-2">
                    <img src="assets/images/contact-img2.jpg" alt="contact" />
                </div>
            </div>
        </div>
    </div>
</section>
