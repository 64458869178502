import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-asset-data-collection',
    templateUrl: './asset-data-collection.component.html',
    styleUrls: ['./asset-data-collection.component.scss']
})
export class AssetDataCollectionComponent implements OnInit {

    constructor(
        private titleService: Title,
        private meta: Meta,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private doc: Document
    )
    {
        this.titleService.setTitle('GBDG  - Road Network Surveys for Asset Inventory and Pavement Condition Data Collection');
        this.meta.addTag({ name: 'description', content: 'The assessment of the structural and functional pavement condition and the collection of an accurate inventory about Best Road Network Surveys in Qatar' });
        this.meta.addTag({ name: 'keywords', content: ' Asset Inventory Survey Qatar,GPR Survey Qatar,LCMS Qatar,Pavement Condition Surveys,Pavement Surveys,Road Condition Surveys,Road Surveys,ROMDAS,Best Road Network Surveys in Doha,Road Network Surveys,Road Network Surveys in Qatar,Road Network Surveys near me,Top Road Network Surveys in Qatar,Road Network Surveys Qatar,Fast Survey Qatar,Automated Survey Qatar,Laser Profilometer Qatar,Laser Foreign Object Detection Qatar,Data Acquisition Qatar,' });
        this.meta.addTag({ name: 'robots', content: 'all' });
        this.meta.addTag({ name: 'resource-type', content: 'document' });
        this.meta.addTag({ name: 'page-topic', content: 'Best Road Network Surveys in Qatar' });
        this.meta.addTag({ name: 'clientbase', content: 'Global' });
        this.meta.addTag({ name: 'distribution', content: 'World Wide Web' });
        this.meta.addTag({ name: 'audience', content: 'all' });
        this.meta.addTag({ name: 'rating', content: 'general' });
        this.meta.addTag({ id: 'googlebot', name: 'googlebot', content: 'index, follow' });
        this.meta.addTag({ name: 'expires', content: 'never' });
        this.meta.addTag({ name: 'bingbot', content: ' index, follow ' });
        this.meta.addTag({ name: 'revisit-after', content: 'Daily' });
        this.meta.addTag({ name: 'author', content: 'Gulf Business Development Group' });
        this.meta.addTag({ name: 'HandheldFriendly', content: 'True' });
        this.meta.addTag({ name: 'YahooSeeker', content: 'Index,Follow' });
        this.meta.addTag({ name: 'geo.region', content: 'UAE' });
        this.meta.addTag({ name: 'State', content: 'Qatar' });
        this.meta.addTag({ name: 'City', content: 'Doha' });
        this.meta.addTag({ name: 'subject', content: 'Rated #1 Gulf Business Development Group  - Best Road Network Surveys in Qatar' });
    }

    ngOnInit(): void {
        this.setCanonicalURL('https://gulfbdgroup.com/road-survey/');
    }

    setCanonicalURL(url: string) {
        let link: HTMLLinkElement | null = this.doc.querySelector('link[rel="canonical"]') || null;
        if (link) {
            this.renderer.setAttribute(link, 'href', url);
        } else {
            link = this.renderer.createElement('link');
            this.renderer.setAttribute(link, 'rel', 'canonical');
            this.renderer.setAttribute(link, 'href', url);
            this.renderer.appendChild(this.doc.head, link);
        }
    }

}
