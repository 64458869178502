<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>Security ELV Solutions</h2>
      <span>Distributed by GBDG</span>
    </div>
  </div>

  <div class="page-shape">
    <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
    <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
    <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
    <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
    <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
  </div>
</div>

<section class="product-area ptb-100" id="cameozak" #cameozak>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="container">
          <div class="row product-img">
            <img src="assets/images/products/came-ozak1.webp" alt="Product" />
          </div>
          <div class="row product-img">
            <img src="assets/images/products/came-ozak2.webp" alt="Product" />
          </div>
          <div class="row product-img">
            <img src="assets/images/products/came-ozak3.webp" alt="Product" />
          </div>
        </div>
      </div>
      <div class="col-lg-6">
          <div class="text-wrapper">
              <div class="logo-wrapper"><img class="logo" src="assets/images/products/came-ozak.webp" alt="GBDG"></div>
              <h2><strong>Pedestrian and Vehicle Control Systems</strong></h2>
              <span><strong>Secure your premises with advanced access control systems</strong></span>
              <p>
                  Experience the pinnacle of security and access control with CAME ÖZAK Vehicle and Pedestrian Control Systems, exclusively distributed by Gulf Business Development Group (GBDG). Our comprehensive range includes cutting-edge solutions such as Road Blockers, Bollards, Speed Gates, Waist Height Turnstiles, Full Height Turnstiles, Glass Line, Glass Full Height Series, High Security Series, Half Height Turnstiles, Turnstiles for Reduced Mobility, Exit Gates, and Free Passage Turnstiles.
                  <br>
                  <br>CAME ÖZAK's Vehicle Control Systems provide robust physical barriers for vehicular access control, safeguarding critical infrastructure and high-security areas. Road Blockers and Bollards effectively manage traffic and provide anti-ramming protection, ensuring uncompromising security.
                  <br>
                  <br>For pedestrian access control, our Pedestrian Control Systems offer a range of options to suit various environments. Speed Gates enable fast and convenient access, while Waist Height Turnstiles provide controlled entry for pedestrians. Full Height Turnstiles offer enhanced security, and the Glass Line and Glass Full Height Series boast sleek aesthetics combined with functionality. High Security Series Turnstiles ensure maximum security, while Half Height Turnstiles and Turnstiles for Reduced Mobility cater to specific requirements. Exit Gates and Free Passage Turnstiles complete the comprehensive range.
                  <br>
                  <br>GBDG's distribution ensures that you receive top-quality products, seamless installation, and reliable after-sales support. Trust CAME ÖZAK and GBDG to deliver world-class access control solutions that prioritize security, efficiency, and aesthetics for your premises.<br>
              </p>
              <div class="product-links d-flex justify-content-center">
                <a class="box-btn" href="https://ozak-t.com/Products/Pedestrian-Control-Series" target="_blank">Pedestrian Control Series</a>
                <a class="box-btn" href="https://ozak-t.com/Products/Vehicle-Control-Series" target="_blank">Vehicle Control Series</a>
              </div>
          </div>
      </div>
    </div>
  </div>
</section>
