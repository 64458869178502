<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>Passenger Information Systems</h2>
    </div>
  </div>

  <div class="page-shape">
    <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
    <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
    <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
    <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
    <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
  </div>
</div>

<section class="product-area pt-100 pb-20" id="papercast" #papercast>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="product-img">
          <img src="assets/images/products/papercast1.webp" alt="Product" />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="text-wrapper">
          <div class="logo-wrapper"><img class="logo" src="assets/images/products/papercast.webp" alt="GBDG"></div>
          <h2><strong>E-Ink Display Solutions</strong></h2>
          <span><strong>The world's leading e-paper passenger information displays</strong></span>
          <p>
            Experience the power of the Papercast<sup>&#174;</sup> solutions offered by GBDG. Our strategic partnership with Papercast<sup>&#174;</sup> brings you cutting-edge digital passenger information systems that revolutionize public transportation communication. With Papercast's e-paper displays, real-time updates, and dynamic content, you can enhance passenger experiences, increase operational efficiency, and reduce costs.<br/><br/>

            In the heart of this transformative collaboration lies a commitment to sustainability. Papercast's e-paper displays not only provide unparalleled visibility but also contribute to environmental conservation. The low power consumption of these displays aligns seamlessly with GBDG's dedication to eco-friendly solutions.<br/><br/>

            By integrating Papercast<sup>&#174;</sup> into your transportation infrastructure, you not only stay at the forefront of technology but also champion a greener tomorrow. This synergy of innovation and sustainability sets our partnership apart, making GBDG and Papercast<sup>&#174;</sup> the ideal choice for those seeking to elevate their passenger communication while actively contributing to a more environmentally conscious future.<br/><br/>

            Trust GBDG's expertise and commitment to quality as we bring you Papercast's world-class digital signage solutions for the transportation industry.
          </p>
          <div class="product-links d-flex justify-content-center">
            <a class="box-btn" href="https://www.papercast.com/" target="_blank">Visit Papercast<sup>&#174;</sup> Website</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="product-area pb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="technology-area mt-70">
        <div class="choose-img d-flex justify-content-center">
          <img src="assets/images/products/papercast2.webp" alt="GBDG" />

          <div class="technology-video">
            <button type="button" class="video-btn p-0 border-0" (click)="openPopup1()">
              <i class='bx bx-play' ></i>
            </button>
          </div>
          <div class="video-popup" *ngIf="isOpen1">
            <div class="popup-inner">
              <iframe src="https://player.vimeo.com/video/567393384?h=cedbcbf165" title="Papercast" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <button (click)="closePopup1()" type="button" class="close-btn">
                <i class='bx bx-x'></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="product-area pb-100">
  <div class="container">
    <div class="row align-items-center flex-lg-row-reverse">
      <div class="col-lg-6">
        <div class="technology-area mt-70">
          <div class="choose-img">
            <img src="assets/images/products/papercast.jpg" alt="GBDG" />

            <div class="technology-video">
              <button type="button" class="video-btn p-0 border-0" (click)="openPopup2()">
                <i class='bx bx-play' ></i>
              </button>
            </div>
            <div class="video-popup" *ngIf="isOpen2">
              <div class="popup-inner">
                <iframe src="https://www.youtube.com/embed/1-zIMybVHWU" title="Papercast" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <button (click)="closePopup2()" type="button" class="close-btn">
                  <i class='bx bx-x'></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="text-wrapper">
          <h2><strong>Outdoor and Indoor E-Paper Displays</strong></h2>
          <p>
            Papercast<sup>&#174;</sup> outdoor e-paper displays feature E Ink<sup>&#174;</sup> e-paper panels and are supplied in a ruggedized IP67 enclosure to provide strong environmental and impact resistance. Toughened glazing is made of high-quality laminated glass to provide the best optical properties and physical protection.<br/><br/>

            Papercast<sup>&#174;</sup> ultra-slimline and lightweight indoor e-paper displays, featuring E-Ink<sup>&#174;</sup> e-paper panels, are designed to be installed within the customer’s own enclosures or structures.<br/><br/>

            With wireless, battery, and solar capabilities as standard, all models come with optional illumination, interactive functions, and text-to-speech modules.<br/><br/>

            The resounding success of GBDG's implementation of Papercast<sup>&#174;</sup> displays in the <a class="project-link" routerLink="/key-projects" fragment="busstop-transportation">new bus stops for the FIFA World Cup 2022™</a> underscores the seamless integration of cutting-edge technology and robust engineering.
          </p>
        </div>
        <div class="product-links d-flex justify-content-center">
          <a class="box-btn" href="https://www.papercast.com/e-paper-digital-signage/" target="_blank">Read more on Papercast<sup>&#174;</sup> Website</a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="product-area pb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="product-img">
          <img src="assets/images/products/papercast3.webp" alt="Product" />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="text-wrapper">
          <h2><strong>Papercast<sup>&#174;</sup> Content Management System</strong></h2>
          <p>
            The Papercast<sup>&#174;</sup> content management system allows you to effortlessly share a wide range of visually striking graphical information with the public.<br/><br/>

            Papercast<sup>&#174;</sup> user-friendly cloud-based platform can be accessed from anywhere and is packed with features to enable you to manage every aspect of your Papercast<sup>&#174;</sup> e-paper displays.<br/><br/>

            Simple API integration allows you to integrate your own content sources. You can also access our widget library and develop your own content apps, such as local news, weather, events, and tourist information.<br/><br/>
          </p>
        </div>
        <div class="product-links d-flex justify-content-center">
          <a class="box-btn" href="https://www.papercast.com/product/6-things-you-need-to-know-about-the-papercast-content-management-system/" target="_blank">Read more on Papercast<sup>&#174;</sup> Website</a>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="product-area">
  <div class="overlay">
    <div class="container overlay-content">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-8">
          <div class="story-wrapper">
            <h2>GBDG's Success with Papercast<sup>&#174;</sup> E-Paper Displays in Qatar</h2>
            <p>
              Gulf Business Development Group is the authorized reseller of Papercast<sup>&#174;</sup> solutions in Qatar, offering state-of-the-art digital signage for smart transportation. With a strong commitment to quality and innovation, GBDG successfully installed a large number of Papercast<sup>&#174;</sup> E-Paper displays in the new bus stops across Qatar, revolutionizing the passenger experience during the FIFA World Cup Qatar 2022.<br/><br/>

              By leveraging Papercast<sup>&#174;</sup>'s advanced technology, GBDG provided Qatar with a cutting-edge passenger information platform that delivers real-time updates, wayfinding information, and engaging content. The E-Paper displays offer exceptional visibility in all lighting conditions, ensuring clear and readable information for passengers.<br/><br/>

              GBDG's expertise in transportation infrastructure and their partnership with Papercast<sup>&#174;</sup> enabled a seamless installation process, ensuring that the new bus stops were equipped with reliable and efficient digital signage solutions. The Papercast<sup>&#174;</sup> displays not only enhance passenger experiences but also contribute to improved operational efficiency and reduced costs for transport authorities.<br/><br/>

              With GBDG's existing service delivery network and technical support, Qatar's transportation system now benefits from the latest advancements in digital signage technology. The successful installation of Papercast<sup>&#174;</sup> E-Paper displays showcases GBDG's commitment to delivering advanced value-added solutions and transforming Qatar's public transportation infrastructure.<br/><br/>

              As the reseller of Papercast<sup>&#174;</sup> solutions, GBDG continues to provide ongoing support, maintenance, and future upgrades, ensuring that Qatar's bus stops remain at the forefront of innovation in smart transportation. With Gulf Business Development Group and Papercast<sup>&#174;</sup>, Qatar's passengers enjoy a modern and user-friendly travel experience, making public transportation more efficient, informative, and enjoyable.
            </p>
          </div>
          <div class="product-links d-flex justify-content-center">
            <a class="box-btn" href="https://www.papercast.com/customer/papercast-e-paper-displays-selected-for-fifa-world-cup-2022/" target="_blank">Read more on Papercast<sup>&#174;</sup> Website</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
