<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>Electrical & Lighting</h2>
    </div>
  </div>

  <div class="page-shape">
    <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
    <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
    <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
    <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
    <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
  </div>
</div>

<section class="services-details-area pt-70 pb-100">
  <div class="container">
    <div class="row">

      <div class="col-12">
        <div class="services-details-text">
          <div class="scrives-item-2">
            <div class="row align-items-center">

              <div class="col-lg-3">
                <div class="services-img mb-qc">
                  <img class="image2" src="assets/images/service/electrical.jpg" title="Image">
                </div>
              </div>

              <div class="col-lg-9">
                <p class="paragraph">Gulf Business Development Group (GBDG) is a licensed electrical contractor by Qatar General Electricity and Water Corporation (Kahramaa) and also approved by the Public Works Authority (Ashghal) for standard and smart street lighting systems in the State of Qatar.</p>
                <p class="paragraph">Our Electrical and Lighting Division undertakes the engineering, design, supply, installation, commissioning, and maintenance of a complete range of electrical and lighting systems and services for roads, tunnels, airports, ports, power stations, oil and gas, and other commercial and residential building/infrastructure sites.</p>
                <p class="paragraph">GBDG’s proficiency in the execution of electrical works encompasses a wide spectrum of services tailored to meet the unique requirements of diverse project requirements. We collaborate closely with our clients to understand and establish their needs and then professionally execute the electrical installations, reflecting top-grade workmanship in terms of quality and safety.</p>
                <p class="paragraph">Our certified team of electrical engineers, supervisors, and technicians carries extensive domain experience across all key activities common to all electrical installation projects, e.g., scheme opening, preparation of shop drawings, cable laying, cable jointing, cable fault detection and repair, sub-station works, testing and commissioning, GIS data updating, and scheme closure, including the demolition of existing electrical facilities and the handing over of dismantled equipment to the concerned authorities.</p>
              </div>



              <div class="col-lg-12 pt-20">
                <p class="paragraph">Under the lighting domain, GBDG offers a comprehensive range of energy-efficient lighting solutions for various sectors, e.g., street lighting, outdoor lighting for commercial and industrial facilities, and landscape lighting for public areas. Our experts assess the specific requirements of each project to develop a lighting design that ensures proper illumination, improved visibility, and energy savings in order to maximize efficiency, extend its life, and minimize maintenance needs.</p>
                <p class="paragraph">Our lighting solutions comply with all the relevant industry standards and local regulations, contributing to safer and better-lit public spaces. Additionally, our Smart Lighting Remote Management and Control platform provides intelligent telecontrol for outdoor lighting systems and enables a large variety of other Smart City services through intelligent resource management and significant savings in energy and maintenance costs.</p>
              </div>
              <div class="col-lg-4 d-flex justify-content-center">
                <div class="services-img mb-qc image-div">
                  <img class="image" src="assets/images/projects/lusail-light.jpg" title="Image">
                </div>
              </div>
              <div class="col-lg-4 d-flex justify-content-center">
                <div class="services-img mb-qc image-div">
                  <img class="image" src="assets/images/service/lighting1.jpg" title="Image">
                </div>
              </div>
              <div class="col-lg-4 d-flex justify-content-center">
                <div class="services-img mb-qc image-div">
                  <img class="image" src="assets/images/service/lighting2.jpg" title="Image">
                </div>
              </div>
              <h3 class="pt-20">Offering Portfolio:</h3>
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="subsection-title">
                      <span>Electrical Works (MV/LV/ELV)</span>
                    </div>
                    <ul class="list">
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Cable Laying</strong></li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Cable Glanding and Termination</strong></li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Switchgear Installation</strong></li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Transformer Installation </strong></li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Substation Works</strong></li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Central Battery & UPS Systems</strong></li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Earthing and Lightning Protection</strong></li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Scheme review and approval</strong></li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Preparation of shop drawings</strong></li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Building installations</strong></li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Testing, commissioning, and handover</strong></li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Systems Integration and Software Development</strong></li>
                    </ul>
                  </div>
                  <div class="col-lg-5">
                    <div class="subsection-title">
                      <span>Lighting Solutions</span>
                    </div>
                    <ul class="list">
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>New Street Lighting Installations</strong></li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Upgrade of Existing Street Lighting Installations</strong></li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Preventive/Corrective Maintenance </strong></li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Feeder Pillars Installation/Rectification </strong></li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Remote Location/Special Event Lighting Solutions</strong></li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Smart Lighting System (Remote Monitoring & Control)</strong></li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Decorative and Landscape Lighting</strong></li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Cable Fault detection  </strong></li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Preparation of shop drawings </strong></li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Testing, commissioning, and handover</strong></li>
                    </ul>
                  </div>
                  <div class="col-lg-3">
                    <div class="subsection-title">
                      <span>Maintenance Services</span>
                    </div>
                    <ul class="list">
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Air Circuit Breaker Testing</strong></li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Current Transformer Testing</strong></li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Capacitor Bank Testing</strong></li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Automatic Transfer Switch Testing</strong></li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Medium Voltage Transformer Testing</strong></li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>LV DB Testing</strong></li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Restricted Earth Fault Relay Testing</strong></li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Busbar Maintenance</strong></li>
                      <li><i class="bx bx-right-arrow"></i>&nbsp;&nbsp;<strong>Meter Calibration</strong></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-70 d-flex justify-content-center">
      <a class="box-btn" routerLink="/contact" target="_blank">Contact Us</a>
    </div>
  </div>
</section>
