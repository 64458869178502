import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class NavbarComponent implements OnInit {
    logo = 'assets/images/logo-white.png';
    location: any;
    navbarClass: any;
    isOpen = false;
    screenWidth = 0;

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
        this.isOpen = !this.isOpen;
        /* let elem: HTMLElement | null = document.getElementById('navbarSupportedContent');
        if(elem && !this.isDesktopDevice()) {
          elem.style.background = 'blue';
        } */
    }

    constructor(
        private router: Router,
        location: Location
    ) {
        this.checkWidth();
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationEnd ) {
                this.location = this.router.url;
                if (this.location == '/home-three'){
                    this.navbarClass = 'navbar-area three';
                } else {
                    this.navbarClass = 'navbar-area';
                }
            }
        });
    }

    ngOnInit(): void {}

    // Navbar Sticky
    isSticky: boolean = false;
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
            this.logo = 'assets/images/logo.png';
        } else {
            this.isSticky = false;
            this.logo = 'assets/images/logo-white.png';
        }
    }

    @HostListener('window:resize', ['$event'])
    checkWidth() {
        this.screenWidth = window.innerWidth;
    }

    hasWideScreen(): boolean {
        return this.screenWidth > 992;
    }

    isMobileDevice(): boolean {
      const regexs = [/(Android)(.+)(Mobile)/i, /BlackBerry/i, /iPhone|iPod/i, /Opera Mini/i, /IEMobile/i];
      return regexs.some(b => navigator.userAgent.match(b));
    }

    isTabletDevice(): boolean {
      const regex = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/;
      return regex.test(navigator.userAgent.toLowerCase());
    }

    isDesktopDevice(): boolean {
      return !this.isMobileDevice() && !this.isTabletDevice();
    }

}
