<footer class="footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="content">
                    <div class="slogan">
                        <div class="logo">
                          <a routerLink="/"><img style="width:75%;" src="assets/images/logo-white.png" alt="logo" /></a>
                        </div>
                        <p></p>
                        <ul class="social">
                          <li><a title="LinkedIn" href="https://www.linkedin.com/company/gulf-business-development-group" target="_blank"> <i class="bi bi-linkedin"></i></a></li>
                          <li><a title="X" href="https://www.twitter.com/GBDGQatar" target="_blank"><i class="bi bi-twitter-x"></i></a></li>
                          <li><a title="Facebook" href="https://www.facebook.com/gbdg.qatar" target="_blank"><i class="bi bi-facebook"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-2 col-md-6">
                <div class="content ml-15">
                    <h3>Who we are</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/quality-and-safety">Quality and Safety</a></li>
                        <li><a routerLink="/trademarks-and-ips">Trademarks and Patents</a></li>
                        <li><a routerLink="/partners-and-suppliers">Partners and Suppliers</a></li>
                        <li><a routerLink="/project-map">GBDG Project Map</a></li>
                        <li><a routerLink="/recognitions">Recognitions</a></li>
                        <li><a routerLink="/clients">Clients</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="content ml-15">
                    <h3>Our Services</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/o-and-m">Operations and Maintenance</a></li>
                        <li><a routerLink="/its">Intelligent Transportation Systems (ITS)</a></li>
                        <li><a routerLink="/electrical-lighting">Electrical and Lighting</a></li>
                        <li><a routerLink="/ict-elv">ICT and ELV Systems</a></li>
                        <li><a routerLink="/plc-scada">PLC/SCADA Automation</a></li>
                        <li><a routerLink="/transportation">Integrated Transportation Solutions</a></li>
                        <li><a routerLink="/road-network-surveys">Road Network Surveys</a></li>
                      <li><a routerLink="/asset-management">Asset Management</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6">
                <div class="content ml-15">
                    <h3>Our Products</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/rokid" fragment="rokid">ROKID</a></li>
                        <li><a routerLink="/romdas" fragment="romdas">ROMDAS&#8482;</a></li>
                        <li><a routerLink="/papercast" fragment="papercast">PAPERCAST<sup>&#174;</sup></a></li>
                     <!-- <li><a routerLink="/security-elv" fragment="cameozak">CAME ÖZAK®</a></li>-->
                        <li><a routerLink="/software" fragment="grams">GRAMS&#8482;</a></li>
                        <li><a routerLink="/software" fragment="busistop">Busistop&#8482;</a></li>
                        <li><a routerLink="/software" fragment="footibridge">Foot iBridge&#8482;</a></li>
                        <li><a routerLink="/software" fragment="gfms">GFMS&#8482;</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6">
                <div class="content contacts">
                    <h3 class="ml-40">Contacts</h3>

                    <ul class="footer-list foot-social">
                        <li><a href="tel:+9744463465"><i class="bx bx-phone"></i> +974 4466 3465</a></li>
                        <li><a href="tel:+9744463465"><i class="bi bi-printer"></i> +974 4466 3462</a></li>
                        <li><a href="mailto:info@gulfbdgroup.com"><i class="bx bxs-envelope"></i> info@gulfbdgroup.com</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="address-area pt-70">
      <div class="container">
        <div class="row central-items">
          <div class="col-lg-7">
            <p class="address"><i class="bx bx-map"></i>&nbsp;Building No.247, 1st Floor, D Ring Road, Fereej Al Ali, P.O. Box 15341, Doha, Qatar</p>
          </div>
        </div>
      </div>
    </div>

    <div class="copy-area">
        <div class="container">
            <div class="row central-items">

                <div class="col-lg-6">
                    <p class="address">© Copyright {{year}} Gulf Business Development Group. All Rights Reserved. </p>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
    <i class='bx bx-chevrons-up'></i>
</div>
