import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gbdg-alert',
  template: `
    <div *ngIf="show" class="{{ class })">
      {{ message }}&nbsp;<i *ngIf="loading" class="bx bx-sync spinning-icon"></i>
    </div>
  `,
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  @Input() loading: boolean = false;
  @Input() expires: boolean = false;
  @Input() message: string = '';
  @Input() class: string = '';
  show: boolean = true;

  ngOnInit() {
    if(this.expires) {
      setTimeout(() => {
        this.show = false;
      }, 5000);
    }
  }
}
