<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>Licenses and Certifications</h2>
    </div>
  </div>

  <div class="page-shape">
    <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
    <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
    <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
    <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
    <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
  </div>
</div>

<!--<section class="services-details-area ptb-100">
  <div class="container">
    <div class="row">

      <div class="col-12">
        <div class="services-details-text">
          <p class="paragraph">As a testament to our unwavering commitment to compliance and operational excellence, Gulf Business Development Group proudly holds various key certifications from well-recognized international and local authorities. These certifications not only validate our continuous commitment to meeting and exceeding the standards set by these authorities in their respective domains but also establish our reliable and compliant standing within the industry.</p>
        </div>
      </div>
    </div>
  </div>
</section>-->
<section class="home-blog-area pb-50 pt-70">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="services-details-text">
          <p class="paragraph">As a testament to our unwavering commitment to compliance and operational excellence, Gulf Business Development Group proudly holds various key certifications from well-recognized international and local authorities. These certifications not only validate our continuous commitment to meeting and exceeding the standards set by these authorities in their respective domains but also establish our reliable and compliant standing within the industry.</p>
        </div>
      </div>
    </div>
    <div class="row pt-20">
      <div class="col-lg-3 col-md-6 ">
        <div class="single-blog">
          <div class="blog-img">
            <img src="assets/images/certifications/iso.jpg" alt="ISO">
          </div>

          <div class="content">
            <h3>ISO</h3>
            <p>ISO Certified</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6">
        <div class="single-blog">
          <div class="blog-img">
            <img src="assets/images/certifications/icv.jpg" alt="ICV">
          </div>

          <div class="content">
            <h3>TAWTEEN</h3>
            <p>In-Country Value Certified</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6">
        <div class="single-blog">
          <div class="blog-img">
            <img src="assets/images/certifications/comm-authority.jpg" alt="COMMUNICATIONS REGULATORY AUTHORITY">
          </div>

          <div class="content">
            <h3>CRA</h3>
            <p>Licensed ICT Contractor</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6">
        <div class="single-blog">
          <div class="blog-img">
            <img src="assets/images/certifications/kahramaa.jpg" alt="KAHRAMAA">
          </div>

          <div class="content">
            <h3>KAHRAMAA</h3>
            <p>Licensed Electrical Contractor</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6">
        <div class="single-blog">
          <div class="blog-img">
            <img src="assets/images/certifications/moi-ssd.jpg" alt="MOI-SSD">
          </div>

          <div class="content">
            <h3>MOI-SSD</h3>
            <p>Licensed Security Systems Contractor</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6">
        <div class="single-blog">
          <div class="blog-img">
            <img src="assets/images/certifications/pwa-o&m.jpg" alt="PWA">
          </div>

          <div class="content">
            <h3>PWA "Ashghal"</h3>
            <p>Licensed Tunnel O&M Contractor</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6">
        <div class="single-blog">
          <div class="blog-img">
            <img src="assets/images/certifications/pwa-its-elv-plc-scada.jpg" alt="PWA">
          </div>

          <div class="content">
            <h3>PWA "Ashghal"</h3>
            <p>Lic. ITS, ELV and SCADA Contractor</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-md-6">
        <div class="single-blog">
          <div class="blog-img">
            <img src="assets/images/certifications/pwa-street-lighting.jpg" alt="PWA">
          </div>

          <div class="content">
            <h3>PWA "Ashghal"</h3>
            <p>Licensed Street Lighting Contractor</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
