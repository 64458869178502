import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-ar-glasses',
    templateUrl: './ar-glasses.component.html',
    styleUrls: ['./ar-glasses.component.scss']
})
export class ARGlassesComponent implements OnInit {

    constructor(
        private titleService: Title,
        private meta: Meta,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private doc: Document
    )
    {
        this.titleService.setTitle('GBDG  - Augmented Reality Glasses');
        this.meta.addTag({ name: 'description', content: 'Augmented Reality glasses play a crucial role in Operations and Maintenance (O&M) for many industries, such as Roads & Building Infrastructure, Transportation, Oil & Gas, manufacturing, and many more, by providing essential real-time information and augmented visualization. They enable technicians to access digital data, instructions, and remote expert assistance hands-free, improving efficiency, accuracy, and safety.' });
        this.meta.addTag({ name: 'keywords', content: ' ROKID Qatar,Augmented Reality Qatar,Oil and Gas Glasses,Explosion Proof Glasses,ATEX Certified Glasses Qatar,IECEx Certified Glasses Qatar,' });
        this.meta.addTag({ name: 'robots', content: 'all' });
        this.meta.addTag({ name: 'resource-type', content: 'document' });
        this.meta.addTag({ name: 'page-topic', content: 'Best Road Network Surveys in Qatar' });
        this.meta.addTag({ name: 'clientbase', content: 'Global' });
        this.meta.addTag({ name: 'distribution', content: 'World Wide Web' });
        this.meta.addTag({ name: 'audience', content: 'all' });
        this.meta.addTag({ name: 'rating', content: 'general' });
        this.meta.addTag({ id: 'googlebot', name: 'googlebot', content: 'index, follow' });
        this.meta.addTag({ name: 'expires', content: 'never' });
        this.meta.addTag({ name: 'bingbot', content: ' index, follow ' });
        this.meta.addTag({ name: 'revisit-after', content: 'Daily' });
        this.meta.addTag({ name: 'author', content: 'Gulf Business Development Group' });
        this.meta.addTag({ name: 'HandheldFriendly', content: 'True' });
        this.meta.addTag({ name: 'YahooSeeker', content: 'Index,Follow' });
        this.meta.addTag({ name: 'geo.region', content: 'UAE' });
        this.meta.addTag({ name: 'State', content: 'Qatar' });
        this.meta.addTag({ name: 'City', content: 'Doha' });
        this.meta.addTag({ name: 'subject', content: 'Rated #1 Gulf Business Development Group  - Best Augmented Reality Glasses in Qatar' });
    }

    ngOnInit(): void {
        this.setCanonicalURL('https://gulfbdgroup.com/rokid/');
    }

    setCanonicalURL(url: string) {
        let link: HTMLLinkElement | null = this.doc.querySelector('link[rel="canonical"]') || null;
        if (link) {
            this.renderer.setAttribute(link, 'href', url);
        } else {
            link = this.renderer.createElement('link');
            this.renderer.setAttribute(link, 'rel', 'canonical');
            this.renderer.setAttribute(link, 'href', url);
            this.renderer.appendChild(this.doc.head, link);
        }
    }

	  isOpen1 = false;
	  isOpen2 = false;
	  isOpen3 = false;
    openPopup1(): void {
        this.isOpen1 = true;
    }
    openPopup2(): void {
        this.isOpen2 = true;
    }
    openPopup3(): void {
        this.isOpen3 = true;
    }
    closePopup1(): void {
        this.isOpen1 = false;
    }
    closePopup2(): void {
        this.isOpen2 = false;
    }
    closePopup3(): void {
        this.isOpen3 = false;
    }
}
