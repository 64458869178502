import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-o-and-m',
    templateUrl: './o-and-m.component.html',
    styleUrls: ['./o-and-m.component.scss']
})
export class OAndMComponent implements OnInit {

    constructor(
        private titleService: Title,
        private meta: Meta,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private doc: Document
    )
    {
        this.titleService.setTitle('GBDG  - Operation & Maintenance');
        this.meta.addTag({ name: 'description', content: 'At GBDG, we take pride in spearheading leading-edge solutions for a wide range of assets, offering a comprehensive suite of services that transcend traditional Operation & Maintenance Services in Qatar' });
        this.meta.addTag({ name: 'keywords', content: ' Control Room Operations,O&M Fleet Services,O&M Framework Contractor Qatar,O&M Services Qatar,Operations and Maintenance,Tunnel Framework Contractor Qatar,Tunnel Maintenance Qatar,Tunnel Operations and Maintenance,Tunnel Safety Solutions,Operation & Maintenance Services in Doha,Operation & Maintenance Services in Qatar,Operation & Maintenance Services near me,Emergency Response Qatar,Traffic Diversion Qatar,Traffic Operators Qatar,Road Operations Qatar,' });
        this.meta.addTag({ name: 'robots', content: 'all' });
        this.meta.addTag({ name: 'resource-type', content: 'document' });
        this.meta.addTag({ name: 'page-topic', content: 'Operation & Maintenance Services' });
        this.meta.addTag({ name: 'clientbase', content: 'Global' });
        this.meta.addTag({ name: 'distribution', content: 'World Wide Web' });
        this.meta.addTag({ name: 'audience', content: 'all' });
        this.meta.addTag({ name: 'rating', content: 'general' });
        this.meta.addTag({ id: 'googlebot', name: 'googlebot', content: 'index, follow' });
        this.meta.addTag({ name: 'expires', content: 'never' });
        this.meta.addTag({ name: 'bingbot', content: ' index, follow ' });
        this.meta.addTag({ name: 'revisit-after', content: 'Daily' });
        this.meta.addTag({ name: 'author', content: 'Gulf Business Development Group' });
        this.meta.addTag({ name: 'HandheldFriendly', content: 'True' });
        this.meta.addTag({ name: 'YahooSeeker', content: 'Index,Follow' });
        this.meta.addTag({ name: 'geo.region', content: 'UAE' });
        this.meta.addTag({ name: 'State', content: 'Qatar' });
        this.meta.addTag({ name: 'City', content: 'Doha' });
        this.meta.addTag({ name: 'subject', content: 'Rated #1 Gulf Business Development Group  - Operation & Maintenance Services in Qatar' });
    }

    ngOnInit(): void {
        this.setCanonicalURL('https://gulfbdgroup.com/o-and-m/');
    }

    setCanonicalURL(url: string) {
        let link: HTMLLinkElement | null = this.doc.querySelector('link[rel="canonical"]') || null;
        if (link) {
            this.renderer.setAttribute(link, 'href', url);
        } else {
            link = this.renderer.createElement('link');
            this.renderer.setAttribute(link, 'rel', 'canonical');
            this.renderer.setAttribute(link, 'href', url);
            this.renderer.appendChild(this.doc.head, link);
        }
    }

}
