<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>Integrated ICT and ELV Systems</h2>
    </div>
  </div>

  <div class="page-shape">
    <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
    <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
    <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
    <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
    <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
  </div>
</div>

<section class="services-details-area pt-70 pb-100">
  <div class="container">
    <div class="row">

      <div class="col-12">
        <div class="services-details-text">
          <div class="scrives-item-2">
            <div class="row align-items-center">

              <div class="col-lg-5">
                <div class="services-img mb-qc">
                  <img src="assets/images/service/ict-elv.jpg" title="Image">
                </div>
              </div>

              <div class="col-lg-7">
                <p class="paragraph">The domain of Information and Communications Technology (ICT) and Extra Low Voltage (ELV) solutions has ushered in a new era of technological transformation to fortify the safety and security of buildings and other mission-critical facilities. These solutions encompass an array of advanced systems integrated into a unified platform that not only enhances public safety but also redefines the landscape of building and infrastructure management.</p>
                <p class="paragraph">GBDG specializes in providing a comprehensive portfolio of ICT and ELV solutions to deliver state-of-the-art, environment-friendly, and intelligent building facilities. Over the years, GBDG has successfully executed numerous complex projects for various government and private entities and consistently earned their valued trust and appreciation.</p>
                <p class="paragraph">Our delivered projects span across a large variety of modern facilities and assets, including stadiums, datacenters, hospitals, schools, educational institutions, transportation facilities (bus stations, depots, bus stops, and buses), retail, and a large variety of other commercial, residential, and multi-purpose buildings.</p>
              </div>

              <div class="col-lg-12 pt-20">
                <h3>Offering Portfolio:</h3>
                <ul class="list">
                  <li><i class="bx bx-right-arrow"></i>&nbsp;<strong>Door/Pedestrian Access Control:</strong>&nbsp;Card Readers, Biometrics, Time & Attendance, Door Controllers, Turnstiles, Speed Gates, Mantraps, Ticketing System, People Counting, Intrusion Detection.</li>
                  <li><i class="bx bx-right-arrow"></i>&nbsp;<strong>Vehicle Access Control:</strong>&nbsp;Gate Barriers, Road Blockers, Under Vehicle Scanning System, Automatic / Manual Bollards, Long Range Card Readers, Car Park Management System.</li>
                  <li><i class="bx bx-right-arrow"></i>&nbsp;<strong>Video Surveillance Solutions:</strong>&nbsp;CCTV, ANPR, Video Analytics, Video Management Systems (VMS), NVR, and Network Storage.</li>
                  <li><i class="bx bx-right-arrow"></i>&nbsp;<strong>Integrated Security Systems:</strong>&nbsp;Physical Security Information Management (PSIM), and Environment Monitoring Systems (EMS).</li>
                  <li><i class="bx bx-right-arrow"></i>&nbsp;<strong>Door Hardware:</strong>&nbsp;Door Operators (sliding or swinging), Mechanical Locks, Electromechanical / Mortise locks, Intelligent Padlock System, Rack / Cabinet Locks.</li>
                  <li><i class="bx bx-right-arrow"></i>&nbsp;<strong>Digital Signage, Master Clock, and Nurse Calling System</strong></li>
                  <li><i class="bx bx-right-arrow"></i>&nbsp;<strong>Structured Cabling System, Containment works</strong></li>
                  <li><i class="bx bx-right-arrow"></i>&nbsp;<strong>Key Management, Master Key Systems</strong></li>
                  <li><i class="bx bx-right-arrow"></i>&nbsp;<strong>Networking, IP Telephony, indoor / rugged outdoor Switches, PoE Extenders, Routers, Firewalls, and Load Balancers</strong></li>
                  <li><i class="bx bx-right-arrow"></i>&nbsp;<strong>Public Address System, Audio Video Intercom, Emergency Telephones (SOS)</strong></li>
                  <li><i class="bx bx-right-arrow"></i>&nbsp;<strong>Audio/Video Systems</strong></li>
                  <li><i class="bx bx-right-arrow"></i>&nbsp;<strong>IT Hardware and Software Systems</strong></li>
                </ul>
              </div>
              <div class="col-lg-12 pt-20">
                <p class="paragraph">As the architectural landscape continues to evolve, GBDG remains at the forefront, pioneering integrated ICT and ELV systems by harnessing the power of seamlessly integrated technology to redefine safety, security, and efficiency. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-70 d-flex justify-content-center">
      <a class="box-btn" routerLink="/contact" target="_blank">Contact Us</a>
    </div>
  </div>
</section>

