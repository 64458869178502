import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NavbarComponent } from './components/common/navbar/navbar.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { SafePipe } from './components/common/pipes/safe.pipe';
import { AlertComponent } from './components/common/alert/alert.component';

import { AboutComponent } from './components/pages/about/about.component';
import { ARGlassesComponent } from './components/pages/ar-glasses/ar-glasses.component';
import { AssetDataCollectionComponent } from './components/pages/asset-data-collection/asset-data-collection.component';
import { AssetManagementComponent } from './components/pages/asset-management/asset-management.component';
import { ClientsComponent } from './components/pages/clients/clients.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ElectricalLightingComponent } from './components/pages/electrical-lighting/electrical-lighting.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { HomeComponent } from './components/pages/home/home.component';
import { ICTELVComponent } from './components/pages/ict-elv/ict-elv.component';
import { ITSComponent } from './components/pages/its/its.component';
import { KeyProjectsComponent } from './components/pages/key-projects/key-projects.component';
import { LicensesComponent } from './components/pages/licenses/licenses.component';
import { OAndMComponent } from './components/pages/o-and-m/o-and-m.component';
import { PartnersComponent } from './components/pages/partners/partners.component';
import { PassengerInfoComponent } from './components/pages/passenger-info/passenger-info.component';
import { PatentsComponent } from './components/pages/patents/patents.component';
import { PLCSCADAComponent } from './components/pages/plc-scada/plc-scada.component';
import { ProjectMapComponent } from './components/pages/project-map/project-map.component';
import { QualityComponent } from './components/pages/quality/quality.component';
import { RecognitionsComponent } from './components/pages/recognitions/recognitions.component';
import { RoadSurveyComponent } from './components/pages/road-survey/road-survey.component';
import { SecurityELVComponent } from './components/pages/security-elv/security-elv.component';
import { SoftwareComponent } from './components/pages/software/software.component';
import { TransportationComponent } from './components/pages/transportation/transportation.component';

import { HomeOneComponent } from './components/pages/examples/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/examples/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/examples/home-three/home-three.component';
import { SolutionsComponent } from './components/pages/examples/solutions/solutions.component';
import { SolutionsDetailsComponent } from './components/pages/examples/solutions-details/solutions-details.component';
import { CaseStudiesComponent } from './components/pages/examples/case-studies/case-studies.component';
import { CaseStudiesDetailsComponent } from './components/pages/examples/case-studies-details/case-studies-details.component';
import { BlogComponent } from './components/pages/examples/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/examples/blog-details/blog-details.component';
import { TeamComponent } from './components/pages/examples/team/team.component';
import { PricingComponent } from './components/pages/examples/pricing/pricing.component';
import { GalleryComponent } from './components/pages/examples/gallery/gallery.component';
import { TestimonialsComponent } from './components/pages/examples/testimonials/testimonials.component';
import { SignUpComponent } from './components/pages/examples/sign-up/sign-up.component';
import { SignInComponent } from './components/pages/examples/sign-in/sign-in.component';

import { FaqComponent } from './components/pages/examples/faq/faq.component';
import { TermsConditionsComponent } from './components/pages/examples/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/examples/privacy-policy/privacy-policy.component';

import { environment } from '../environments/environment';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    SafePipe,
    AlertComponent,


    AboutComponent,
    ARGlassesComponent,
    AssetDataCollectionComponent,
    AssetManagementComponent,
    ClientsComponent,
    ContactComponent,
    ElectricalLightingComponent,
    ErrorComponent,
    HomeComponent,
    ICTELVComponent,
    ITSComponent,
    KeyProjectsComponent,
    LicensesComponent,
    OAndMComponent,
    PartnersComponent,
    PassengerInfoComponent,
    PatentsComponent,
    ProjectMapComponent,
    QualityComponent,
    RecognitionsComponent,
    RoadSurveyComponent,
    SecurityELVComponent,
    SoftwareComponent,
    TransportationComponent,

    HomeOneComponent,
    HomeTwoComponent,
    HomeThreeComponent,
    SolutionsComponent,
    SolutionsDetailsComponent,
    CaseStudiesComponent,
    CaseStudiesDetailsComponent,
    BlogComponent,
    BlogDetailsComponent,
    TeamComponent,
    PricingComponent,
    GalleryComponent,
    TestimonialsComponent,
    SignUpComponent,
    SignInComponent,
    FaqComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    BrowserAnimationsModule,
    NgxScrollTopModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    RecaptchaFormsModule,
    RecaptchaModule
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
  ]
})
export class AppModule { }
