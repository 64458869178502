<div class="navbar-area" *ngIf="hasWideScreen()">
    <div class="main-nav" [ngClass]="{'sticky': isSticky}">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light" [class.active]="classApplied">
                <div class="text-end">
                    <button class="navbar-toggler" type="button" (click)="toggleClass()">
                      <i class="{{ isOpen ? 'bi bi-x' : 'bx bx-menu'}}"></i>
                    </button>
                </div>
                <a class="brand" routerLink="/">
                    <img src="{{ logo }}" alt="logo">
                </a>
                <div class="collapse navbar-collapse mean-menu" id="navbarDesktopSupportedContent" style="justify-content: center;">
                    <ul class="navbar-nav text-left">
                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">About Us</a>
                            <ul class="dropdown-menu">
                              <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Company Overview</a></li>
                              <li class="nav-item"><a routerLink="/quality-and-safety" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Quality and Safety</a></li>
                                <li class="nav-item"><a routerLink="/licenses-and-certifications" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Licenses and Certifications</a></li>
                                <li class="nav-item"><a routerLink="/trademarks-and-ips" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Trademarks and IPs</a></li>
                              <li class="nav-item"><a routerLink="/partners-and-suppliers" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Partners and Suppliers</a></li>
                            </ul>
                        </li>

                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Solutions and Services</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/o-and-m" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Operations and Maintenance</a></li>
                                <li class="nav-item"><a routerLink="/its" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Intelligent Transportation Systems (ITS)</a></li>
                                <li class="nav-item"><a routerLink="/electrical-lighting" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Electrical and Lighting</a></li>
                                <li class="nav-item"><a routerLink="/ict-elv" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">ICT and ELV Systems</a></li>
                                <li class="nav-item"><a routerLink="/plc-scada" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">PLC/SCADA Automation</a></li>
                                <li class="nav-item"><a routerLink="/transportation" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Integrated Transportation Solutions</a></li>
                                <li class="nav-item"><a routerLink="/road-network-surveys" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Road Network Surveys</a></li>
                              <li class="nav-item"><a routerLink="/asset-management" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Asset Management</a></li>
                            </ul>
                        </li>

                        <li class="nav-item dropdown">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Portfolio</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/key-projects" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Key Projects</a></li>
                                <li class="nav-item"><a routerLink="/project-map" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">GBDG Project Map</a></li>
                                <li class="nav-item"><a routerLink="/recognitions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Recognitions</a></li>
                                <li class="nav-item"><a routerLink="/clients" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Clients</a></li>
                            </ul>
                        </li>

                        <li class="nav-item dropdown">
                          <a href="javascript:void(0)" class="nav-link dropdown-toggle">Products</a>
                          <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/rokid" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Rokid AR Glasses</a></li>
                            <li class="nav-item"><a routerLink="/romdas" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">ROMDAS&#8482; Survey Systems</a></li>
                            <li class="nav-item"><a routerLink="/papercast" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Papercast<sup>&#174;</sup> E-Ink Displays</a></li>
                            <!--<li class="nav-item"><a routerLink="/security-elv" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Security ELV Solutions</a></li>-->
                            <li class="nav-item"><a routerLink="/software" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">GBDG Software Solutions</a></li>
                          </ul>
                        </li>

                        <li class="nav-item dropdown-item">
                            <a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact Us</a>
                        </li>



                      <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Examples</a>

                            <ul class="dropdown-menu"><li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                              <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>

                              <li class="nav-item"><a routerLink="/case-studies" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Studies</a></li>

                              <li class="nav-item"><a routerLink="/case-studies-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Studies Details</a></li>

                              <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Error 404</a></li>

                              <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                              <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>

                              <li class="nav-item"><a routerLink="/home-one" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home One</a></li>

                              <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Two</a></li>

                              <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Three</a></li>

                              <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                              <li class="nav-item"><a routerLink="/privacy-policy" cl ass="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>

                              <li class="nav-item"><a routerLink="/sign-in" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign In</a></li>

                              <li class="nav-item"><a routerLink="/sign-up" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>

                              <li class="nav-item"><a routerLink="/solutions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Solutions</a></li>

                              <li class="nav-item"><a routerLink="/solutions-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Solutions Details</a></li>

                              <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team</a></li>

                              <li class="nav-item"><a routerLink="/terms-condition" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a></li>

                              <li class="nav-item"><a routerLink="/testimonials" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>
                            </ul>
                        </li>-->
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</div>
<div class="navbar-area" *ngIf="!hasWideScreen()">
  <div class="mobile-nav" [ngClass]="{'sticky': isSticky}">
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-light" [class.active]="classApplied">
        <div class="header">
          <a class="navbar-brand d-none" routerLink="/">
            <img src="{{ logo }}" alt="logo">
          </a>
          <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <i class="{{ isOpen ? 'bi bi-x' : 'bx bx-menu'}}"></i>
          </button>
        </div>
        <div class="collapse navbar-collapse mean-menu" id="navbarMobileSupportedContent" style="justify-content: center;">
          <ul class="navbar-nav text-left">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">About Us</a>
              <ul class="dropdown-menu">
                <li class="nav-item" (click)="toggleClass()"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Company Overview</a></li>
                <li class="nav-item" (click)="toggleClass()"><a routerLink="/quality-and-safety" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Quality and Safety</a></li>
                <li class="nav-item" (click)="toggleClass()"><a routerLink="/licenses-and-certifications" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Licenses and Certifications<</a></li>
                <li class="nav-item" (click)="toggleClass()"><a routerLink="/trademarks-and-ips" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Trademarks and IPs</a></li>
                <li class="nav-item" (click)="toggleClass()"><a routerLink="/partners-and-suppliers" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Partners and Suppliers</a></li>
              </ul>
            </li>

            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Solutions and Services</a>
              <ul class="dropdown-menu">
                <li class="nav-item"><a routerLink="/o-and-m" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Operations and Maintenance</a></li>
                <li class="nav-item"><a routerLink="/its" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Intelligent Transportation Systems (ITS)</a></li>
                <li class="nav-item"><a routerLink="/electrical-lighting" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Electrical and Lighting</a></li>
                <li class="nav-item"><a routerLink="/ict-elv" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">ICT and ELV Systems</a></li>
                <li class="nav-item"><a routerLink="/plc-scada" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">PLC/SCADA Automation</a></li>
                <li class="nav-item"><a routerLink="/transportation" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Integrated Transportation Solutions</a></li>
                <li class="nav-item"><a routerLink="/road-network-surveys" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Road Network Surveys</a></li>
              </ul>
            </li>

            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Portfolio</a>
              <ul class="dropdown-menu">
                <li class="nav-item" (click)="toggleClass()"><a routerLink="/key-projects" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Key Projects</a></li>
                <li class="nav-item" (click)="toggleClass()"><a routerLink="/project-map" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">GBDG Project Map</a></li>
                <li class="nav-item" (click)="toggleClass()"><a routerLink="/recognitions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Recognitions</a></li>
                <li class="nav-item" (click)="toggleClass()"><a routerLink="/clients" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Clients</a></li>
              </ul>
            </li>

            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Products</a>
              <ul class="dropdown-menu">
                <li class="nav-item"><a routerLink="/rokid" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Rokid AR Glasses</a></li>
                <li class="nav-item"><a routerLink="/romdas" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">ROMDAS&#8482; Survey Systems</a></li>
                <li class="nav-item"><a routerLink="/papercast" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Papercast<sup>&#174;</sup> E-Ink Displays</a></li>
                <!--<li class="nav-item"><a routerLink="/security-elv" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Security ELV Solutions</a></li>-->
                <li class="nav-item"><a routerLink="/software" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">GBDG Software Solutions</a></li>
              </ul>
            </li>

            <li class="nav-item dropdown" (click)="toggleClass()">
              <a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact Us</a>
            </li>



            <!-- <li class="nav-item">
                 <a href="javascript:void(0)" class="nav-link dropdown-toggle">Examples</a>

                 <ul class="dropdown-menu"><li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                   <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>

                   <li class="nav-item"><a routerLink="/case-studies" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Studies</a></li>

                   <li class="nav-item"><a routerLink="/case-studies-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Studies Details</a></li>

                   <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Error 404</a></li>

                   <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                   <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>

                   <li class="nav-item"><a routerLink="/home-one" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home One</a></li>

                   <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Two</a></li>

                   <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Three</a></li>

                   <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                   <li class="nav-item"><a routerLink="/privacy-policy" cl ass="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>

                   <li class="nav-item"><a routerLink="/sign-in" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign In</a></li>

                   <li class="nav-item"><a routerLink="/sign-up" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>

                   <li class="nav-item"><a routerLink="/solutions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Solutions</a></li>

                   <li class="nav-item"><a routerLink="/solutions-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Solutions Details</a></li>

                   <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team</a></li>

                   <li class="nav-item"><a routerLink="/terms-condition" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a></li>

                   <li class="nav-item"><a routerLink="/testimonials" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>
                 </ul>
             </li>-->
          </ul>
        </div>
      </nav>
    </div>
  </div>
</div>
