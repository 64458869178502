import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {

    constructor(
        private titleService: Title,
        private meta: Meta,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private doc: Document
    )
    {
        this.titleService.setTitle('GBDG - Partners and Suppliers');
        this.meta.addTag({ name: 'description', content: 'GBDG duly recognizes and appreciates the important contribution of its OEM partners, associates, and suppliers, who have supported us all along and enabled us to deliver customized solutions to our valued customers.' });
        this.meta.addTag({ name: 'keywords', content: ' Gulf Business Development Group,Best Technology Company Qatar,Commissioning Services Qatar,GBDG Qatar,Gulf Business Qatar,Qatar Technology Solutions,Supply and Installation Qatar,GBDG Qatar near me,Qatari Company,Business Development Qatar,New Technologies in Qatar,PWA Contractor,Public Works Authority,Ministry of Transportation,Ministry of Interior,PWA Qatar,MoT Qatar,MoI Qatar,Software Qatar,ISO 9001 Certified Company Qatar,ISO 14001 Certified Company Qatar,ISO 45001 Certified Company Qatar,ISO Certified Company Qatar,Trademarks Qatar,Intellectual Properties Qatar,Licenced Contractor Qatar,' });
        this.meta.addTag({ name: 'robots', content: 'all' });
        this.meta.addTag({ name: 'resource-type', content: 'document' });
        this.meta.addTag({ name: 'page-topic', content: 'Gulf Business Development Group' });
        this.meta.addTag({ name: 'clientbase', content: 'Global' });
        this.meta.addTag({ name: 'distribution', content: 'World Wide Web' });
        this.meta.addTag({ name: 'audience', content: 'all' });
        this.meta.addTag({ name: 'rating', content: 'general' });
        this.meta.addTag({ id: 'googlebot', name: 'googlebot', content: 'index, follow' });
        this.meta.addTag({ name: 'expires', content: 'never' });
        this.meta.addTag({ name: 'bingbot', content: ' index, follow ' });
        this.meta.addTag({ name: 'revisit-after', content: 'Daily' });
        this.meta.addTag({ name: 'author', content: 'Gulf Business Development Group' });
        this.meta.addTag({ name: 'HandheldFriendly', content: 'True' });
        this.meta.addTag({ name: 'YahooSeeker', content: 'Index,Follow' });
        this.meta.addTag({ name: 'geo.region', content: 'UAE' });
        this.meta.addTag({ name: 'State', content: 'Qatar' });
        this.meta.addTag({ name: 'City', content: 'Doha' });
        this.meta.addTag({ name: 'subject', content: 'Rated #1 Gulf Business Development Group - Partners and Suppliers' });
    }

    ngOnInit(): void {
        this.setCanonicalURL('https://gulfbdgroup.com/partners-and-suppliers/');
    }

    setCanonicalURL(url: string) {
        let link: HTMLLinkElement | null = this.doc.querySelector('link[rel="canonical"]') || null;
        if (link) {
            this.renderer.setAttribute(link, 'href', url);
        } else {
            link = this.renderer.createElement('link');
            this.renderer.setAttribute(link, 'rel', 'canonical');
            this.renderer.setAttribute(link, 'href', url);
            this.renderer.appendChild(this.doc.head, link);
        }
    }

}
