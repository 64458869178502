<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>Recognitions</h2>
    </div>
  </div>

  <div class="page-shape">
    <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
    <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
    <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
    <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
    <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
  </div>
</div>

<section class="client-area mt-20 pb-100">
  <div class="container">
    <div class="section-title">
      <p>Our clients appreciate the quality of service and positive outcomes, as reflected in their encouraging testimonials, and their appreciation motivates us to push our boundaries even further.</p>
    </div>
    <div class="client-wrap">
      <owl-carousel-o [options]="clientWrap">
        <ng-template carouselSlide>
          <div class="single-client">
            <img src="assets/images/client/1.jpg" alt="img">
            <div class="testimonial">
              <br/>
              <h3>Supreme Committee for Delivery and Legacy</h3>
              <span>Niyas Abdulrahiman - FIFA Qatar 2022 ICT Executive Director</span>
              <p>"[...] Your team's commitment, dedication, enthusiasm, and insight have been visible in their work through the preparation & the tournament. We have received positive feedback and reviews from other stakeholders for the IT delivery of the tournament and it goes without saying that these feedbacks are a testament of the successful delivery that has been supported by our esteemed partners such as Gulf Business Development Group though your commendable efforts in the provision of the required technology services and manpower support. [...]"</p>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-client">
            <img src="assets/images/client/2.jpg" alt="img">
            <div class="testimonial">
              <br/>
              <h3>Public Works Authority "Ashghal"</h3>
              <span>Eng. Yousef A. Rahman Al-Emadi - Project Affairs Director</span>
              <p><br/>"[...] The Public Works Authority extends gratitude & appreciation to Gulf Business Development Group for the support in the implementation and operation of Tunnels Management Systems for Arab Cup 2021 and FIFA World Cup 2022 for Al Bustan North Project and Khalifa Avenue Project."</p>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-client">
            <img src="assets/images/client/2.jpg" alt="img">
            <div class="testimonial">
              <br/>
              <h3>Public Works Authority "Ashghal"</h3>
              <span>Eng. Aksar Meethale Vannantavita - Civil Inspector from Roads Operation & Maintenance Department</span>
              <p><br/>"[...] I am delighted to say that the workplace was impressed with arrangements and noticed several good practices that your team has implemented, we would like to appreciate your team for the high level of safety standards that were observed on site. Once more we appreciate your full time monitoring to maintain the H&S standards at the site and would like to encourage the same keenness and endeavor to keep your site H&S as a primary objectives in future also."</p>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-client">
            <img src="assets/images/client/4.jpg" alt="img">
            <div class="testimonial">
              <br/>
              <h3>Mowasalat</h3>
              <span>Mr. Ameen Abu Talib - Technical Team Lead</span>
              <p>"[...] We would like to express our appreciation for GBDG’s prompt action and cooperation in delivering our previous project. We are excited to commence this new phase and confident in GDDG’s ability to deliver a successful solution to our requirements. [...]"</p>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-client">
            <img src="assets/images/client/8.jpg" alt="img">
            <div class="testimonial">
              <br/>
              <h3>Emovis</h3>
              <span>Mr. Ross Taylor - Congestion Charging Manager</span>
              <p>"[...] I have had the privilege of collaborating with Gulf Business Development Group (GBDG) on two significant ITS infrastructure projects over the last 5 years. Their approach to overcoming challenges reflected a commitment to safety, efficient installation speed, and maintaining a high-quality standard that aligns with the expectations of all concerned stakeholders. Their professional support for the seamless handover of the tolling and congestion charging system further solidified my positive experience, prompting me to designate GBDG as my preferred choice for future projects."</p>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-client">
            <img src="assets/images/client/3.jpg" alt="img">
            <div class="testimonial">
              <br/>
              <h3>CCC/Teyseer JV</h3>
              <span>Eng. Bassam Salem - Project Director</span>
              <p><b>Mall of Qatar Tunnel ITS/SCADA Implementation</b><br/><br/>"[...] This has reference to the successful opening of Mall of Qatar Tunnel on 10th December 2016. On this occasion, we would like to convey our appreciation to GBDG for their remarkable contribution to this achievement."</p>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-client">
            <img src="assets/images/client/3.jpg" alt="img">
            <div class="testimonial">
              <br/>
              <h3>CCC/Teyseer JV</h3>
              <span>Eng. Bassam Salem - Project Director</span>
              <p><b>Mall of Qatar Tunnel Operation and Maintenance</b><br/><br/>"[...] CCC/Teyseer would like to extend its genuine appreciation to GBDG for their close cooperation, commitment, professionalism and efforts whilst duly accomplished the required scope to our utmost satisfaction. [...]"</p>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</section>
