import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-ict-elv',
    templateUrl: './ict-elv.component.html',
    styleUrls: ['./ict-elv.component.scss']
})
export class ICTELVComponent implements OnInit {

    constructor(
        private titleService: Title,
        private meta: Meta,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private doc: Document
    )
    {
        this.titleService.setTitle('GBDG  - ICT and ELV Systems');
        this.meta.addTag({ name: 'description', content: 'GBDG specializes in providing a comprehensive portfolio of ICT and ELV solutions to deliver state-of-the-art, environment-friendly, and ICT and ELV Systems in Qatar' });
        this.meta.addTag({ name: 'keywords', content: '  Access Control System Qatar,Best ELV System Contractor Qatar,Building Security Qatar,CCTV Contractor Qatar,CCTV System Qatar,Datacenter Solutions Qatar,ICT Contractor Qatar,Security Systems Qatar,Telecom Contractor Qatar,Turnstile System Qatar,Video Surveillance Qatar,ICT and ELV Systems,ICT and ELV Systems in Doha,ICT and ELV Systems in Qatar,ICT and ELV Systems near me,MOI-SSD Certified Qatar,Licensed ICT Contractor,' });
        this.meta.addTag({ name: 'robots', content: 'all' });
        this.meta.addTag({ name: 'resource-type', content: 'document' });
        this.meta.addTag({ name: 'page-topic', content: 'ICT and ELV Systems' });
        this.meta.addTag({ name: 'clientbase', content: 'Global' });
        this.meta.addTag({ name: 'distribution', content: 'World Wide Web' });
        this.meta.addTag({ name: 'audience', content: 'all' });
        this.meta.addTag({ name: 'rating', content: 'general' });
        this.meta.addTag({ id: 'googlebot', name: 'googlebot', content: 'index, follow' });
        this.meta.addTag({ name: 'expires', content: 'never' });
        this.meta.addTag({ name: 'bingbot', content: ' index, follow ' });
        this.meta.addTag({ name: 'revisit-after', content: 'Daily' });
        this.meta.addTag({ name: 'author', content: 'Gulf Business Development Group' });
        this.meta.addTag({ name: 'HandheldFriendly', content: 'True' });
        this.meta.addTag({ name: 'YahooSeeker', content: 'Index,Follow' });
        this.meta.addTag({ name: 'geo.region', content: 'UAE' });
        this.meta.addTag({ name: 'State', content: 'Qatar' });
        this.meta.addTag({ name: 'City', content: 'Doha' });
        this.meta.addTag({ name: 'subject', content: 'Rated #1 Gulf Business Development Group  - ICT and ELV Systems in Qatar' });
    }

    ngOnInit(): void {
        this.setCanonicalURL('https://gulfbdgroup.com/ict-elv/');
    }

    setCanonicalURL(url: string) {
        let link: HTMLLinkElement | null = this.doc.querySelector('link[rel="canonical"]') || null;
        if (link) {
            this.renderer.setAttribute(link, 'href', url);
        } else {
            link = this.renderer.createElement('link');
            this.renderer.setAttribute(link, 'rel', 'canonical');
            this.renderer.setAttribute(link, 'href', url);
            this.renderer.appendChild(this.doc.head, link);
        }
    }

}
