<section class="slider-area-2">
  <div class="home-slider">
    <owl-carousel-o [options]="homeSlides">
      <ng-template carouselSlide>
        <div class="single-slider single-slider-bg-1">
          <div class="d-table">
            <div class="d-table-cell">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-lg-12 text-center">
                    <div class="slider-content one">
                      <h1>Operations and Maintenance</h1>
                      <div class="slider-btn text-center">
                        <a routerLink="/o-and-m" class="box-btn border-btn">Read More</a>
                        <a routerLink="/contact" class="box-btn">Contact Us</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="single-slider single-slider-bg-2">
          <div class="d-table">
            <div class="d-table-cell">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-lg-12 text-center">
                    <div class="slider-content one">
                      <h1>Intelligent Transportation Systems</h1>
                      <div class="slider-btn text-center">
                        <a routerLink="/its" class="box-btn border-btn">Read More</a>
                        <a routerLink="/contact" class="box-btn">Contact Us</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="single-slider single-slider-bg-3">
          <div class="d-table">
            <div class="d-table-cell">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-lg-12 text-center">
                    <div class="slider-content one">
                      <h1>Electrical and Lighting</h1>
                      <div class="slider-btn text-center">
                        <a routerLink="/electrical-lighting" class="box-btn border-btn">Read More</a>
                        <a routerLink="/contact" class="box-btn">Contact Us</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="single-slider single-slider-bg-4">
          <div class="d-table">
            <div class="d-table-cell">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-lg-12 text-center">
                    <div class="slider-content one">
                      <h1>ICT and ELV Systems</h1>
                      <div class="slider-btn text-center">
                        <a routerLink="/ict-elv" class="box-btn border-btn">Read More</a>
                        <a routerLink="/contact" class="box-btn">Contact Us</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="single-slider single-slider-bg-5">
          <div class="d-table">
            <div class="d-table-cell">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-lg-12 text-center">
                    <div class="slider-content one">
                      <h1>PLC/SCADA Automation</h1>
                      <div class="slider-btn text-center">
                        <a routerLink="/plc-scada" class="box-btn border-btn">Read More</a>
                        <a routerLink="/contact" class="box-btn">Contact Us</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="single-slider single-slider-bg-6">
          <div class="d-table">
            <div class="d-table-cell">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-lg-12 text-center">
                    <div class="slider-content one">
                      <h1>Integrated Transportation Solutions</h1>
                      <div class="slider-btn text-center">
                        <a routerLink="/transportation" class="box-btn border-btn">Read More</a>
                        <a routerLink="/contact" class="box-btn">Contact Us</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="single-slider single-slider-bg-7">
          <div class="d-table">
            <div class="d-table-cell">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-lg-12 text-center">
                    <div class="slider-content one">
                      <h1>Road Network Survey Solutions</h1>
                      <div class="slider-btn text-center">
                        <a routerLink="/road-network-surveys" class="box-btn border-btn">Read More!</a>
                        <a routerLink="/contact" class="box-btn">Contact Us</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>
</section>

<div class="info-area pt-100 pb-20">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-sm-6">
        <div class="single-info">
          <div class="info-img">
            <img src="assets/images/info/i6.jpg" alt="info" />
          </div>

          <div class="content info-title" [routerLink]="['/about']">
            <h3><i class="flaticon-info"></i> About Us</h3>
            <div class="arrow">
              <a [routerLink]="['/about']"> <i class="flaticon-next-1"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-sm-6">
        <div class="single-info">
          <div class="info-img">
            <img src="assets/images/info/i5.jpg" alt="info" />
          </div>

          <div class="content" [routerLink]="['/about']" fragment="vision">
            <h3><i class="flaticon-support"></i> Our Vision</h3>
            <div class="arrow">
              <a [routerLink]="['/about']" fragment="vision"> <i class="flaticon-next-1"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
        <div class="single-info si-30">
          <div class="info-img">
            <img src="assets/images/info/i4.jpg" alt="info" />
          </div>

          <div class="content" [routerLink]="['/about']" fragment="values">
            <h3><i class="flaticon-goal"></i>Our Core Values</h3>
            <div class="arrow">
              <a [routerLink]="['/about']" fragment="values"> <i class="flaticon-next-1"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="technology-area ptb-100">
  <div class="container">
    <div class="row w-100 text-center choose-c">
      <h1>Company Overview</h1>
    </div>
    <div class="row pt-20 d-flex justify-content-center choose-c">
      <div class="col-lg-6">
        <div class="choose-img">
          <img src="assets/images/company/services.png" alt="GBDG" />

          <div class="technology-video">
            <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
              <i class='bx bx-play' ></i>
            </button>
          </div>
        </div>
        <div class="video-popup" *ngIf="isOpen">
          <div class="popup-inner"><!-- https://youtu.be/j6o6GGkoYI8 -->
            <iframe src="https://youtube.com/embed/j6o6GGkoYI8" title="Gulf Business Development Group" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <button (click)="closePopup()" type="button" class="close-btn">
              <i class='bx bx-x'></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="home-process-area pt-100 pb-70">
  <div class="container">
    <div class="section-title">
      <h2>End-to-end Solutions and Services</h2>
      <p>GBDG offers a holistic portfolio of well-integrated solutions and services to both private and public organizations in the roads, tunnels, transport, and building infrastructure sectors. Our offerings span end-to-end solutions and services ranging from design, procurement, installation, testing and commissioning, operations, and maintenance.</p>
    </div>

    <div class="row d-flex justify-content-center">
      <div class="col-lg-2 col-sm-4">
        <div class="single-process">
          <div class="icon">
            <img src="assets/images/service/design.png" alt="process" />
            <span><img src="assets/images/process/next.png" alt="shape" /></span>
          </div>

          <div class="content">
            <h3>Design</h3>
          </div>
        </div>
      </div>

      <div class="col-lg-2 col-sm-4">
        <div class="single-process">
          <div class="icon">
            <img src="assets/images/service/procurement.png" alt="process" />
            <span class="pro-span"><img src="assets/images/process/next.png" alt="shape" /></span>
          </div>

          <div class="content">
            <h3>Procurement</h3>
          </div>
        </div>
      </div>

      <div class="col-lg-2 col-sm-4">
        <div class="single-process">
          <div class="icon">
            <img src="assets/images/service/maintenance.png" alt="process" />
            <span class="pro-span"><img src="assets/images/process/next.png" alt="shape" /></span>
          </div>

          <div class="content">
            <h3>Operations &<br/>Maintenance</h3>
          </div>
        </div>
      </div>

      <div class="col-lg-2 col-sm-4">
        <div class="single-process">
          <div class="icon">
            <img src="assets/images/service/installation-commissioning.png" alt="process" />
            <span class="pro-span"><img src="assets/images/process/next.png" alt="shape" /></span>
          </div>

          <div class="content">
            <h3>Installation,<br/>Testing & Commissioning</h3>
          </div>
        </div>
      </div>

      <div class="col-lg-2 col-sm-4">
        <div class="single-process">
          <div class="icon">
            <img src="assets/images/service/turnkey-solution.png" alt="process" />
          </div>

          <div class="content">
            <h3>Turnkey Solution</h3>
          </div>
        </div>
      </div>


    </div>
  </div>
</section>


<section class="home2-choose-area ptb-100 bg-color">
  <div class="container">
    <div class="section-title">
      <h2>Industries Served</h2>
    </div>

    <div class="w-100 d-flex justify-content-center">
      <div class="industry">
        <div class="single-choose">
          <div class="icon">
            <img src="assets/images/industries/roads.webp" />
          </div>
          <h3>Roads and Tunnels</h3>
        </div>
      </div>

      <div class="industry">
        <div class="single-choose">
          <div class="icon">
            <img src="assets/images/industries/transport.webp" />
          </div>
          <h3>Transport Infrastructure</h3>
        </div>
      </div>

      <div class="industry">
        <div class="single-choose">
          <div class="icon">
            <img src="assets/images/industries/buildings.png" />
          </div>
          <h3>Buildings Infrastructure</h3>
        </div>
      </div>

      <div class="industry">
        <div class="single-choose">
          <div class="icon">
            <img src="assets/images/industries/stadiums.png" />
          </div>
          <h3>Stadiums</h3>
        </div>
      </div>

      <div class="industry">
        <div class="single-choose">
          <div class="icon">
            <img src="assets/images/industries/datacenters.jpg" />
          </div>
          <h3>Datacenters</h3>
        </div>
      </div>

    </div>
  </div>
</section>

<section class="client-area ptb-100">
  <div class="container">
    <div class="section-title">
      <h2 class="h2-normal">What our clients say...</h2>
      <p>Our clients appreciate the work and positive outcomes we deliver, which further encourages us to do even better.</p>
    </div>
    <div class="client-wrap">
      <owl-carousel-o [options]="clientWrap">
        <ng-template carouselSlide>
          <div class="single-client">
            <img src="assets/images/client/1.jpg" alt="img">
            <div class="testimonial">
              <br/>
              <h3>Supreme Committee for Delivery and Legacy</h3>
              <span>Niyas Abdulrahiman - FIFA Qatar 2022 ICT Executive Director</span>
              <p>"[...] Your team's commitment, dedication, enthusiasm, and insight have been visible in their work through the preparation & the tournament. We have received positive feedback and reviews from other stakeholders for the IT delivery of the tournament and it goes without saying that these feedbacks are a testament of the successful delivery that has been supported by our esteemed partners such as Gulf Business Development Group though your commendable efforts in the provision of the required technology services and manpower support. [...]"</p>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-client">
            <img src="assets/images/client/2.jpg" alt="img">
            <div class="testimonial">
              <br/>
              <h3>Public Works Authority "Ashghal"</h3>
              <span>Eng. Yousef A. Rahman Al-Emadi - Project Affairs Director</span>
              <p><br/>"[...] The Public Works Authority extends gratitude & appreciation to Gulf Business Development Group for the support in the implementation and operation of Tunnels Management Systems for Arab Cup 2021 and FIFA World Cup 2022 for Al Bustan North Project and Khalifa Avenue Project."</p>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-client">
            <img src="assets/images/client/2.jpg" alt="img">
            <div class="testimonial">
              <br/>
              <h3>Public Works Authority "Ashghal"</h3>
              <span>Eng. Aksar Meethale Vannantavita - Civil Inspector from Roads Operation & Maintenance Department</span>
              <p><br/>"[...] I am delighted to say that the workplace was impressed with arrangements and noticed several good practices that your team has implemented, we would like to appreciate your team for the high level of safety standards that were observed on site. Once more we appreciate your full time monitoring to maintain the H&S standards at the site and would like to encourage the same keenness and endeavor to keep your site H&S as a primary objectives in future also."</p>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-client">
            <img src="assets/images/client/4.jpg" alt="img">
            <div class="testimonial">
              <br/>
              <h3>Mowasalat</h3>
              <span>Mr. Ameen Abu Talib - Technical Team Lead</span>
              <p>"[...] We would like to express our appreciation for GBDG’s prompt action and cooperation in delivering our previous project. We are excited to commence this new phase and confident in GDDG’s ability to deliver a successful solution to our requirements. [...]"</p>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-client">
            <img src="assets/images/client/8.jpg" alt="img">
            <div class="testimonial">
              <br/>
              <h3>Emovis</h3>
              <span>Mr. Ross Taylor - Congestion Charging Manager</span>
              <p>"[...] I have had the privilege of collaborating with Gulf Business Development Group (GBDG) on two significant ITS infrastructure projects over the last 5 years. Their approach to overcoming challenges reflected a commitment to safety, efficient installation speed, and maintaining a high-quality standard that aligns with the expectations of all concerned stakeholders. Their professional support for the seamless handover of the tolling and congestion charging system further solidified my positive experience, prompting me to designate GBDG as my preferred choice for future projects."</p>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-client">
            <img src="assets/images/client/3.jpg" alt="img">
            <div class="testimonial">
              <br/>
              <h3>CCC/Teyseer JV</h3>
              <span>Eng. Bassam Salem - Project Director</span>
              <p><b>Mall of Qatar Tunnel ITS/SCADA Implementation</b><br/><br/>"[...] This has reference to the successful opening of Mall of Qatar Tunnel on 10th December 2016. On this occasion, we would like to convey our appreciation to GBDG for their remarkable contribution to this achievement."</p>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-client">
            <img src="assets/images/client/3.jpg" alt="img">
            <div class="testimonial">
              <br/>
              <h3>CCC/Teyseer JV</h3>
              <span>Eng. Bassam Salem - Project Director</span>
              <p><b>Mall of Qatar Tunnel Operation and Maintenance</b><br/><br/>"[...] CCC/Teyseer would like to extend its genuine appreciation to GBDG for their close cooperation, commitment, professionalism and efforts whilst duly accomplished the required scope to our utmost satisfaction. [...]"</p>
            </div>
          </div>
        </ng-template>


      </owl-carousel-o>
    </div>
  </div>
</section>
