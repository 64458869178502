<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>About Us</h2>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center pt-70">

            <div class="col-lg-4 col-md-12 feature-img">
                <img class="poster" src="assets/images/company/gbdg-poster.webp" alt="GBDG" />

            </div>

            <div id="about" class="col-lg-8 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">

                        <h2>Gulf Business Development Group (GBDG)</h2>
                        <p class="paragraph">Gulf Business Development Group (GBDG) was founded in 2013 by an ingenious entrepreneur with a strategic vision to transform Qatar's land transportation sector through advanced technological solutions and services, aiming to establish a new benchmark for innovation and progress.</p>
                        <p class="paragraph">Today, GBDG is one of the leading groups of companies offering a wide portfolio of intelligent transportation, ICT and ELV systems, electrical and lighting solutions, and operations and asset management services for roads, tunnels, transport and building infrastructure sectors in the State of Qatar.</p>
                        <p class="paragraph">Starting with only 5 employees at its beginning, GBDG swiftly grew in numbers and experience, hiring both local and international resources while taking care of its clients’ needs right from the design phase of their projects to handover and post-handover operations and maintenance.</p>
                        <p class="paragraph">Certified to various international quality and safety standards, GBDG has consistently delivered to the requirements of its customers in a satisfactory manner and earned the reputation of a reliable solution partner for its strong technical capabilities and flexible business approach. This has enabled GBDG to establish a strong client base over the period, secure consistent repeat business from its valued customers, and thereby establish a sound financial standing and a sustainable business structure.</p>
                        <p class="paragraph">Our rapid growth and success are largely attributed to the dynamism of its management team and the proven technical competence and commitment of its valued employees. The conglomeration of talent combined with an encouraging work environment has propelled the growth of GBDG towards realizing its vision and becoming an organization of choice for its customers, employees, and suppliers.</p>
                        <p class="paragraph">We are well-equipped with a committed team of management professionals and a balanced pool of subject matter experts, experienced project managers, engineers, designers, and other key support personnel, along with all the necessary latest tools and equipment to ensure successful and timely delivery of projects in compliance with international standards of quality and safety.</p>
                        <p class="paragraph">GBDG is continually striving for improvement in all aspects of its operations to deliver better value to its customers and maintain its recognition as a reliable and competent solution provider in Qatar and other GCC countries.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="feature area ptb-100 bg-color">
  <div class="container">
    <div class="row">
      <div class="title">
        <h2 class="title">GBDG's Evolution</h2>
        </div>
    </div>
    <div class="row timeline-area d-flex justify-content-center">
      <ul class="timeline">
        <li style="--accent-color:#021663">
          <div class="date">2013 - 2014</div>
          <div class="descr">
            <ul class="events">
              <li>• Incorporated as a local Qatari company</li>
              <li>• Pre-Qualified with Ashghal (PWA) as a turnkey ITS Contractor</li>
            </ul>
          </div>
        </li>
        <li style="--accent-color:#021663">
          <div class="date">2015 - 2016</div>
          <div class="descr">
            <ul class="events">
              <li>• Awarded first ITS, ELV and PLC/SCADA Implementation Project for Mall of Qatar Tunnel</li>
              <li>• Awarded first Tunnel O&M Contract for Dukhan Highway/Mall of Qatar</li>
            </ul>
          </div>
        </li>
        <li style="--accent-color:#021663">
          <div class="date">2017 - 2018</div>
          <div class="descr">
            <ul class="events">
              <li>• Awarded first major stadium contract for the upgrade of Turnstiles and Ticket Reader Systems</li>
              <li>• Awarded one of the largest ITS, ELV and PLC/SCADA Implementation Project for Khalifa Avenue Roads & Tunnels</li>
              <li>• Achieved ISO 9001, 14001 and 45001 Quality, Environment, Health and Safety Certifications</li>
              <li>• Awarded first Street Lighting O&M Contract</li>
              <li>• Awarded ITS, ELV and PLC/SCADA Implementation Project for Al Bustan Street Roads & Tunnels</li>
            </ul>
          </div>
        </li>
        <li style="--accent-color:#021663">
          <div class="date">2019 - 2020</div>
          <div class="descr">
            <ul class="events">
              <li>• Achieved Kahramaa License for LV Works</li>
              <li>• Pre-qualified with Ashghal (PWA) as a turnkey Street Lighting Contractor</li>
              <li>• Achieved MOI-SSD License as an approved Security Systems contractor</li>
              <li>• Awarded first ELV systems implementation project for a Tier-III Datacenter</li>
              <li>• Awarded Tunnel O&M Contract for the Al Rayyan Tunnel (longest tunnel in the State of Qatar)</li>
              <li>• Appointed by Ashghal (PWA) as a nominated Tunnels O&M Framework Contractor</li>
              <li>• Awarded first O&M Contract for Pedestrian Bridges</li>
            </ul>
          </div>
        </li>
        <li style="--accent-color:#021663">
          <div class="date">2021 - 2022</div>
          <div class="descr">
            <ul class="events">
              <li>• Awarded major project for implementation of on-board devices and systems for 3000 FIFA World Cup tournament buses</li>
              <li>• Awarded first project for the implementation of Congestion Charging and Tolling Systems in the State of Qatar</li>
              <li>• Awarded the major project for implementation of 2700 Bus Shelters in the State of Qatar</li>
              <li>• Awarded the O&M Contract for Turnstiles and Ticketing Readers for Four (4) FIFA World Cup Stadiums</li>
            </ul>
          </div>
        </li>
        <li style="--accent-color:#021663">
          <div class="date">2023 - Today</div>
          <div class="descr">
            <ul class="events">
              <li>• Established new division for Road Network Surveys for Asset Inventory and Pavement Condition Data Collection & Analysis.</li>
              <li>• Assigned O&M Work Order for Four (4) additional Tunnels from the Public Works Authority Ashghal</li>
              <li>• Awarded major project for implementation of Hostile Vehicle Mitigation Systems in Military Medical City Hospital in at Tarek Bin Zeyad Camp in Sailiyah</li>
              <li>• Awarded major maintenance contract for access control system for Aisha Bin Al-Attiyah Hospital (Hamad Medical Corporation)</li>
              <li>• Awarded major project of implementation of “BusiStop” solution for remote monitoring and control of air-conditioned Bus Shelters.</li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div id="vision"></div>
</section>


<section class="feature-area ptb-100 bg-quote" >
  <div class="vision">
    <h2>Company Vision</h2><br/>
    <p class="paragraph"><i class="bx bxs-quote-alt-left"></i><span> To become the most preferred solution provider in the areas of our expertise through our committed and flexible business approach, delivering the best value to our customers and growth to our employees and partners. </span><i class="bx bxs-quote-alt-right"></i></p>
    <p class="paragraph"><br/></p>
  </div>
  <div id="values"></div>

</section>


<section class="feature-area ptb-100" >

    <div class="container">

        <div class="row align-items-center">
            <div class="col-lg-7">
                <div class="contnet">
                    <div class="feature-tittle">
                        <h2>Core Values</h2>
                        <p class="paragraph">Like any other successful organization, our success is also built upon a strong and distinct set of values that clearly reflect what is important to us at GBDG.</p>

                        <p class="paragraph">Right from the point of our incorporation, we have established these core values as part of our DNA to describe the very character of our organization, and we have consistently strived to align our corporate culture with them.</p>

                        <p class="paragraph">We passionately sustain and promote these core values as part of our everyday activities at GBDG, and every single person joining the GBDG family is trained to practice these core values as part of their daily activities.</p>

                    </div>

                    <ul>
                        <li>
                            <i class="flaticon-correct"></i>
                            <b>Reliability</b>
                            <p class="paragraph">We strongly believe that Reliability is one of the most sought-after requirements when a customer selects a Solution provider for their project. We are therefore committed to acting responsibly across all stages of our engagement with any customer to ensure that we do really deliver what we promise to deliver within the agreed timeline with absolute transparency and sincere commitment to resolve any challenging situation.</p>
                        </li>
                        <li>
                          <i class="flaticon-correct"></i>
                          <b>Competence</b>
                          <p class="paragraph">Our technical competency and professional delivery approach have repeatedly helped us gain an upper edge over your competitors. We devote extra care to the selection of people across all our main stream and support functions and reasonably invest in their development and retention while providing them with a collaborative environment to think, grow, and deliver smart and cost-effective solutions to project requirements.</p>
                        </li>
                        <li>
                            <i class="flaticon-correct"></i>
                            <b>Flexibility</b>
                            <p class="paragraph">Flexibility is the force that stimulates progress, adaptability, continual improvement, and the very desire to succeed in everything that we do at GBDG. We constantly remain engaged with our clients and employees at all levels and continually strive to deliver high levels of performance by effectively responding to evolving project requirements, maintaining consistent follow-up, and making quick decisions across all functions within our corporate value chain.</p>
                        </li>
                    </ul>

                </div>


            </div>

            <div class="col-lg-5">
                <div class="feature-img image">
                    <img src="assets/images/company/core.png" alt="Core Values" />
                </div>
            </div>
        </div>
        <br/><br/>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <p class="paragraph">GBDG's consistency in maintaining the above values helped build trust and loyalty among our customers, suppliers, partners, regulators, and shareholders and drove employee satisfaction and retention. Our core values have enabled us to improve and grow, and ultimately to improve the lives of our employees, customers, and stakeholders.</p>
                </div>
            </div>
        </div>

    </div>
</section>

