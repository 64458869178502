import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';

import { ContactService } from './contact.service';


export interface IContact {
  name?: string | null;
  email?: string | null;
  phone?: string | null;
  subject?: string | null;
  message?: string | null;
  token?: string | null;
}

export type ContactFormContent = {
  name: FormControl<IContact['name']>;
  email: FormControl<IContact['email']>;
  phone: FormControl<IContact['phone']>;
  subject: FormControl<IContact['subject']>;
  message: FormControl<IContact['message']>;
  token: FormControl<IContact['token']>;
};

export type ContactFormGroup = FormGroup<ContactFormContent>;


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  contactForm: ContactFormGroup = this.createContactFormGroup();
  submitted = false;
  error = false;
  errorContactEmail = false;
  isLoading = false;
  captchaResolved : boolean = false;

  constructor(
        private titleService: Title,
        private meta: Meta,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private doc: Document,
        protected contactService: ContactService
    )
    {
        this.titleService.setTitle('GBDG - Contact Us');
        this.meta.addTag({ name: 'description', content: 'Have a question or feedback? We\'re here to help! Use the contact form, connect with us on social media, or drop by our office. We value your input and look forward to hearing from you soon.' });
        this.meta.addTag({ name: 'keywords', content: ' Gulf Business Development Group,Best Technology Company Qatar,Commissioning Services Qatar,GBDG Qatar,Gulf Business Qatar,Qatar Technology Solutions,Supply and Installation Qatar,GBDG Qatar near me,Qatari Company,Business Development Qatar,New Technologies in Qatar,PWA Contractor,Public Works Authority,Ministry of Transportation,Ministry of Interior,PWA Qatar,MoT Qatar,MoI Qatar,Software Qatar,ISO 9001 Certified Company Qatar,ISO 14001 Certified Company Qatar,ISO 45001 Certified Company Qatar,ISO Certified Company Qatar,Trademarks Qatar,Intellectual Properties Qatar,Licenced Contractor Qatar,' });
        this.meta.addTag({ name: 'robots', content: 'all' });
        this.meta.addTag({ name: 'resource-type', content: 'document' });
        this.meta.addTag({ name: 'page-topic', content: 'Gulf Business Development Group' });
        this.meta.addTag({ name: 'clientbase', content: 'Global' });
        this.meta.addTag({ name: 'distribution', content: 'World Wide Web' });
        this.meta.addTag({ name: 'audience', content: 'all' });
        this.meta.addTag({ name: 'rating', content: 'general' });
        this.meta.addTag({ id: 'googlebot', name: 'googlebot', content: 'index, follow' });
        this.meta.addTag({ name: 'expires', content: 'never' });
        this.meta.addTag({ name: 'bingbot', content: ' index, follow ' });
        this.meta.addTag({ name: 'revisit-after', content: 'Daily' });
        this.meta.addTag({ name: 'author', content: 'Gulf Business Development Group' });
        this.meta.addTag({ name: 'HandheldFriendly', content: 'True' });
        this.meta.addTag({ name: 'YahooSeeker', content: 'Index,Follow' });
        this.meta.addTag({ name: 'geo.region', content: 'UAE' });
        this.meta.addTag({ name: 'State', content: 'Qatar' });
        this.meta.addTag({ name: 'City', content: 'Doha' });
        this.meta.addTag({ name: 'subject', content: 'Rated #1 Gulf Business Development Group - Contact Us' });
    }

    ngOnInit(): void {
        this.setCanonicalURL('https://gulfbdgroup.com/contact/');
    }

    setCanonicalURL(url: string) {
        let link: HTMLLinkElement | null = this.doc.querySelector('link[rel="canonical"]') || null;
        if (link) {
            this.renderer.setAttribute(link, 'href', url);
        } else {
            link = this.renderer.createElement('link');
            this.renderer.setAttribute(link, 'rel', 'canonical');
            this.renderer.setAttribute(link, 'href', url);
            this.renderer.appendChild(this.doc.head, link);
        }
    }

  createContactFormGroup(): ContactFormGroup {
    const contact: IContact = {
      name: null,
      email: null,
      phone: null,
      subject: null,
      message: null,
      token: null
    };
    return new FormGroup({
      name: new FormControl(contact.name, {
        nonNullable: true,
        validators: [Validators.required]
      }),
      email: new FormControl(contact.email, {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(5), Validators.maxLength(254), Validators.email]
      }),
      phone: new FormControl(contact.phone, {
        nonNullable: true,
        validators: [Validators.required]
      }),
      subject: new FormControl(contact.subject, {
        nonNullable: true,
        validators: [Validators.required]
      }),
      message: new FormControl(contact.message, {
        nonNullable: true,
        validators: [Validators.required]
      }),
      token: new FormControl(contact.token, {
        nonNullable: true,
        validators: [Validators.required]
      }),
    });
  }

  getContact(): IContact {
    let contact: IContact = {
      name: this.contactForm.get('name') ? this.contactForm.get('name')?.value : null,
      email: this.contactForm.get('email') ? this.contactForm.get('email')?.value : null,
      phone: this.contactForm.get('phone') ? this.contactForm.get('phone')?.value : null,
      subject: this.contactForm.get('subject') ? this.contactForm.get('subject')?.value : null,
      message: this.contactForm.get('message') ? this.contactForm.get('message')?.value : null,
      token: this.contactForm.get('token') ? this.contactForm.get('token')?.value : null,
    };
    return contact;
  }

  resetEmail(): void {
    this.contactForm.controls['email'].setValue(null);
  }

  resetForm(): void {
    const contact: IContact = {
      name: null,
      email: null,
      phone: null,
      subject: null,
      message: null,
      token: null,
    };
    this.contactForm.reset({
      ...contact
    });
    this.submitted = false;
    this.error = false;
  }

  submit(): void {
     const contact = this.getContact();
     this.send(contact);
  }

  checkCaptcha(captchaResponse : any) {
    this.captchaResolved = (captchaResponse && captchaResponse.length > 0) ? true : false
  }

  send(contact: any){
    this.isLoading = true;
    this.contactService.sendEmailToContact(contact).subscribe(
         (data) => {},
         (errorToContact) => {
            if (errorToContact && errorToContact.status) {
              console.log('STATUS TO CONTACT: ' + errorToContact.status);
              if(errorToContact.status < 300){
                this.contactService.sendEmailToGBDG(contact).subscribe(
                  (data) => {},
                  (error) => {
                    if (error && error.status) {
                      console.log('STATUS TO GBDG: ' + error.status);
                      if(error.status < 300){
                        this.resetForm();
                        this.submitted = true;
                        this.errorContactEmail = false;
                        this.error = false;
                        this.isLoading = false;
                        setTimeout(() => {
                          this.submitted = false;
                        }, 5000);
                      }else {
                        this.submitted = false;
                        this.errorContactEmail = false;
                        this.error = true;
                        this.isLoading = false;
                      }
                    }
                  }
                );
              } else {
                this.submitted = false;
                this.resetEmail();
                this.errorContactEmail = true;
                this.error = false;
                this.isLoading = false;
              }
            }
         }
     );
  }

}
